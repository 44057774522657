<template>
  <div class="pa-3"
       infinite-scroll-disabled="busy"
       infinite-scroll-distance="10"
       v-infinite-scroll="load">
    <highlight-card :key="item.doc"
                    :text="item.highlight"
                    :title="item.name + ' (' + item.version + ')'"
                    @click.native="open(item)"
                    v-for="item in found"/>
  </div>
</template>

<script>
  import HighlightCard from './HighlightCard'

  export default {
    components: { HighlightCard },
    props: {
      searchQuery: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      busy: true,
      page: 0,
      found: [],
      cancel: undefined
    }),
    methods: {
      find (newSearchQuery) {
        this.searchQuery = newSearchQuery
        this.found = []
        this.busy = false
        this.page = 0
        if (newSearchQuery) this.load()
      },
      load () {
        const CancelToken = this.$axios.CancelToken
        this.busy = true
        let self = this
        if (this.cancel) {
          this.cancel()
        }
        const params = new URLSearchParams()
        this.searchQuery.split(' ').forEach(it => params.append('q', it))
        params.append('page', this.page)
        this.$axios
          .get('/full-text-search/find', {
            params: params,
            cancelToken: new CancelToken(function executor (c) {
              self.cancel = c
            })
          })
          .then(response => {
            if (response.data.length) {
              this.found.push(...response.data)
              this.page++
              this.busy = false
            }
          })
          .catch(thrown => {
            if (this.$axios.isCancel(thrown)) {
              console.error('Request canceled', thrown.message)
            } else {
              thrown ? console.error(thrown.toString()) : console.error("Unknown exception")
            }
          })
      },
      open (item) {
        this.$emit('close')
        this.$router.push(`/project/${item.project}/doc/${item.doc}`)
      },
      clean () {
        this.found = []
      }
    },
    watch: {
      searchQuery (newSearchQuery) {
        this.find(newSearchQuery)
      }
    }
  }

</script>
