<template>
  <div>
    <v-card-title>
      Шаблоны объектов
      <v-spacer/>
      <v-btn
        outlined
        color="primary"
        @click="create"
      >
        <v-icon>add</v-icon>
        Создать
      </v-btn>
    </v-card-title>
    <v-list>
      <v-list-item
        v-for="(item, index) in templateList"
        :key="index"
        @click="open(item)"
      >
        <v-list-item-avatar>
          <v-icon>line</v-icon>
        </v-list-item-avatar>
        {{ item.name }}
      </v-list-item>
    </v-list>
    <poi-template-dialog
      ref="poiTemplateDialog"
      @onSave="loadData"
    />
  </div>
</template>

<script>
import PoiTemplateDialog from '@/components/admin/poi-template-management/PoiTemplateDialog'

export default {
  name: 'PoiTemplateManagement',
  components: { PoiTemplateDialog },
  data: () => ({
    templateList: []
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    getIcon (type) {
      switch (type) {
        case 'LINE_STRING':
          return 'timeline'
      }
    },
    loadData () {
      this.$axios
        .get('layer-poi-template')
        .then(response => {
          this.templateList = response.data
        })
    },
    open (item) {
      this.$refs.poiTemplateDialog.open(item)
    },
    create () {
      this.$refs.poiTemplateDialog.open()
    }
  }
}
</script>
