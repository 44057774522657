<template>
  <v-card
    @click.native="show(item)"
    class="ma-2 search-result-row"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <span class="subtitle-2">
          {{ item.row.dname ? item.row.dname.value : 'Объект' }}
        </span>
        <v-list-item-subtitle v-if="item.row.typename">
          {{ item.row.typename.value }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.row.modename">
          {{ item.row.modename.value }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="item.icon">
        <img width="20" :src="item.icon"/>
      </v-list-item-action>
    </v-list-item>
    <v-card-actions>
      <v-chip label outlined small>
        <v-icon left small>layers</v-icon>
        {{ item.layer.name }}
      </v-chip>
      <v-spacer/>
      <span class="font-weight-thin caption" v-if="item.row.sys">
        #{{ item.row.sys.value }}
      </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  name: "ZwsSearchResult",
  props: {item: Object},
  methods: {
    show() {
      EventBus.$emit('showFeature', this.item.layer, this.item.row.sys.value)
    }
  }
}
</script>
