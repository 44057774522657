<template>
  <tr :bgcolor="item.id === selectedDataId ? '#f1f1f1' : '#ffffff'" :key="item.id">
    <td class="pa-3" v-show="selectable">
      <v-checkbox @change="$emit('selectHandler')" class="ma-0 pa-0" dense hide-details v-model="item.selected"/>
    </td>
    <td @click="open(item, $event)" class="inline-text pointer" style="max-width: 250px !important;">
      <span :style="item.isActive? '': 'text-decoration: line-through'">{{ item.name }}</span>
    </td>
    <td v-if="additionalField" @click="open(item, $event)" class="inline-text " style="max-width: 100px !important;">
      <v-chip dark small label :style="backgroundFromString(item[additionalField])">
        {{ item[additionalField] }}
      </v-chip>
    </td>
    <td @click="open(item, $event)" class="inline-text" style="max-width: 250px !important;"
        v-show="isDataListView && !displayXS">
      <v-chip class="ma-1" color="deep-purple" label outlined small
              v-for="category in item.categoryList.filter(it => !it.isDefault)">
        {{ category.name }}
      </v-chip>
    </td>
    <td @click="open(item, $event)" class="inline-text" style="max-width: 250px !important;"
        v-show="isDataListView && !displayXS">
      <v-chip class="ma-1" color="primary" label outlined small v-for="keyword in item.keywords">
        {{ keyword.value }}
      </v-chip>
    </td>
    <td @click="open(item, $event)" class="inline-text pointer" v-show="isDataListView && !displayXS">
      {{ formatDate(item.creationDate) }}
    </td>

    <td class="pointer" style="text-align: right; color: #5c5c5c; width: 20px"
        v-show="isDataListView && !displayXS">
      <v-menu :nudge-right="5" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <slot name="actions"/>
            <v-list-item
              v-for="(item, index) in options" :key="index"
              @click="invoke(item.action)"
              v-show="setAbility(item.role)"
              dense>
              <v-list-item-avatar>
                <v-icon :color="item.color">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                {{ $t(item.title) }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </td>

    <abstract-data-details-dialog @save="save" ref="itemDetailsDialog"/>
    <abstract-data-info-dialog ref="infoDialog"/>
    <share-data-dialog ref="shareDataDialog"/>
  </tr>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog'
import AbstractDataInfoDialog from '@/components/utils/AbstractDataInfoDialog'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import { EventBus } from '@/event-bus'

export default {
  name: 'AbstractDataRow',
  components: { ShareDataDialog, AbstractDataInfoDialog, AbstractDataDetailsDialog },
  props: ['item', 'isDataListView', 'selectedDataId', 'selectable', 'additionalField'],
  mixins: [displayServiceMixin],
  methods: {
    invoke (action) {
      this[action]()
    },
    showInfo () {
      this.$refs.infoDialog.open(this.item.id)
    },
    async edit () {
      let { data } = await this.$axios.get('data/get', { params: { id: this.item.id } })
      data.position = data.position ? JSON.parse(data.position) : null
      this.$refs.itemDetailsDialog.editData(data)
    },
    share () {
      let url = `${window.location}/${this.item.id}`
      this.$refs.shareDataDialog.share(url)
    },
    save (data) {
      this.$axios
        .post('data/save', data)
        .then(EventBus.$emit('showSuccessMessage'))
    },
    open (event) {
      this.$emit('open', this.item, event)
    }
  },
  computed: {
    options () {
      return [
        {
          title: 'button.info',
          icon: 'info',
          color: 'gray',
          action: 'showInfo',
          role: 'USER',
          visible: true
        },
        {
          title: 'button.edit',
          icon: 'edit',
          color: 'gray',
          action: 'edit',
          role: 'MODERATOR',
          visible: true
        },
        {
          title: 'button.share',
          icon: 'share',
          color: 'gray',
          action: 'share',
          role: 'USER',
          visible: true
        }
      ]
    }
  }
}
</script>
