<script>
import { TextLayerBuilder } from 'pdfjs-dist/web/pdf_viewer.js'

export default {
  name: 'PdfTextLayer',
  props: {
    page: {
      type: Object,
      required: true
    },
    viewport: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      textContent: null,
      textLayer: null
    }
  },
  methods: {
    renderText () {
      this.dispose()

      this.textLayer = new TextLayerBuilder({
        textLayerDiv: this.$el,
        viewport: this.viewport,
        pageIndex: this.page.pageIndex
      })

      this.textLayer.setTextContent(this.textContent)
      this.textLayer.render()
    },
    dispose () {
      if (this.textLayer) {
        this.textLayer.cancel()
        this.textLayer = null
      }

      this.$el.innerHTML = ''
    }
  },
  watch: {
    viewport () {
      this.renderText()
    }
  },
  render (h) {
    return h('div', {
      class: {
        textLayer: true
      }
    })
  },
  async mounted () {
    this.textContent = await this.page.getTextContent()
    this.renderText()
  },
  beforeDestroy () {
    this.dispose()
  }
}
</script>


