<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>
        Объект
        <v-spacer/>
        <v-btn @click="dialog=false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="header in table.headers">
          <v-text-field
            :label="header.name"
            v-model="item[header.field]"
          />
        </div>
      </v-card-text>
      <v-card-actions v-if="setAbility('MODERATOR')">
        <v-spacer/>
        <v-btn
          outlined
          v-show="!isNewFile"
          color="red"
          @click="remove"
        >
          Удалить
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DataTableItemDetailsDialog',
  props: ['table'],
  data: () => ({
    dialog: false,
    item: null,
    isNewFile: false
  }),
  methods: {
    save () {
      if (this.isNewFile) {
        this.table.items.push(this.item)
      }
      this.$emit('save')
      this.dialog = false
    },
    open (item) {
      this.isNewFile = false
      this.item = item
      this.dialog = true
    },
    createItem () {
      this.isNewFile = true
      this.item = {}
      this.dialog = true
    },
    remove () {
      this.table.items.splice(this.table.items.indexOf(this.item), 1)
      this.$emit('save')
      this.dialog = false
    }
  }
}
</script>
