<template>
  <div :class="panelExpanded ? 'project-expanded' : ''" v-if="selectedProject.id">
    <div class="second-color" id="project-data-bar" v-if="projectView" v-show="panelExpanded">
      <v-card-title class="pl-1 py-2" style="height: 49px">
        <div class="ml-2" id="header-of-project">{{ selectedProject.name }}</div>
        <v-spacer/>
        <v-btn @click="panelExpanded = !panelExpanded" dark icon v-if="!displayXS">
          <v-icon dark>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-list subheader dark dense class="without-background">
        <v-list-item
          :key="item.title"
          :to="item.link"
          v-for="item in mainMenuItems"
          v-if="selectedProject.id"
        >
          <v-list-item-icon class="mr-3">
            <v-icon color="grey lighten-2">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="grey--text text--lighten-2">{{ $t(item.title) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mt-2"/>
        <v-subheader>{{ $t('project-data') }}</v-subheader>
        <v-list-item :key="item.title" :to="item.link" v-for="item in menuItems" v-if="selectedProject.id">
          <v-list-item-icon class="mr-3">
            <v-icon color="grey lighten-2">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="grey--text text--lighten-2">{{ $t(item.title) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div @click="panelExpanded = true"
         class="second-color"
         style="cursor: pointer; border-right: solid 1px #d6d2d2; min-height: 100vh; width: 36px; position: fixed"
         v-if="!displayXS"
         v-show="!panelExpanded">
      <v-btn class="mb-2" dark icon>
        <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
      </v-btn>
      <div
        class="body-1"
        style="padding: 5px; width: 1000px; overflow: hidden; margin-top: -30px; transform-origin: bottom left; transform: rotate(90deg);"
      >
        {{ selectedProject.name }}
      </div>
    </div>

    <router-view :style="panelExpanded ? 'padding-left: 280px;' : 'padding-left: 36px;'" class="router-view"/>
  </div>
</template>

<script>

import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '../../shared-locale/project/messages'

export default {
  name: 'Project',
  mixins: [displayServiceMixin],
  data: () => ({
    panelExpanded: false
  }),
  i18n: { messages: messages },
  watch: {
    panelExpanded () {
      EventBus.$emit('frameResize')
    }
  },
  computed: {
    projectView () {
      if (!this.displayXS) return true
      return this.$route.name === 'project-view'
    },
    mainMenuItems () {
      return [
        {
          title: 'home',
          icon: 'home',
          link: `/project/${this.selectedProject.id}/home`
        },
        {
          title: 'dashboard',
          icon: 'dashboard',
          link: `/project/${this.selectedProject.id}/dashboard`
        },
        {
          title: 'tasks',
          icon: 'style',
          link: `/project/${this.selectedProject.id}/issue`
        },
        {
          title: 'objects',
          icon: 'place',
          link: `/project/${this.selectedProject.id}/poi-management`
        },
        {
          title: 'reports',
          icon: 'description',
          link: `/project/${this.selectedProject.id}/report`
        }
      ]
    },
    menuItems () {
      return [
        {
          title: 'documents',
          icon: 'book',
          link: `/project/${this.selectedProject.id}/doc`
        },
        {
          title: 'tables',
          icon: 'table_chart',
          link: `/project/${this.selectedProject.id}/table`
        },
        {
          title: 'maps',
          icon: 'map',
          link: `/project/${this.selectedProject.id}/map`
        },
        {
          title: 'layers',
          icon: 'layers',
          link: `/project/${this.selectedProject.id}/layer`
        },
        {
          title: 'charts',
          icon: 'insert_chart',
          link: `/project/${this.selectedProject.id}/chart`
        },
        {
          title: 'modbus-unit',
          icon: 'device_hub',
          link: `/project/${this.selectedProject.id}/modbus-unit`
        },
        {
          title: 'calculations',
          icon: 'functions',
          link: `/project/${this.selectedProject.id}/calc`
        }
      ]
    }
  }
}
</script>

<style>
#header-of-project {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 48px);
}
</style>
