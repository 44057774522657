<template>
  <v-dialog
    v-model="dialog"
    width="700"
    persistent
    scrollable
  >
    <v-card>
      <v-card-text>
        <v-text-field
          outlined
          label="Название шаблона"
          v-model="poiTemplate.name"
        />

        <v-select
          :items="graphTypeList"
          outlined
          label="Тип"
          v-model="poiTemplate.graphType"
        >
          <template v-slot:selection="{item}">
            {{ $t(`graphTypeList.${item}`) }}
          </template>
          <template v-slot:item="{item}">
            {{ $t(`graphTypeList.${item}`) }}
          </template>
        </v-select>

        <v-select
          :items="lpStyleList"
          outlined
          return-object
          item-text="name"
          label="Стиль по умолчанию"
          v-model="poiTemplate.defaultLayerPoiStyle"
        />
        <lps-rule-editor ref="lpsRuleEditorDlg" :template="poiTemplate"/>
        <vuedraggable handle=".handle" v-model="poiTemplate.fields">
          <poi-template-field
            v-for="field in poiTemplate.fields"
            :value="field"
            @remove="removeLpField"
          />
        </vuedraggable>

        <v-card-title>
          <v-spacer/>
          <v-btn
            outlined
            @click="addField"
          >
            <v-icon left>add</v-icon>
            Добавить поле
          </v-btn>
        </v-card-title>

      </v-card-text>

      <v-card-actions>
        <v-btn
          outlined
          @click="dialog=false"
        >
          Отмена
        </v-btn>
        <v-spacer/>
        <v-btn
          v-show="setAbility('SYSTEM_ADMIN')"
          outlined
          v-if="poiTemplate.isActive"
          color="red"
          @click="deactivate"
        >
          Деактивировать
        </v-btn>
        <v-btn
          v-show="setAbility('SYSTEM_ADMIN')"
          outlined
          v-if="!poiTemplate.isActive"
          color="green"
          @click="activate"
        >
          Активировать
        </v-btn>
        <v-btn
          v-show="setAbility('ADMIN')"
          outlined
          color="primary"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PoiTemplateField from '@/components/admin/poi-template-management/PoiTemplateField'
import messages from '@/componet-locale/poi-template-dialog/messages'
import { EventBus } from '@/event-bus'
import Vuedraggable from 'vuedraggable'
import LpsRuleEditor from '@/components/admin/poi-styles/LpsRuleEditor.vue'

const DEFAULT_FIELD = {
  name: '',
  type: 'STRING',
  required: false,
  options: ''
}

const DEFAULT_TEMPLATE = {
  name: '',
  graphType: 'POLYGON',
  fields: []
}

export default {
  name: 'PoiTemplateDialog',
  i18n: { messages: messages },
  components: { LpsRuleEditor, PoiTemplateField, Vuedraggable },
  data: () => ({
    dialog: false,
    poiTemplate: {},
    lpStyleList: [],
    graphTypeList: [
      'POLYGON', 'MULTI_POLYGON', 'LINE_STRING', 'MULTI_LINE_STRING', 'POINT'
    ]
  }),
  methods: {
    open (template = null) {
      this.loadStyles()
      if (template === null) {
        this.poiTemplate = { ...DEFAULT_TEMPLATE }
        this.poiTemplate.fields = []
      } else {
        this.poiTemplate = template
      }
      this.dialog = true
    },
    removeLpField (field) {
      this.poiTemplate.fields.splice(this.poiTemplate.fields.indexOf(field), 1)
    },
    loadStyles () {
      this.$axios
        .get('layer-poi-style')
        .then(response => {
          this.lpStyleList = response.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Произошла ошибка при попытке получить данные по стилям'))
    },
    deactivate () {
      this.$axios
        .post('layer-poi-template/deactivate', null, {
          params: { id: this.poiTemplate.id }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Шаблон деактивирован')
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'произошла ошибка'))
    },
    activate () {
      this.$axios
        .post('layer-poi-template/activate', null, {
          params: { id: this.poiTemplate.id }
        })
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Шаблон активирован')
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Поизошла ошибка'))
    },
    addField () {
      this.poiTemplate.fields.push({ ...DEFAULT_FIELD })
    },
    save () {
      this.poiTemplate.fields.forEach((it, index) => it.index = index)
      this.$axios
        .post('layer-poi-template', this.poiTemplate)
        .then(() => {
          EventBus.$emit('showSuccessMessage', 'Шаблон сохранен')
          this.$emit('onSave')
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'При сохранении шаблона произошла ошибка'))
    }
  }
}
</script>