import { getArea, getLength } from 'ol/sphere'

const regExp = /\B(?=(\d{3})+(?!\d))/g

export const mapMeasureMixin = {
  methods: {
    formatLength (line, options = null) {
      let length = getLength(line, options)
      let output
      if (length > 100) {
        let val = (Math.round(length / 1000 * 100) / 100).toString()
        output = val.replace(regExp, ' ') + ' км'
      } else {
        let val = (Math.round(length * 100) / 100).toString()
        output = val.replace(regExp, ' ') + ' м'
      }
      return output
    },
    formatArea (polygon, options = null) {
      let area = getArea(polygon, options)
      let output
      if (area > 1000000) {
        let val = (Math.round(area / 1000000)).toString()
        output = val.replace(regExp, ' ') + ' км<sup>2</sup>'
      } else if (area > 5000) {
        let val = (area / 1000000 * 100).toFixed(1).toString()
        output = val.replace(regExp, ' ') + ' га'
      } else {
        let val = (Math.round(area * 100) / 100).toString()
        output = val.replace(regExp, ' ') + ' м<sup>2</sup>'
      }
      return output
    }
  }
}
