<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <v-card>
      <v-card-title>
        Пользователи по компаниям
      </v-card-title>
      <v-card-text>
        <ul
          v-for="(listOfUser, company, index) in usersByCompany"
        >
          <span v-if="company !== 'null'">
            {{ ++index }}. {{ company }} - {{ listOfUser.length }}
          </span>
          <div
            v-else
            class="mt-3"
          >
            Другие пользователи - {{ listOfUser.length }}
          </div>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UsersByCompanyDialog",
  props: ['usersByCompany'],
  data: () => ({
    dialog: false
  }),
  methods: {
    open() {
      this.dialog = true
    }
  }
}
</script>
