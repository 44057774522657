<template>
  <view-data-page
    :data="data"
    :selected-view="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
  >

    <template slot="actions">
      <v-dialog
        hide-overlay
        persistent
        v-model="loadingDialog"
        width="300">
        <v-card>
          <v-card-text>
            {{ $t('exportExecuted') }}
            <v-progress-linear
              class="mb-0"
              color="primary"
              indeterminate/>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-menu offset-x>
        <template v-slot:activator="{ on }">
          <v-list-item dense v-on="on">
            <v-list-item-avatar>
              <v-icon>cloud_download</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{ $t('export') }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item :key="index"
                       @click="exportData(item)"
                       v-for="(item, index) in exportFormats">
            <v-list-item-title>{{ $t('format') }} {{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template slot="doc">
      <v-card-actions v-show="setAbility('MODERATOR')">
        <v-btn @click="cancelEdit" small v-if="editMode" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn @click="edit" small v-if="!editMode" outlined>
          <v-icon small left>create</v-icon>
          {{ $t('button.edit') }}
        </v-btn>
        <v-btn @click="save" small v-if="editMode" outlined>
          <v-icon small left>check</v-icon>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-runtime-template v-if="!editMode" :template="'<div>' + body + '</div>'"/>

        <vue-editor v-if="editMode" class="pa-3" v-model="draft"/>
      </v-card-text>
    </template>
  </view-data-page>
</template>

<script>
  import VRuntimeTemplate from 'v-runtime-template'
  import ViewDataPage from '../utils/ViewDataPage'
  import { VueEditor } from 'vue2-editor'
  import { EventBus } from '../../event-bus'
  import messages from '../../componet-locale/report-view/messages'

  export default {
    name: 'ReportView',
    props: {
      splitScreenMode: {
        type: Boolean,
        default: false
      },
      dataId: Number
    },
    components: { ViewDataPage, VRuntimeTemplate, VueEditor },
    i18n: { messages: messages },
    data: () => ({
      editMode: false,
      draft: '',
      selectedView: 'comments',
      tabList: [
        {
          icon: 'message',
          view: 'comments',
        }
      ],
      data: {
        owner: {
          firstName: ''
        },
        project: {
          name: ''
        }
      },
      props: [],
      response: '',
      body: '',
      chartsIds: [],
      exportFormats: ['pdf', 'docx'],
      loadingDialog: false,
      exportFailed: false
    }),
    methods: {
      selectView (view) {
        this.selectedView = view
      },
      edit () {
        this.draft = this.data.body
        this.editMode = true
      },
      cancelEdit () {
        this.draft = ''
        this.editMode = false
      },
      save () {
        this.data.body = this.draft
        this.$axios
          .post('report/save', this.data)
          .then(() => {
            this.body = this.draft
            this.processing()
            this.editMode = false
          })
      },
      init () {
        let id = this.dataId || this.$route.params.id
        this.$axios
          .get('data/get', { params: { id: id } })
          .then(response => {
            this.data = response.data
            this.body = this.data.body
            this.processing()
          })
          .catch(reason => {
            EventBus.$emit('showErrorMessage', 'error')
            console.error(reason.data.message)
          })
      },
      async replaceAsync (str, regex, asyncFn) {
        const promises = []
        str.replace(regex, (match, ...args) => {
          const promise = asyncFn(match, ...args)
          promises.push(promise)
        })
        const data = await Promise.all(promises)
        return str.replace(regex, () => data.shift())
      },
      async processing () {
        this.chartsIds = []
        let nameRegExp = /\[DATA-\d{0,}]/g
        this.body = await this.replaceAsync(
          this.body,
          nameRegExp,
          async data => {
            let id = data.replace(/[^0-9]/g, '')
            let response = await this.$axios.get('/data/get', { params: { id: id } })
            switch (response.data.dataType) {
              case 'TABLE' :
                return `<table-widget :id="${response.data.id}"/>`
              case 'CHART' :
                this.chartsIds.push(response.data.id)
                return `<chart-widget :id="${response.data.id}"/>`
              case 'MAP' :
                this.chartsIds.push(response.data.id)
                return `<map-widget style="height: 500px" :id="${response.data.id}"/>`
              default :
                return ''
            }
          })
      },
      exportData (format) {
        this.loadingDialog = true
        let id = this.$route.params.id
        let json = {}
        this.chartsIds.forEach(id => {
          let imageData = document.getElementById(`image-data-${id}`)
          let canvas = imageData ? imageData.getElementsByTagName('canvas')[0] : null
          if (canvas) json[id] = canvas.toDataURL()
        })
        this.$axios
          .post(`/report/export/${id}`, json, {
            params: { format: format },
            responseType: 'arraybuffer'
          })
          .then(response => {
            let url = window.URL.createObjectURL(new Blob([response.data]))
            let link = document.createElement('a')
            link.href = url
            link.download = `${this.data.project.name}.${format}`
            document.body.appendChild(link)
            link.click()
          })
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
          .finally(() => this.loadingDialog = false)
      }
    },
    created () {
      this.init()
    },
    watch: {
      '$route.params.id' () {
        this.init()
      }
    }
  }
</script>
