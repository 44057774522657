import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueLayers from 'vuelayers'
import { globalMixin } from './mixins/global-mixin'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'
import websocket from './services/websocket'
import PortalVue from 'portal-vue'
import i18n from './plugins/i18n'

Vue.config.productionTip = false
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
axios.defaults.timeout = 20000
Vue.prototype.$axios = axios
Vue.prototype.$ws = websocket

let infiniteScroll = require('vue-infinite-scroll')

Vue.mixin(globalMixin)
Vue.use(infiniteScroll)
Vue.use(VueLayers)
Vue.use(PortalVue)

new Vue({
  router: router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

axios.get('settings/get-general')
  .then(response => {
    document.title = response.data.COMPANY_NAME

    let link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = response.data.COMPANY_FAVICON
    document.getElementsByTagName('head')[0].appendChild(link)

    document.querySelector('link[rel=\'shortcut icon\']').href = response.data.COMPANY_FAVICON
    document.querySelector("link[rel*='icon']").href = response.data.COMPANY_FAVICON
  })
