<template>
  <div>
    <div class="pb-2">
      <v-select
        prepend-inner-icon="layers"
        outlined
        label="Выберите слой-подложку"
        dense
        multiple
        v-if="baseLayers.length"
        v-model="selectedBaseLayers"
        hide-details
        clearable
        :items="baseLayers"
        return-object
        item-text="name"
      />
    </div>
    <div class="d-map-widget">
      <div style="width: 100%; height: 0; text-align: right">
        <v-btn
          style="z-index: 20; padding: 10px"
          small
          fab
          @click="dialog = true">
          <v-icon>fullscreen</v-icon>
        </v-btn>
      </div>
      <layer-poi-map-viewer
        :poi="poi"
        :selected-base-layers="selectedBaseLayers"
      />
    </div>

    <v-dialog v-model="dialog" fullscreen>
      <div style="background: white; width: 100%; height: 100vh">
        <v-btn
          absolute
          small
          fab
          @click="dialog=false"
          style="top: 20px"
          right
        >
          <v-icon>fullscreen_exit</v-icon>
        </v-btn>
        <layer-poi-map-viewer
          :poi="poi"
          :selected-base-layers="selectedBaseLayers"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import LayerPoiMapViewer from '@/components/layer-poi/LayerPoiMapViewer'

export default {
  name: 'LayerPoiMapWidget',
  props: {
    poi: Object
  },
  components: { LayerPoiMapViewer },
  data: () => ({
    dialog: false,
    baseLayers: [],
    selectedBaseLayers: []
  }),
  created () {
    this.loadBaseLayers()
  },
  methods: {
    loadBaseLayers () {
      this.$axios
        .get('/layer/get-xyz-layers', {
          params: {
            projectId: this.selectedProject.id
          }
        })
        .then(response => {
          this.baseLayers = response.data
        })
    }
  },
  watch: {
    poi () {
      this.layer = {
        features: [this.poi]
      }
    }
  }
}
</script>

<style>
.d-map-widget {
  width: 100%;
  height: 300px;
}

.d-map-widget .ol-viewport {
  border-radius: 8px
}
</style>
