<template>
  <vl-geoloc @update:position="onUpdatePosition">
    <template #default="geoloc">
      <vl-feature
        v-if="geoloc.position"
        id="position-feature"
      >
        <vl-geom-point
          :radius="10"
          :coordinates="geoloc.position"
        />
        <vl-style-box>
          <vl-style-circle :radius="8">
            <vl-style-stroke
              :width="5"
              :color="pulse ? '#649DE3D6' : 'rgba(136,193,255,0.84)'"
            />
            <vl-style-fill color="#2D6DBEFF"/>
          </vl-style-circle>
        </vl-style-box>

      </vl-feature>
    </template>
  </vl-geoloc>
</template>

<script>
export default {
  name: "UserGeoloc",
  props: {state: Object},
  data: () => ({
    pulse: false
  }),
  created() {
    this.makePulse()
  },
  methods: {
    makePulse() {
      setTimeout(
        () => {
          this.pulse = !this.pulse
          this.makePulse()
        },
        800
      )
    },
    onUpdatePosition(coordinate) {
      if (!this.state.userLocation && coordinate) {
        this.$emit('onFindPosition', coordinate)
      }
      this.state.userLocation = coordinate
    }
  }
}
</script>
