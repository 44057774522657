<template>
  <abstract-data-list ref="abstractDataList" type="CALC" view="calc-list">
    <template slot="actions">
      <v-btn @click="createNewItem"
             color="primary"
             outlined
             v-if="setAbility('MODERATOR')">
        {{ $t('button.create') }}
      </v-btn>
    </template>
    <calculation-edit-dialog @on-save="onSave" ref="editDialog"/>
  </abstract-data-list>
</template>

<script>
  import AbstractDataList from '../utils/AbstractDataList'
  import CalculationEditDialog from './CalculationEditDialog'

  export default {
    name: 'CalculationList',
    components: { CalculationEditDialog, AbstractDataList },
    methods: {
      createNewItem () {
        this.$refs.editDialog.open()
      },
      onSave () {
        this.$refs.abstractDataList.filterData()
      }
    }
  }
</script>
