<template>
  <v-dialog
    v-model="dialog"
    width="900"
    scrollable
  >
    <v-card>
      <v-card-title>
        Дорожная карта
        <v-spacer/>
        <v-btn
          v-if="setAbility('ADMIN')"
          @click="$refs.projectTimelineConfigDlg.open()"
          icon
        >
          <v-icon v-html="'edit'"/>
        </v-btn>
        <v-btn
          @click="dialog=false"
          icon
        >
          <v-icon v-html="'close'"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-timeline clipped dense>
          <v-timeline-item
            v-for="item in timeline.steps"
            :color="item.color ? item.color : 'blue'"
            fill-dot
          >
            <v-card>
              <v-card-title class="subtitle-1">
                <span class="bold" style="word-break: normal !important;">{{ item.title }}</span>
                <v-spacer/>
                {{ item.tag }}
                <v-tooltip
                  max-width="450"
                  :color="item.color ? item.color : 'blue'"
                  top
                  v-if="item.tooltip"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                    >
                      <v-icon>info</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ item.tooltip }}</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="pt-0" v-html="item.description"/>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <v-card
          v-if="timeline.description"
          outlined
          class="mt-2"
        >
          <v-card-text v-html="timeline.description"/>
        </v-card>
      </v-card-text>
    </v-card>

    <project-timeline-config-dlg
      ref="projectTimelineConfigDlg"
      @onSave="init"
    />
  </v-dialog>
</template>

<script>
import ProjectTimelineConfigDlg from '@/components/project/ProjectTimelineConfigDlg';

export default {
  name: 'ProjectTimelineDialog',
  components: { ProjectTimelineConfigDlg },
  data: () => ({
    dialog: false,
    timeline: {
      steps: []
    }
  }),
  methods: {
    async init () {
      let projectId = this.selectedProject.id
      let { data } = await this.$axios.get('project/get', { params: { id: projectId } })
      this.timeline = data.timeline ? JSON.parse(data.timeline) : {
        description: '',
        steps: []
      }
    },
    async open () {
      await this.init()
      this.dialog = true
    }
  }
}
</script>
