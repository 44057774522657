<template>
  <v-card outlined>
    <v-list-item>
      <v-list-item-avatar>
        <user-avatar
          :user="user"
          dense
          online-dot
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ getUserFullName(user) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ getUserInfo(user) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          v-if="setAbility('ADMIN')"
          v-show="user.role === 'USER'"
          icon
          @click="edit"
        >
          <v-icon>more_vert</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-card-actions class="pt-0">
      <v-spacer/>
      <v-btn
        :disabled="user.id === currentLoginUser.id"
        color="primary"
        outlined
        @click="sendMessage"
      >
        {{ $t('write') }}
        <v-icon right>chat</v-icon>
      </v-btn>
    </v-card-actions>

    <user-project-edit-dialog ref="userProjectEditDialog"
                              @on-remove="removeUserProject"/>
  </v-card>
</template>

<script>
import UserProjectEditDialog from '@/components/admin/user-management/UserProjectEditDialog'
import UserAvatar from '@/components/utils/UserAvatar'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/user-card/messages'

export default {
  name: 'UserCard',
  components: { UserProjectEditDialog, UserAvatar },
  i18n: { messages: messages },
  props: ['user'],
  methods: {
    edit() {
      this.$axios
        .get('users/get-user-project-by-user', {
          params: {
            userId: this.user.id,
            projectId: this.selectedProject.id
          }
        })
        .then(response => {
          this.$refs.userProjectEditDialog.editUserProject(response.data)
        })
    },
    removeUserProject(id) {
      this.$axios
        .post('project/remove-user-project', null, {
          params: { id: id }
        })
        .then(() => {
          this.$emit('on-remove', id)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    sendMessage() {
      this.$axios
        .post('chats/direct', null, {
          params: { userId: this.user.id }
        })
        .then(({ data }) => {
          EventBus.$emit('show-chats', data)
          this.$emit('close')
        })
    }
  }
}
</script>
