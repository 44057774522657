<template>
  <div style="height: 100vh; width: 100%">
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title>
          <v-list-item>
            <v-list-item-content>
              {{ data.name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="dialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          {{ data.description }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      style="position: fixed; z-index: 30; background: white; width: 100%; border-bottom: 1px solid #d5d5d5; height: 74px">
      <v-card-title class="py-0">
        <v-list-item
          v-if="data"
          :style="displayXS ? 'width: 100%' : 'max-width: 400px;'"
          three-line
        >
          <v-list-item-action
            v-if="data.icon">
            <img
              :src="data.icon"
              height="50px"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-subtitle class="subtitle-1">
              {{ data.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action
            v-if="displayXS">
            <v-btn @click="searchBar=true" icon>
              <v-icon>search</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-spacer/>
        <span v-if="!displayXS">
          <v-btn @click="$refs.linkDialog.share()" icon v-if="!displayXS">
            <v-icon>share</v-icon>
          </v-btn>
          <v-btn @click="dialog=true" icon v-if="data && data.description">
            <v-icon>info</v-icon>
          </v-btn>
          <v-btn @click="searchBar=true" icon>
            <v-icon>search</v-icon>
          </v-btn>
        </span>
      </v-card-title>

    </div>
    <v-bottom-sheet v-model="searchBar" max-width="600">
      <v-card style="height: 90vh; overflow-y: auto" class="py-3">
        <portal-target name="map-search"/>
      </v-card>
    </v-bottom-sheet>
    <d-map
      ref="map"
      style="padding-top: 74px"
      v-if="layers"
      :map="data">

      <div
        v-show="!displayXS"
        @click="menu=true"
        v-if="!menu"
        style="cursor: pointer; position: fixed; width: 20px; height: 100%; z-index: 30">
        <div
          style="width: 25px; padding-right: 10px; margin-top: 45vh; background: rgb(96,96,96); border-radius: 0 6px 6px 0">
          <v-icon large color="white" left>arrow_right</v-icon>
        </div>
      </div>
      <div
        v-show="!displayXS"
        v-if="menu"
        style="position: absolute; left: 0; bottom: 0; width: 280px; height: calc(100% - 75px); z-index: 30; background: white">
        <v-card-text>
          <span class="subtitle-1">Список слоев</span>
          <map-config
            class="mt-2"
            :layers.sync="layers"
            @refresh-map="$refs.map.refresh()"/>
        </v-card-text>
        <div @click="menu=false"
             style="width: 100%; height: 40px; cursor: pointer; bottom: 0; position: absolute;">
          <v-divider class="mb-2"/>

          <span class="pa-3">
            Скрыть
          </span>
        </div>
      </div>
    </d-map>
    <share-data-dialog ref="linkDialog"/>
  </div>
</template>

<script>
import MapConfig from '@/components/map/helpers/MapConfig'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import DMap from '@/components/map/DMap'

export default {
  name: 'Frame',
  components: { DMap, ShareDataDialog, MapConfig },
  mixins: [displayServiceMixin],
  data: () => ({
    dialog: false,
    menu: false,
    searchBar: false,
    layers: null,
    data: {}
  }),
  mounted () {
    this.init()
    EventBus.$on('showMapFeature', () => this.searchBar = false)
  },
  methods: {
    init () {
      let id = this.$route.params.id
      this.$axios
        .get('public-data/get', { params: { id: id } })
        .then(response => {
          let map = response.data
          map.position = map.position ? JSON.parse(map.position) : null
          this.layers = map.layerConfigList
            .sort((a, b) => {
              return a.indexNumber > b.indexNumber ? 1 : -1
            })
            .map(it => {
              let layer = it.layer
              layer.visible = it.visible
              return layer
            })
          this.data = map
        })
    }
  }
}
</script>
