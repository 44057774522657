<template>
  <v-dialog v-model=dialog :fullscreen="fullscreen" scrollable>
    <v-card height="100vh">
      <v-card-actions v-if="data">
        {{ data.name }}
        <v-spacer/>
        <v-btn icon @click="dialog=false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-card-text class="pa-0" style="overflow: hidden">
        <component
          :is="getComponentByType(data.dataType)"
          :data-id="data.id"
          v-if="data"
          split-screen-mode
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MapView from '@/components/map/MapView'
import DocView from '@/components/doc/DocView'
import TableView from '@/components/table/TableView'
import SearchDataDialog from '@/components/utils/SearchDataDialog'
import ChartView from '@/components/chart/ChartView'
import ReportView from '@/components/report/ReportView'
import LayerView from '@/components/map/LayerView'
import CalculationView from '@/components/calc/CalculationView'
import { EventBus } from '@/event-bus'

export default {
  name: 'AbstractDataViewDialog',
  components: { CalculationView, LayerView, ReportView, ChartView, SearchDataDialog, TableView, DocView, MapView },
  data: () => ({
    data: null,
    dialog: false,
    fullscreen: false
  }),
  mounted () {
    EventBus.$on('showData', data => this.open(data))
  },
  methods: {
    open (data) {
      this.data = null
      this.$axios
        .get('data/get', {
          params: { id: data.id }
        })
        .then(response => this.data = response.data)
      this.dialog = true
    },
    getComponentByType (dataType) {
      switch (dataType) {
        case 'DOC':
          return 'doc-view'
        case 'TABLE':
          return 'table-view'
        case 'LAYER':
          return 'layer-view'
        case 'MAP':
          return 'map-view'
        case 'CHART':
          return 'chart-view'
        case 'CALC':
          return 'calculation-view'
        case 'REPORT':
          return 'report-view'
      }
    },
  }
}
</script>
