<template>
  <div class="pdf-zoom-container position-absolute d-flex flex-column mb-12 mr-12">
    <v-btn v-if="showFitWidth" @click="fitWidth" x-small fab>
      <v-icon>fullscreen</v-icon>
    </v-btn>
    <v-btn v-else @click="fitAuto" x-small fab class="mt-5">
      <v-icon>fullscreen_exit</v-icon>
    </v-btn>
    <v-btn @click="zoomIn" x-small fab class="mt-6">
      <v-icon>add</v-icon>
    </v-btn>
    <v-btn @click="zoomOut" x-small fab class="mt-3">
      <v-icon>remove</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PdfZoom',
  props: {
    scale: {
      type: Number
    },
    increment: {
      type: Number,
      default: 0.25
    },
  },
  data: () => ({
    showFitWidth: true
  }),
  methods: {
    zoomIn () {
      this.updateScale(this.scale + this.increment)
    },
    zoomOut () {
      if (this.scale <= this.increment) return
      this.updateScale(this.scale - this.increment)
    },
    updateScale (scale) {
      this.$emit('change', { scale })
    },
    fitWidth () {
      this.showFitWidth = false
      this.$emit('fit', 'width')
    },
    fitAuto () {
      this.showFitWidth = true
      this.$emit('fit', 'auto')
    }
  }
}
</script>

<style scoped>
.pdf-zoom-container {
  z-index: 2;
  bottom: 0;
  right: 0;
}
</style>
