<template>
  <div :style="projectBackground" class="project-avatar">
    <span class="white--text">{{ project.name[0] }}</span>
  </div>
</template>

<script>
  export default {
    name: 'ProjectAvatar',
    props: ['project'],
    computed: {
      projectBackground () {
        let hash = 0
        for (let i = 0; i < this.project.name.length; i++) {
          hash = this.project.name.charCodeAt(i) + ((hash << 5) - hash)
        }
        hash = Math.abs(hash)
        const reverseHash = parseInt(hash.toString().split('').reverse().join(''))
        const leftColor = { r: hash % 255, g: 2 * hash % 255, b: 3 * hash % 255 }
        const rightColor = { r: reverseHash % 255, g: 2 * hash % 255, b: 3 * hash % 255 }
        return { 'background-image': `linear-gradient(to bottom right, rgba(${leftColor.r}, ${leftColor.g}, ${leftColor.b},.5), rgba(${rightColor.r}, ${rightColor.g}, ${rightColor.b}))` }
      }
    }
  }
</script>
