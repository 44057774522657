<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>
        Добавить шаблон
        <v-spacer/>
        <v-btn
          @click="dialog=false"
          icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            dense
            v-for="template in poiTemplateList"
            @click="bind(template)"
          >
            <layer-poi-badge :template="template"/>
            <span
              class="caption"
              v-html="template.name"
            />
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LayerPoiBadge from "@/components/layer-poi/LayerPoiBadge";

export default {
  name: "FindLayerPoiTemplateDialog",
  components: { LayerPoiBadge },
  props: { layer: Object },
  data: () => ({
    dialog: false,
    poiTemplateList: []
  }),
  methods: {
    bind(template) {
      this.$emit('bind', template)
      this.dialog = false
    },
    open() {
      let ids = this.layer.poiTemplates.map(it => it.id)
      this.$axios
        .get('layer-poi-template')
        .then(response => {
          this.poiTemplateList = response.data.filter(it => !ids.includes(it.id))
        })
      this.dialog = true
    }
  }
}
</script>
