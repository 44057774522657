<template>
  <div>
    <v-dialog v-model="addressDialog" width="600">
      <v-card>
        <v-card-title>
          <v-toolbar-title>
            {{ $t('addressDialogTitle') }}
          </v-toolbar-title>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-text-field :rules="emailRules" v-model="email">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn outlined
                 @click="addressDialog=false">
            {{ $t('button.close') }}
          </v-btn>
          <v-spacer/>
          <v-btn outlined
                 color="red"
                 :disabled="!isValidEmail"
                 :loading="loadingSubmitButton"
                 @click="submit">
            {{ $t('button.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resultDialog" width="600">
      <v-card>
        <v-card-text>
          {{ $t('resultDialogText') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined
                 @click="resultDialog=false">
            {{ $t('button.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import messages from '@/componet-locale/test-email-dialog/messages'
import inputRules from '@/shared-locale/input-rules/messages'
import axios from 'axios'
import { EventBus } from '@/event-bus'
import { EMAIL_PATTERN } from '@/utils/regexp'

export default {
  name: 'TestEmailDialog',
  data: () => ({
    addressDialog: false,
    resultDialog: false,
    loadingSubmitButton: false,
    email: undefined
  }),
  i18n: { messages: messages,
          sharedMessages: inputRules},
  methods: {
    open () {
      this.email = this.currentLoginUser.email
      this.loadingSubmitButton = false
      this.addressDialog = true
    },
    submit () {
      this.loadingSubmitButton = true
      axios
        .post('/settings/send-test-message', null, { params: { email: this.email } })
        .then(() => {
          this.switchDialogs()
        })
        .catch(() => {
          this.addressDialog = false
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    switchDialogs () {
      this.addressDialog = false
      this.resultDialog = true
    }
  },
  computed: {
    emailRules () {
      return [
        v => !!v || this.$t('emptyEmail'),
        v => EMAIL_PATTERN.test(v) || this.$t('invalidEmail'),
      ]
    },
    isValidEmail () {
      return EMAIL_PATTERN.test(this.email)
    }
  }
}
</script>

<style scoped>

</style>
