<template>
  <div id="map-view-sfeature">
    <slot/>
    <div id="sfeature-container">
      <div id="sfeature-info">
        <v-list-item>
          <v-list-item-content class="py-2">
            <slot name="name"/>
            <v-list-item-subtitle>
              <slot name="subtitle"/>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ma-0">
            <v-btn
              icon
              small
              @click="$emit('close')"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <slot name="info"/>
        <slot name="search"/>
      </div>
      <div id="sfeature-fields">
        <slot name="fields"/>
      </div>
      <v-card-actions id="sfeature-actions">
        <slot name="actions"/>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'FeatureCard',
  mixins: [displayServiceMixin]
}
</script>

<style scoped>
#map-view-sfeature {
  position: fixed;
  z-index: 400;
  width: 360px;
  padding: 10px;
  top: 90px;
  height: calc(100% - 140px);
  right: 0;
}

@media only screen and (max-width: 600px) {
  #map-view-sfeature {
    position: fixed;
    width: 100%;
    padding: 10px;
    top: inherit;
    bottom: 0;
    left: 0;
  }
}

#sfeature-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background: white;
  border: 1px solid #d0d0d0;
}

#sfeature-fields {
  padding: 10px;
  flex-grow: 5;
  overflow-y: auto;
}

#sfeature-info {
  flex-grow: 0;
}

#sfeature-actions {
  flex-grow: 0;
}
</style>
