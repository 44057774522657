<template>
  <div>
    <v-card
      v-for="item in addressList"
      @click.native="showAddress(item)"
      class="ma-2 search-result-row pointer"
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
        <span class="caption">
            {{ item.properties.display_name }}
        </span>
        </v-list-item-content>
        <v-list-item-action>
          <v-img
            :src="item.properties.icon"
            width="20"
          />
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import ObjectLayer from "@/components/map/ObjectLayer";

export default {
  name: "AddressSearchResult",
  components: { ObjectLayer },
  props: { addressList: Array },
  methods: {
    showAddress(item) {
      this.$emit('show', item.bbox)
    }
  }
}
</script>
