<template>
  <div>
    <div v-if="layer">
      <v-card-actions>
        <v-breadcrumbs :items="breadcrumbs"/>
        <v-spacer/>
        <action-toolbar
          :actions="actions"
          @download="exportCsv"
          @removeAll="$refs.confirmationDialog.open(layer)"
          :bottom="true"
        />
      </v-card-actions>
      <v-card-actions>
        <span style="width: 180px">
          <v-select
            dense
            label="Фильтр"
            hide-details
            outlined
            item-text="name"
            v-model="filterBy"
            class="group-input-left"
            :items="filters"
            return-object
            prepend-icon="mdi-filter"
          />
        </span>

        <span style="width: 180px">
          <v-select
            v-if="filterBy.type === 'LIST' || filterBy.type === 'BOOLEAN'"
            outlined
            label="Значение"
            class="group-input-right"
            dense
            clearable
            v-model="filterValue"
            hide-details
            :items="getOptions()"
          >
            <template v-slot:item="{ item }">
                <span v-if="filterBy.type === 'BOOLEAN'">
                  {{ $t(item) }}
                </span>
              <span v-else>{{ item }}</span>
            </template>
            <template v-slot:selection="{ item }">
                <span v-if="filterBy.type === 'BOOLEAN'">
                  {{ $t(item) }}
                </span>
              <span v-else>{{ item }}</span>
            </template>
          </v-select>
          <v-text-field
            label="Значение"
            class="group-input-right"
            v-model="filterValue"
            v-else
            dense
            clearable
            hide-details
            outlined
          />
        </span>
        <v-spacer/>
        <v-select
          outlined
          label="Тип объектов"
          v-model="selectedTemplate"
          return-object
          v-if="layer.poiTemplates.length > 1"
          hide-details
          dense
          :items="layer.poiTemplates"
          item-text="name"
        />
      </v-card-actions>


      <v-data-table
        :headers="headers.filter(f => !f.excludeFromTableView)"
        :server-items-length="totalElements"
        :options.sync="options"
        :items="items"
        locale="ru"
      >

        <template v-slot:item="{ item, headers }">
          <tr @click="openDialog(item)">
            <td
              v-for="(header, index) in headers"
              :key="index"
            >
              <template v-if="header.type === 'LINK'">
                <a
                  target="_blank"
                  :href="item[header.value]"
                >
                  {{ item[header.value] }}
                </a>
              </template>

              <template v-else-if="header.type === 'NUMBER' ||  header.type === 'COMPUTED_FIELD'">
                {{ item[header.value] ? item[header.value].toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') : '' }}
              </template>

              <template v-else-if="header.type === 'CUSTOM_LINK'">
                <a
                  target="_blank"
                  :href="item[header.options] + item[header.value]"
                >
                  {{ item[header.value] }}
                </a>
              </template>

              <template v-else-if="header.type === 'BOOLEAN'">
                {{ item[header.value] ? $t('true') : $t('false') }}
              </template>

              <template v-else>
                {{ item[header.value] }}
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <confirmation-dialog
      ref="confirmationDialog"
      title="<span class='red--text font-weight-bold'>Внимание, это действие необратимо!</span><br/>Вместе с этой операцией история изменений по объектам также будет удалена.<br/> Вы действительно хотите удалить все данные типа: "
      @confirm="removeAll"
    />
    <layer-poi-dialog
      map-widget
      actions-toolbar
      ref="layerPoiDlg"
      @update="loadPoiList"
    />
  </div>
</template>

<script>
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import ActionToolbar from '@/components/utils/ActionToolbar'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import messages from '@/componet-locale/report-view/messages'
import { EventBus } from '@/event-bus'
import { HISTORY } from '@/components/map/helpers/map-actions'

export default {
  name: 'LayerPoiTable',
  components: { ActionToolbar, ConfirmationDialog, LayerPoiDialog },
  i18n: { messages: messages },
  data: () => ({
    selectedTemplate: null,
    layer: null,
    headers: [],
    filters: [],
    filterBy: {},
    filterValue: '',
    options: {},
    totalElements: 0,
    items: [],
    fieldValueMapper: {
      LINK: 'value',
      CUSTOM_LINK: 'value',
      LIST: 'value',
      MULTIPLE_LIST: 'value',
      BOOLEAN: 'boolean',
      STRING: 'string',
      NUMBER: 'number',
      DATE: 'date',
      COMPUTED_FIELD: 'value',
      DATA_TABLE: 'value'
    },
    actions: [
      HISTORY,
      {
        title: 'Удалить все',
        icon: 'delete_outline',
        color: 'red',
        background: '#ffe0e0',
        action: 'removeAll',
        userRole: 'ADMIN'
      },
      {
        title: 'Экспорт',
        icon: 'file_download',
        color: 'white',
        primary: true,
        background: '#2d89ff',
        action: 'download'
      }
    ],
    cancel: undefined
  }),
  created () {
    this.init()
  },
  methods: {
    exportCsv () {
      const body = this.getRequestBody()
      this.$axios
        .post(`/layer-poi/export/xlsx`, body, {
          responseType: 'arraybuffer',
          params: { layerId: this.layer.id, templateId: this.selectedTemplate.id }
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          let name = this.selectedTemplate.name || 'layer-poi'
          link.download = `${name}.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
    },
    removeAll (layer) {
      this.$axios.delete('layer-poi/delete-by-template', {
        params: { layerId: layer.id, templateId: this.selectedTemplate.id },
        timeout: 60000
      })
        .then(() => {
          this.loadPoiList()
          EventBus.$emit('showSuccessMessage', 'Данные удалены')
        })
        .catch(() =>
          EventBus.$emit('showErrorMessage', 'Произошла ошибка'))
    },
    getOptions () {
      if (this.filterBy.type === 'BOOLEAN') return ['true', 'false']
      else if (this.filterBy.type === 'LIST') return this.filterBy.options.split(',')
    },
    async init () {
      let layerId = this.$route.params.layerId
      let response = await this.$axios
        .get('data/get', {
          params: { id: layerId }
        })

      this.layer = response.data
      this.selectedTemplate = this.layer.poiTemplates[0] || null
    },
    getRequestBody () {
      const body = {
        page: this.options.page - 1,
        rowsPerPage: this.options.itemsPerPage
      }
      if (Object.keys(this.filterBy).length && this.filterValue) {
        body.layerPoiCriteria = {
          fieldId: this.filterBy.id,
          dataType: this.filterBy.type,
          value: this.filterValue
        }
      }
      return body
    },
    loadPoiList () {
      const CancelToken = this.$axios.CancelToken
      let self = this
      if (this.cancel) {
        this.cancel()
      }
      const body = this.getRequestBody()
      let requestParam = {
        layerId: this.layer.id,
        templateId: this.selectedTemplate.id
      }
      this.$axios
        .post('layer-poi/filter', body, {
          params: requestParam,
          cancelToken: new CancelToken(function executor (c) {
            self.cancel = c
          })
        })
        .then(({ data }) => {
          this.totalElements = data.totalElements
          this.populateTableContent(data.content)
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
    },
    populateTableContent (data) {
      this.items = data
        .map(d =>
          d.properties.reduce((previous, property) => {
            const key = this.fieldValueMapper[property.field.type]
            previous[property.field.name] = property[key]
            previous._obj = d
            return previous
          }, {})
        )
    },
    setHeadersFromTemplate (template) {
      this.headers = template.fields.map(f =>
        (
          {
            text: f.name,
            value: f.name,
            sortable: false,
            ...f
          }
        )
      )
      this.options = { page: 1, itemsPerPage: 10 }
    },
    updateFilter (event, column) {
      this.filter[column.id].second = event
      this.loadPoiList()
    },
    initFilter (fields) {
      this.filterBy = {}
      this.filters = []
      fields.forEach(f => {
        if (
          f.type === 'STRING' ||
          f.type === 'LIST' ||
          f.type === 'BOOLEAN'
        ) { this.filters.push(f) }
      })
    },
    openDialog (item) {
      let poi = { ...item._obj }
      this.$refs.layerPoiDlg.open(poi)
    }
  },
  watch: {
    selectedTemplate (val) {
      this.setHeadersFromTemplate(val)
      this.initFilter(val.fields)
      this.loadPoiList()
    },
    filterValue () {
      this.loadPoiList()
    },
    'options.page' () {
      this.loadPoiList()
    },
    'options.itemsPerPage' () {
      this.loadPoiList()
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {
          text: this.selectedProject.name,
          to: 'home'
        },
        {
          text: 'Объекты',
          to: 'poi-management'
        },
        {
          text: this.layer ? this.layer.name : ' - ',
          disabled: true
        }
      ]
    }
  }

}
</script>
