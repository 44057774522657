<template>
  <div>
    <vl-layer-vector :z-index="10">
      <vl-source-vector ref="vlSourceVector">
        <vl-style-box>
          <vl-style-circle :radius="10">
            <vl-style-stroke
              :width="6"
              :color="pulse ? 'blue' : 'red'"
            />
          </vl-style-circle>

          <vl-style-stroke
            :width="5"
            :color="pulse ? 'blue' : 'red'"
          />
        </vl-style-box>
      </vl-source-vector>

      <vl-feature
        v-for="feature in selected"
        :properties="{
          obj: feature
        }"
      >
        <component
          v-if="feature.geometry"
          :is="getComponent(feature)"
          :coordinates="feature.geometry.coordinates"
        />

        <vl-style-box>
          <vl-style-circle :radius="10">
            <vl-style-stroke
              :width="6"
              :color="pulse ? 'blue' : 'red'"
            />
          </vl-style-circle>

          <vl-style-stroke
            :width="5"
            :color="pulse ? 'blue' : 'red'"
          />
        </vl-style-box>
      </vl-feature>
    </vl-layer-vector>

    <selected-feature-card
      v-if="!widget"
      :features="selected"
      @edit="$emit('edit')"
      @close="selected = []"
    />
  </div>
</template>

<script>
import SelectedFeatureCard from '@/components/map/SelectedFeatureCard'

export default {
  name: 'SelectedFeature',
  components: { SelectedFeatureCard },
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: Array,
    widget: Boolean
  },
  data: () => ({
    pulse: false
  }),
  created () {
    this.makePulse(false)
  },
  methods: {
    show (feature) {
      this.$refs.vlSourceVector.addFeature(feature)
    },
    makePulse (pulse) {
      this.pulse = pulse
      setTimeout(() => {
          this.makePulse(!this.pulse)
        },
        600)
    },
    getComponent (feature) {
      switch (feature.geometry.type) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + feature.geometry.type)
      }
    },
    getColor (feature) {
      if (feature.template && feature.template.stroke) {
        return feature.template.stroke
      } else {
        return 'red'
      }
    }
  },
  computed: {
    selected: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  }
}
</script>
