<template>
  <div class="row no-gutters">
    <div class="vertical"/>

    <div class="col"
         @click="view">
      <div v-show="image"
           class="pt-2"
           :style="`max-width: 300px; min-height: ${height}`">
        <img :src="preview"
             style="width: 100%"
             @load="onLoadImage"/>
      </div>

      <v-row no-gutters>
        <!--        Download Button-->
        <v-col cols="auto">
          <v-btn
            :ripple="false"
            elevation="0"
            fab
            x-small
            color="blue"
            class="mr-2"
            :loading="isFileDownloading"
            @click.stop="download">
            <v-icon color="white">
              save_alt
            </v-icon>
          </v-btn>
        </v-col>

        <v-col>
          <v-row no-gutters>
            <v-col cols="12"
                   class="text-truncate">
              {{ jsonMessage.name }}
            </v-col>
            <v-col cols="12">
            <span class="caption grey--text">
              {{ description }}
            </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <file-viewer
      :storage-url="file => `files/get-user-file/${file.token}`"
      ref="fileViewer"
    />
  </div>
</template>

<script>
import moment from 'moment'
import downloadFile from '../../services/file-downloader'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/file-message-content/messages'
import FileViewer from '@/components/drive/FileViewer'

const SUPPORTED_FORMATS = ['jpeg', 'jpg', 'png']
export default {
  name: 'FileMessageContent',
  components: { FileViewer },
  props: {
    message: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data: () => ({
    isFileDownloading: false,
    previewHeight: '700px',
    preview: null
  }),
  i18n: { messages: messages },
  methods: {
    view () {
      this.$refs.fileViewer.open(this.jsonMessage)
    },
    download () {
      this.isFileDownloading = true
      downloadFile(this.url, this.jsonMessage.name)
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('fileDownloadError'))
        })
        .finally(() => this.isFileDownloading = false)
    },
    load () {
      this.$axios
        .get(this.url, { responseType: 'arraybuffer' })
        .then(response => {
          let blob = new Blob([response.data], { type: 'image/jpeg' })
          let urlCreator = window.URL || window.webkitURL
          this.preview = urlCreator.createObjectURL(blob)
        })
    },
    onLoadImage () {
      this.previewHeight = 'auto'
    }
  },
  mounted () {
    if (SUPPORTED_FORMATS.includes(this.jsonMessage.type)) this.load()
  },
  computed: {
    image () {
      return SUPPORTED_FORMATS.includes(this.jsonMessage.type)
    },
    height () {
      return this.image ? this.previewHeight : '0px'
    },
    jsonMessage () {
      return JSON.parse(this.message.decryptedBody).file
    },
    url () {
      return `files/get-user-file/${this.jsonMessage.token}`
    },
    description () {
      let size = this.jsonMessage.size
      let fSExt = ['Bytes', 'KB', 'MB', 'GB'], i = 0
      while (size > 900) {
        size /= 1024
        i++
      }
      const shortSize = (Math.round(size * 100) / 100) + ' ' + fSExt[i]
      const time = moment(this.jsonMessage.creationDate).format('DD.MM.YYYY HH:mm')
      return `${shortSize}, ${time}`
    }
  }
}
</script>

<style scoped>
/*.content-wrapper {*/
/*  display: inline-flex*/
/*}*/

.content-wrapper:hover {
  cursor: pointer;
}

.content-info {
  margin-right: 12px;
  display: flex;
  flex-direction: column
}
</style>
