<template>
  <div>
    <v-card-title>
      Шаблоны задач
      <v-spacer/>
      <v-btn
        outlined
        color="primary"
        @click="create"
      >
        <v-icon>add</v-icon>
        Создать
      </v-btn>
    </v-card-title>
    <v-list>
      <v-list-item
        v-for="(item, index) in templateList"
        :key="index"
        @click="open(item)"
      >
        {{ item.name }}
      </v-list-item>
    </v-list>
    <issue-template-dialog ref="issueTemplateDialog" @onSave="loadData"/>
  </div>
</template>

<script>
import IssueTemplateDialog from '@/components/admin/issue-template-management/IssueTemplateDialog'

export default {
  name: 'IssueTemplateManagement',
  components: { IssueTemplateDialog },
  data: () => ({
    templateList: []
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios
        .get('/issue-template/get-all')
        .then(response => {
          this.templateList = response.data
        })
    },
    open (item) {
      this.$refs.issueTemplateDialog.open(item)
    },
    create () {
      this.$refs.issueTemplateDialog.open()
    }
  }
}
</script>
