<template>
  <div v-if="chartData">
    <v-row
      class="mb-6"
      no-gutters
    >
      <v-card
        v-for="field in modbusUnit.fields"
        :style="{borderColor: field.color}"
        width="300"
        class="ma-2"
        outlined>
        <v-card-text>
          {{ field.name }}
          <div class="headline">
            20
          </div>
<!--          <gauge-widget :value="lastResult.get(field.id)" :field="field"/>-->
        </v-card-text>
      </v-card>
    </v-row>
    <line-chart :styles="canvasStyle"
                :chart-data="chartData"
                :options="options">/
    </line-chart>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LineChart from '../utils/chart/line-chart'
import GaugeWidget from '@/components/widget/GaugeWidget'

export default {
  name: 'ModbusUnitChart',
  components: { GaugeWidget, LineChart },
  props: {
    modbusUnit: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    canvasStyle: {
      width: '100%',
      position: 'relative',
      minWidth: '400px'
    },
    chartData: null,
    datasets: [],
    datasetMap: new Map(),
    lastResult: new Map()
  }),
  computed: {
    ...mapGetters([
      'establishedConnection'
    ]),
    options () {
      return {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            type: 'time',
            distribution: 'series',
            ticks: {
              source: 'auto',
              maxTicksLimit: 10
            }
          }],
          yAxes: this.getYAxes()
        }
      }
    }
  },
  methods: {
    loadInitData () {
      this.chartData = null
      this.datasets = []
      this.datasetMap = new Map()
      this.lastResult = new Map(this.modbusUnit.fields.map(field => [field.id, '-']))

      this.$axios
        .get(`modbus-reader/get-last-data/${this.modbusUnit.id}`)
        .then(({ data }) => {
          this.setData(data)
        })
    },
    getYAxes () {
      return this.modbusUnit.fields.map(field => {
        return {
          id: field.id,
          position: field.postfix === 'Бар' ? 'right' : 'left',
          ticks: {
            fontColor: field.color,
            min: field.minValue,
            max: field.maxValue
          }
        }
      })
    },
    setData (data) {
      const fields = this.modbusUnit.fields
      fields.forEach(field => {
        let fieldData = data.filter(i => i.fieldId === field.id)
        let currentData = this.getPoints(fieldData)
        let dataset = {
          data: currentData,
          fill: false,
          label: field.name,
          postfix: field.postfix,
          borderColor: field.color,
          type: 'line',
          pointRadius: 0,
          lineTension: 0,
          borderWidth: 2,
          yAxisID: field.id
        }
        this.datasetMap.set(field.id, dataset)
      })
      this.updateCharData()
    },
    updateData (data) {
      const fields = this.modbusUnit.fields
      data.forEach(it => {
        this.lastResult.set(it.fieldId, Number(it.value))
      })
      fields.forEach(field => {
        const fieldData = data.filter(i => i.fieldId === field.id)
        const currentData = this.getPoints(fieldData)
        const fieldDataset = this.datasetMap.get(field.id)
        if (fieldDataset) {
          fieldDataset.data.push(...currentData)
          this.datasetMap.set(field.id, fieldDataset)
        }
      })
      this.updateCharData()
    },
    updateCharData () {
      this.datasets = []
      this.datasetMap.forEach(value => this.datasets.push(value))
      this.chartData = {
        datasets: this.datasets
      }
    },
    getPoints (fieldData) {
      return fieldData
        .map(point => {
          return {
            t: Date.parse(point.date),
            y: parseFloat(point.value)
          }
        })
    },
    subscribe (id) {
      this.$ws.client.subscribe(
        `/ws-broker/${id}`,
        it => {
          if (this.chartData) {
            this.updateData(JSON.parse(it.body))
          }
        }
      )
    }
  },
  watch: {
    establishedConnection: {
      handler (val) {
        if (val) this.subscribe(this.modbusUnit.id)
      },
      immediate: true
    },
    modbusUnit (val) {
      if (val) this.loadInitData()
    }
  }
}
</script>
