<template>
  <div>
    <v-card-title>
      Стили объектов
      <v-spacer/>
      <v-btn
        color="primary"
        outlined
        @click="create"
      >
        <v-icon>add</v-icon>
        Создать
      </v-btn>
    </v-card-title>
    <v-list>
      <v-list-item
        :ripple="false"
        v-for="(item, id) in items"
        :key="id"
        @click="open(item)"
      >
        <v-list-item-avatar>
          <v-icon>line</v-icon>
        </v-list-item-avatar>
        {{ item.name }}
      </v-list-item>
    </v-list>
    <poi-style-dialog @update="loadStyles" ref="poiStyleDlg"/>
  </div>
</template>

<script>
import PoiStyleDialog from '@/components/admin/poi-styles/PoiStyleDialog.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'PoiStyles',
  components: { PoiStyleDialog },
  data: () => ({
    items: []
  }),
  created () {
    this.loadStyles()
  },
  methods: {
    loadStyles () {
      this.$axios
        .get('layer-poi-style')
        .then(response => {
          this.items = response.data
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Произошла ошибка при попытке получить данные по стилям')
        })
    },
    create () {
      this.$refs.poiStyleDlg.open()
    },
    open (item) {
      this.$refs.poiStyleDlg.open(item)
    }
  }
}
</script>
