<template>
  <v-autocomplete
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    cache-items
    class="elevation-0"
    clearable
    hide-details
    hide-no-data
    item-text="name"
    :no-data-text="$t('noData')"
    outlined
    :placeholder="$t('search')"
    return-object
    v-model="selection"/>
</template>

<script>
  import messages from '../../componet-locale/search-bar/messages'

  export default {
    name: 'SearchBar',
    data: () => ({
      loading: false,
      items: [],
      selection: {},
      search: null,
    }),
    i18n: { messages: messages },
    watch: {
      search (val) {
        let projectId = this.selectedProject.id
        this.$axios
          .get('/data/search', { params: { str: val, projectId: projectId } })
          .then(response => {
            this.items = response.data
          })
      },
      selection (val) {
        if (val) {
          this.openInNewTab(val)
        }
      }
    }
  }
</script>
