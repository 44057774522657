<template>
  <vl-layer-tile>
    <vl-source-osm/>
  </vl-layer-tile>
</template>

<script>
export default {
  name: "BaseLayer"
}
</script>
