<template>
  <div v-if="state.editMode && features.length">
    <vl-layer-vector :z-index="200">
      <vl-source-vector
        ref="vlSourceVectorDraw"
        :features.sync="features"
        ident="draw-target"
      />
      <measure-style-box/>
    </vl-layer-vector>

    <vl-interaction-modify source="draw-target"/>

    <div style="position:absolute; right: 0; padding: 40px; z-index: 200;">
      <v-btn
        @click="cancel"
      >
        Отменить
      </v-btn>

      <v-btn
        class="ml-4"
        color="primary"
        @click="save"
      >
        Сохранить
      </v-btn>
    </div>
  </div>
</template>

<script>
import MeasureStyleBox from "@/components/map/helpers/measurer/MeasureStyleBox";
import { EventBus } from "@/event-bus";
import { GML, WFS } from "ol/format";

export default {
  name: "FeatureEditor",
  components: { MeasureStyleBox },
  props: { state: Object },
  data: () => ({
    originalFeature: null,
    features: []
  }),
  methods: {
    edit(features) {
      this.state.editMode = true
      this.originalFeature = features[0]
      if (this.originalFeature.dComponent === 'geo-server-feature-card') {
        setTimeout(() => {
          this.$refs.vlSourceVectorDraw.addFeature(features[0])
        }, 200)
      } else {
        this.features = [{
          type: 'Feature',
          geometry: features[0].geometry,
          properties: {}
        }]
      }
    },
    save() {
      let { dComponent } = this.originalFeature
      switch (dComponent) {
        case 'layer-poi-feature-card':
          this.saveLP()
          break;
        case 'geo-server-feature-card':
          this.saveWFS()
          break;
      }

    },
    saveLP() {
      let feature = { ...this.features[0] }
      this.originalFeature.geometry = JSON.stringify(feature.geometry)
      this.$axios
        .post('layer-poi/save', this.originalFeature)
        .then(() => {
          this.$emit('refresh')
          EventBus.$emit('showSuccessMessage', 'Сохранено')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Произошла ошибка')
        })
        .finally(() => this.cancel())
    },
    saveWFS() {
      let { layer } = this.originalFeature
      let formatWFS = new WFS();
      let features = this.$refs.vlSourceVectorDraw.getFeatures()

      let gml = new GML({
        featureNS: layer._ds.targetNamespace,
        featurePrefix: layer._ds.targetPrefix,
        featureType: layer._ds.typeName,
        srsName: 'EPSG:3857'
      })
      let node = formatWFS.writeTransaction(null, features, null, gml)
      let s = new XMLSerializer();
      let value = s.serializeToString(node);
      this.$axios
        .post(window.location.origin + '/geoserver/wfs', value, {
          headers: {
            'Content-Type': 'text/xml',
          },
          params: {
            service: 'wfs',
            outputFormat: ''
          }
        })
        .then(() => {
          this.$emit('refresh')
          EventBus.$emit('showSuccessMessage', 'Сохранено')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', 'Ошибка')
        })
        .finally(() => this.cancel())
    },
    cancel() {
      this.state.editMode = false
      this.features = []
      this.originalFeature = null
    }
  }
}
</script>
