<template>
  <div>
    <div class="navigation-panel" id="admin-navigation-panel">
      <v-card-title>
        <div class="body-1">{{ $t('administration') }}</div>
      </v-card-title>
      <v-divider/>
      <v-list dense>
        <v-list-item :to="item.path" v-for="item in items">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.title) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <router-view id="admin-content"/>
  </div>
</template>

<script>

import messages from '../../componet-locale/admin-page/messages'

export default {
  name: 'AdminPage',
  i18n: { messages: messages },
  data: () => ({
    items: [
      {
        title: 'userManagement',
        path: '/admin/users'
      },
      {
        title: 'logging',
        path: '/admin/user-activity'
      },
      {
        title: 'backup',
        path: '/admin/backup'
      },
      {
        title: 'systemUpdate',
        path: '/admin/update'
      },
      {
        title: 'settings',
        path: '/admin/settings'
      },
      {
        title: 'issueTemplates',
        path: '/admin/issue-templates'
      },
      {
        title: 'poiTemplates',
        path: '/admin/poi-templates'
      },
      {
        title: 'poiStyles',
        path: '/admin/lps'
      },
      {
        title: 'mailTemplates',
        path: '/admin/mail-templates'
      }
    ]
  })
}
</script>

<style>
#admin-navigation-panel {
  height: 100vh;
  background: white;
  border-left: none;
}

#admin-content {
  padding-left: 280px
}
</style>
