<template>
  <div>
    <v-list>
      <v-list-item to="/user-profile" class="py-3">
        <user-avatar :user="currentLoginUser" class="mr-4"/>
        <v-list-item-content>
          <v-list-item-title>{{ `${currentLoginUser.firstName} ${currentLoginUser.lastName}` }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ currentLoginUser.email }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>settings</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider/>
    <v-list dense>
      <v-list-item to="/help">
        <v-list-item-avatar>
          <v-icon>help_outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>{{ $t('help') }}</v-list-item-content>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-avatar>
          <v-icon>exit_to_app</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>{{ $t('exit') }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  import UserAvatar from '@/components/utils/UserAvatar'
  import messages from '../../componet-locale/profile-items/messages'

  export default {
    name: 'ProfileItems',
    components: { UserAvatar },
    i18n: { messages: messages }
  }
</script>
