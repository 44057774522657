<template>
  <div style="width: 100%">
    <grid-layout :col-num="12"
                 :is-draggable="editableMode"
                 :is-mirrored="false"
                 :is-resizable="editableMode"
                 :layout.sync="widgets"
                 :margin="[16, 10]"
                 :row-height="30"
                 :use-css-transforms="true"
                 :vertical-compact="true">

      <grid-item :h="widget.h"
                 :i="widget.i"
                 :w="widget.w"
                 :x="widget.x"
                 :y="widget.y"
                 style="border: 1px #a2a2a2 solid; border-radius: 5px; overflow: hidden"
                 v-for="widget in widgets">

        <v-btn @click="remove(widget.id)" absolute
               class="mt-3" color="red"
               dark fab outlined
               right small
               v-show="editableMode"
               z-index="2">
          <v-icon>close</v-icon>
        </v-btn>

        <gauge-widget v-if="widget.type === 'SENSOR'"/>

        <chart-widget :id="widget.abstractData.id" dense v-if="widget.type === 'CHART'"/>

        <table-widget :id="widget.abstractData.id" dense v-if="widget.type === 'TABLE'"/>

        <map-widget :id="widget.abstractData.id" dense v-if="widget.type === 'MAP'"/>

      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import MapWidget from '../widget/MapWidget'
import ChartWidget from '../widget/ChartWidget'
import GaugeWidget from '../widget/GaugeWidget'
import { EventBus } from '@/event-bus'
import TableWidget from '@/components/widget/TableWidget'
import messages from '../../componet-locale/widget-bar/messages'

export default {
  name: 'WidgetBar',
  mixins: [displayServiceMixin],
  props: ['widgets', 'editableMode'],
  components: {
    TableWidget,
    GaugeWidget,
    ChartWidget,
    MapWidget,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  i18n: { messages: messages },
  methods: {
    remove (id) {
      this.$axios
        .get('/widget/remove', { params: { id: id } })
        .then(() => {
          this.$emit('onRemove')
          EventBus.$emit('showInfoMessage', this.$t('widgetRemoved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    }
  }
}
</script>
