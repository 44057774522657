<template>
  <vl-layer-tile>
    <vl-source-xyz
      :tile-load-function="tileLoader"
      :url="getZwsRequest(layer)"
      crossOrigin="Anonymous"
    />
  </vl-layer-tile>
</template>

<script>
import ZwsCommandBuilder from "@/services/zws-command-builder";

export default {
  name: "ZwsLayer",
  props: {layer: Object},
  methods: {
    tileLoader: ZwsCommandBuilder.tileLoader,
    getZwsRequest: ZwsCommandBuilder.getZwsRequest
  }
}
</script>
