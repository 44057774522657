<template>
  <feature-card @close="$emit('close')">
    <template v-slot:name>
      {{ name }}
    </template>
    <template v-slot:subtitle>
      {{ feature.template.name }}
      <br/>
      <span v-if="geometryInfoInfo" v-html="geometryInfoInfo"/>
    </template>
    <template v-slot:info>
      <action-toolbar
        :actions="actions"
        @remove="openConfirmationDialog"
        @share="share"
        @createIssue="createIssue"
        @history="history"
        @attachData="attachData"
        @calculate="calculate"
        @edit="$emit('edit')"
        class="px-3"
      />
      <div class="caption pa-3">
        Создан: {{ formatDate(feature.creationDate) }}
        <br/>
        Последнее обновление: {{ formatDate(feature.modificationDate) }}
      </div>
    </template>
    <template v-slot:fields>
      <layer-poi-fields
        @refresh="refresh"
        :poi="feature"
      />
    </template>
    <template v-slot:actions>
      <v-btn
        outlined
        @click="$refs.layerPoiDlg.open(feature)"
      >
        Детали
      </v-btn>
      <v-spacer/>
      <v-btn
        outlined
        color="primary"
        @click="save"
      >
        Сохранить
      </v-btn>
      <layer-poi-dialog
        actions-toolbar
        @update="refresh"
        ref="layerPoiDlg"
      />
      <layer-poi-changelog-dialog ref="changelogDlg"/>
    </template>

    <new-issue-dialog
      v-if="isAuthenticated"
      @on-save="$emit('on-update')"
      :map-widget="false"
      ref="newIssueDialog"
    />
    <confirmation-dialog
      title="Вы действительно хотите удлаить объект"
      ref="removeConfirmationDialog"
      @confirm="remove"
    />
    <share-data-dialog ref="shareDataDialog"/>
    <attached-data ref="attachedDataDlg"/>
  </feature-card>
</template>

<script>
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import FeatureCard from '@/components/map/FeatureCard'
import { EventBus } from '@/event-bus'
import LayerPoiDialog from '@/components/layer-poi/LayerPoiDialog'
import NewIssueDialog from '@/components/issue/NewIssueDialog'
import { ATTACHED_DATA, CRATE_ISSUE, EDIT, REMOVE, SHARE, HISTORY, CALC } from '@/components/map/helpers/map-actions'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import { getLinkToLayerPoi, getNameOfLayerPoi, saveLayerPoi } from '@/components/layer-poi/layer-poi-service'
import ActionToolbar from '@/components/utils/ActionToolbar'
import LayerPoiChangelogDialog from '@/components/layer-poi/LayerPoiChangelogDialog'
import { LineString, MultiLineString, MultiPolygon, Polygon } from 'ol/geom'
import { mapMeasureMixin } from '@/mixins/map-measure-mixin'
import { Vector } from 'ol/source'
import { GeoJSON } from 'ol/format'
import AttachedData from '@/components/layer-poi/attachments/AttachedData.vue';

export default {
  name: 'LayerPoiFeatureCard',
  components: {
    AttachedData,
    LayerPoiChangelogDialog,
    ActionToolbar,
    ConfirmationDialog,
    ShareDataDialog,
    NewIssueDialog,
    LayerPoiDialog,
    FeatureCard,
    LayerPoiFields
  },
  mixins: [mapMeasureMixin],
  props: {
    feature: Object
  },
  methods: {
    remove () {
      this.$axios
        .delete('layer-poi', {
          params: { id: this.feature.id }
        })
        .then(() => {
          this.refresh()
        })
    },
    save () {
      saveLayerPoi(
        this.feature,
        () => {
          EventBus.$emit('showSuccessMessage', 'Изменения сохранены')
          this.refresh()
        },
        () => EventBus.$emit('showErrorMessage', 'Произошла ошибка')
      )
    },
    refresh () {
      EventBus.$emit('refreshMap')
    },
    createIssue () {
      this.$refs.newIssueDialog.createLayerPoiIssue(this.feature)
    },
    attachData () {
      this.$refs.attachedDataDlg.open(this.feature)
    },
    calculate () {
      let source = new Vector({
        features: (
          new GeoJSON({
            featureProjection: 'EPSG:3857'
          }))
          .readFeatures([this.feature])
      })
    },
    share () {
      this.$refs.shareDataDialog.share(
        getLinkToLayerPoi(this.feature)
      )
    },
    history () {
      this.$refs.changelogDlg.open(this.feature)
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open({ name: this.name })
    },
    geometry () {
      switch (this.feature.geometry.type) {
        case 'LineString' :
          return new LineString(this.feature.geometry.coordinates)
        case 'MultiLineString' :
          return new MultiLineString(this.feature.geometry.coordinates)
        case 'Polygon' :
          return new Polygon(this.feature.geometry.coordinates)
        case 'MultiPolygon' :
          return new MultiPolygon(this.feature.geometry.coordinates)
      }
    }
  },
  computed: {
    geometryInfoInfo () {
      let geometry = this.geometry()
      switch (this.feature.geometry.type) {
        case 'LineString' :
        case 'MultiLineString' :
          return 'Длина объекта: ' + this.formatLength(geometry, { projection: 'EPSG:4326' })
        case 'Polygon':
        case 'MultiPolygon' :
          return 'Площадь объекта: ' + this.formatArea(geometry, { projection: 'EPSG:4326' })
      }
    },
    actions () {
      return [CRATE_ISSUE, ATTACHED_DATA, SHARE, HISTORY, EDIT, REMOVE]
    },
    name () {
      return getNameOfLayerPoi(this.feature)
    }
  }
}
</script>
