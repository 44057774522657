<template>
  <div>
    <div style="width: 100%" class="doc-frame position-relative" v-if="loading">
      <v-row justify="center">
        <v-overlay absolute color="white">
          <v-progress-circular indeterminate
                               :size="70"
                               :width="7"
                               color="purple"/>
        </v-overlay>
      </v-row>
    </div>

    <pdf-zoom :scale="scale"
              @change="updateScale"
              @fit="updateFit"/>

    <pdf-data :url="url"
              v-show="!loading"
              @page-count="updatePageCount"
              @page-focus="updateCurrentPage"
              @document-rendered="onDocumentRendered"
              @document-errored="onDocumentErrored">
      <template v-slot:preview="{pages}">
        <portal to="doc-structure">
          <pdf-preview class="pdf-viewer__preview"
                       @page-focus="updateCurrentPage"
                       v-bind="{pages, scale, currentPage, pageCount}"/>
        </portal>
      </template>

      <template v-slot:document="{pages}">
        <pdf-document class="preview-enabled"
                      v-bind="{pages, scale, optimalScale, fit, currentPage, pageCount}"
                      @scale-change="updateScale"/>
      </template>
    </pdf-data>
  </div>
</template>

<script>
import PdfData from '@/components/utils/pdf/PdfData'
import PdfPreview from '@/components/utils/pdf/PdfPreview'
import PdfDocument from '@/components/utils/pdf/PdfDocument'
import PdfZoom from '@/components/utils/pdf/PdfZoom'

function floor (value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.floor(value * multiplier) / multiplier
}

export default {
  name: 'PdfViewer',
  components: { PdfZoom, PdfDocument, PdfPreview, PdfData },
  props: {
    url: String
  },
  data: () => ({
    loading: true,
    scale: undefined,
    optimalScale: undefined,
    fit: undefined,
    currentPage: 1,
    pageCount: undefined,
  }),
  methods: {
    onDocumentRendered () {
      this.loading = false
    },
    onDocumentErrored (e) {},
    updateScale ({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2)
      if (isOptimal) this.optimalScale = roundedScale
      this.scale = roundedScale
    },
    updateFit (fit) {
      this.fit = fit
    },
    updatePageCount (pageCount) {
      this.pageCount = pageCount
    },
    updateCurrentPage (pageNumber) {
      this.currentPage = pageNumber
    }
  },
  watch: {
    url () {
      this.currentPage = undefined
      this.loading = true
    }
  }
}
</script>
