<template>
  <vl-overlay
    :offset="[0, -10]"
    :position="position"
    v-if="position"
    positioning="bottom-center"
  >
    <span
      class="ol-tooltip ol-tooltip-static"
      style="position: absolute; width: 400px"
      v-if="value"
      v-html="value"
    />
  </vl-overlay>
</template>

<script>
  import { LineString, Polygon } from 'ol/geom'
  import { mapMeasureMixin } from '@/mixins/map-measure-mixin'

  export default {
    name: 'MeasuredObject',
    props: ['feature'],
    mixins: [mapMeasureMixin],
    computed: {
      position() {
        switch (this.feature.geometry.type) {
          case 'LineString' :
            return this.geometry.getLastCoordinate()
          case 'Polygon' :
            let position = [...this.geometry.getInteriorPoint().getCoordinates()]
            position.pop()
            return position
        }
      },
      value() {
        switch (this.feature.geometry.type) {
          case 'LineString' :
            return this.formatLength(this.geometry, { projection: 'EPSG:4326' })
          case 'Polygon' :
            return this.formatArea(this.geometry, { projection: 'EPSG:4326' })
        }
      },
      geometry() {
        switch (this.feature.geometry.type) {
          case 'LineString' :
            return new LineString(this.feature.geometry.coordinates)
          case 'Polygon' :
            return new Polygon(this.feature.geometry.coordinates)
        }
      }
    }
  }
</script>
