<template>
  <div>
    <div class="my-1 px-3 py-1" style="background: #f5f2f0" v-for="prop in item.props">
      <v-row>
        <v-col>
          <v-text-field dense hide-details :label="$t('variable')" outlined v-model="prop.variable"/>
        </v-col>
        <v-col>
          <v-text-field dense hide-details :label="$t('name')"  outlined v-model="prop.name"/>
        </v-col>
      </v-row>
    </div>
    <v-card-actions>
      <v-btn @click="remove" outlined text>{{ $t('button.remove') }}</v-btn>
      <v-spacer/>
      <v-btn @click="add" outlined text>{{ $t('button.add') }}</v-btn>
    </v-card-actions>
  </div>
</template>

<script>

  import messages from '../../componet-locale/calculation-edit-dialog/messages'

  export default {
    name: 'CalculationEditForm',
    props: {
      item: {
        type: Object,
        default: () => ({
          props: []
        })
      }
    },
    i18n: { messages: messages },
    methods: {
      add () {
        this.item.props.push({
          name: '',
          variable: ''
        })
      },
      remove () {
        this.item.props.pop()
      }
    }
  }
</script>
