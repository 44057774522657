<template>
  <v-select
    dense
    outlined
    :readonly="readonlyMode"
    :label="value.field.name"
    :items="items"
    v-model="value.value"
  />
</template>

<script>
export default {
  name: 'ListFromDataTableField',
  props: {
    value: Object,
    readonlyMode: Boolean
  },
  data: () => ({
    items: []
  }),
  mounted () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let [tableId, header] = this.value.field.options.split(',')
      this.$axios
        .get('table/get-options', {
          params: { tableId, header }
        })
        .then(response => {
          this.items = response.data
          if (!this.items.includes(this.value.value)) {
            this.items.push(this.value.value)
          }
        })
    }
  }
}
</script>
