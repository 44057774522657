import Vue from 'vue'
import Router from 'vue-router'
import MailTemplates from '@/components/admin/mail-template/MailTemplates'
import Drive from '@/components/drive/Drive'
import IssueList from '@/components/issue/IssueList'
import IssueView from '@/components/issue/IssueView'
import PoiManagement from '@/components/layer-poi/PoiManagement'
import ModbusUnitList from '@/components/modbus/ModbusUnitList'
import ModbusUnitView from '@/components/modbus/ModbusUnitView'
import ProfileMenu from '@/components/profile/ProfileMenu'
import UserProfile from '@/components/profile/UserProfile'
import Project from '@/components/project/Project'
import ProjectView from '@/components/project/ProjectView'
import Frame from '@/components/PublicDataViewer'
import SplitScreenPage from '@/components/SplitScreenPage'
import AdminPage from './components/admin/AdminPage'
import ApplicationSettings from './components/admin/ApplicationSettings'
import BackupPage from './components/admin/BackupPage'
import UpdatePage from './components/admin/UpdatePage'
import UserTable from './components/admin/user-management/UserTable'
import CalculationList from './components/calc/CalculationList'
import CalculationView from './components/calc/CalculationView'
import CategoryView from './components/category/CategoryView'
import ChartList from './components/chart/ChartList'
import ChartView from './components/chart/ChartView'
import Dashboard from './components/dashboard/Dashboard'
import DocList from './components/doc/DocList'
import DocView from './components/doc/DocView'
import SharedFilePage from './components/drive/SharedFilePage'
import Home from './components/Home'
import HelpPage from './components/info/HelpPage'
import LoginForm from './components/LoginPage'
import LayerList from './components/map/LayerList'
import LayerView from './components/map/LayerView'
import MapList from './components/map/MapList'
import MapSandbox from './components/map/MapSandbox'
import MapView from './components/map/MapView'
import NewPassword from './components/NewPassword'
import NoRights from './components/NoRights'
import ReportList from './components/report/ReportList'
import ReportView from './components/report/ReportView'
import ResetPassword from './components/ResetPassword'
import TableList from './components/table/TableList'
import TableView from './components/table/TableView'
import UserRegistration from './components/UserRegistration'
import Wiki from './components/wiki/ArticleEditor'
import store from './store/index'
import UserActivity from '@/components/admin/UserActivity'
import IssueTemplateManagement from '@/components/admin/issue-template-management/IssueTemplateManagement'
import PoiTemplateManagement from '@/components/admin/poi-template-management/PoiTemplateManagement'
import LayerPoiTable from '@/components/layer-poi/LayerPoiTable'
import PoiStyles from '@/components/admin/poi-styles/PoiStyles.vue'

Vue.use(Router)

const routes = [
  {
    path: '/registration',
    component: UserRegistration,
    meta: { nonRequiresAuth: true }
  },
  {
    path: '/public-data/:id',
    component: Frame,
    props: true,
    meta: { publicPage: true }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    meta: { nonRequiresAuth: true }
  },
  {
    path: '/new-password',
    component: NewPassword,
    meta: { nonRequiresAuth: true },
    props: (route) => ({
      token: route.query.token
    })
  },
  { path: '/profile', component: ProfileMenu },
  { path: '/user-profile', component: UserProfile },
  {
    path: '/login',
    component: LoginForm,
    meta: { loginPage: true, nonRequiresAuth: true }
  },
  { path: '/', component: Home },
  { path: '/drive', component: Drive },
  { path: '/drive/:token', component: SharedFilePage },
  { path: '/no-rights', component: NoRights },
  { path: '/help', component: HelpPage },
  {
    path: '/split-screen',
    component: SplitScreenPage,
    props: route => ({
      dataId: Number(route.query.dataId),
      secondDataId: Number(route.query.secondDataId)
    })
  },
  { path: '/wiki/:id?', component: Wiki },
  {
    path: '/chats',
    component: Home,
    name: 'chats',
    props: (route) => ({ chatID: route.query.chat })
  },
  {
    path: '/admin',
    component: AdminPage,
    children: [
      { path: 'users', component: UserTable },
      { path: 'backup', component: BackupPage },
      { path: 'update', component: UpdatePage },
      { path: 'settings', component: ApplicationSettings },
      { path: 'issue-templates', component: IssueTemplateManagement },
      { path: 'poi-templates', component: PoiTemplateManagement },
      { path: 'mail-templates', component: MailTemplates },
      { path: 'user-activity', component: UserActivity },
      { path: 'lps', component: PoiStyles }
    ]
  },
  {
    path: '/project/:projectId',
    name: 'project-view',
    component: Project,
    children: [
      { path: 'home', component: ProjectView },
      { path: 'dashboard', component: Dashboard },
      {
        path: 'issue',
        name: 'issue-list',
        meta: { page: 'tasks' },
        component: IssueList,
        children: [
          {
            path: ':id',
            name: 'view-issue',
            props: true,
            component: IssueView
          }
        ]
      },
      {
        path: 'poi-management', name: 'poi-management', meta: { page: 'poi' }, component: PoiManagement
      },
      {
        path: 'poi-table',
        name: 'poi-table',
        props: true,
        component: LayerPoiTable
      },
      {
        path: 'report',
        name: 'report-list',
        meta: { page: 'reports' },
        component: ReportList,
        children: [
          {
            path: ':id',
            name: 'view-report',
            props: true,
            component: ReportView
          }
        ]
      },
      {
        path: 'chart',
        name: 'chart-list',
        meta: { page: 'charts' },
        component: ChartList,
        children: [
          {
            path: ':id',
            name: 'view-chart',
            props: true,
            component: ChartView
          }
        ]
      },
      {
        path: 'calc',
        name: 'calc-list',
        component: CalculationList,
        meta: { page: 'calculations' },
        children: [
          {
            path: ':id',
            name: 'view-calc',
            props: true,
            component: CalculationView
          }
        ]
      },
      {
        path: 'modbus-unit',
        name: 'modbus-unit-list',
        component: ModbusUnitList,
        meta: { page: 'modbus-units' },
        children: [
          {
            path: ':id',
            name: 'view-modbus-unit',
            props: true,
            component: ModbusUnitView
          }
        ]
      },
      {
        path: 'table',
        name: 'table-list',
        meta: { page: 'tables' },
        component: TableList,
        children: [
          {
            path: ':id',
            name: 'view-table',
            props: true,
            component: TableView
          }
        ]
      },
      {
        path: 'doc',
        name: 'doc-list',
        meta: { page: 'documents' },
        component: DocList,
        children: [
          {
            path: ':id',
            name: 'view-doc',
            props: true,
            component: DocView
          }
        ]
      },
      {
        path: 'map',
        name: 'map-list',
        meta: { page: 'maps' },
        component: MapList,
        children: [
          {
            path: ':id',
            name: 'map-view',
            props: true,
            component: MapView
          }
        ]
      },
      {
        path: 'layer/sandbox',
        name: 'map-sandbox',
        component: MapSandbox,
        props: (route) => ({
          layerIdList: route.query.layerIdList,
          featureSys: route.query.featureSys,
          baseLayer: route.query.baseLayer,
          lat: route.query.lat,
          lon: route.query.lon,
          featureId: route.query.featureId,
          zoom: route.query.z,
          url: route.query.url
        })
      },
      {
        path: 'layer',
        name: 'layer-list',
        meta: { page: 'layers' },
        component: LayerList,
        children: [
          {
            path: ':id',
            name: 'view-layer',
            component: LayerView,
            props: (route) => ({
              sysId: route.query.sysId,
              dataId: route.query.dataId
            })
          }
        ]
      },
      { path: 'category/:id', name: 'category-list', component: CategoryView }
    ]
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const nonRequiresAuth = to.meta.nonRequiresAuth
  const publicPage = to.meta.publicPage

  if (publicPage) {
    next()
    return
  }

  const isAuthenticated = !!localStorage.getItem('currentLoginUser')
  if (nonRequiresAuth) {
    if (!isAuthenticated) {
      next()
    } else {
      router.push('/')
    }
  } else {
    if (isAuthenticated) {
      if (!store.getters.gisCredentials) {
        store.dispatch('setGisCredentials')
      }
      if (to.matched[0].name === 'project-view') {
        syncSelectedProject(to)
      }
      next()
    } else {
      router.push('/login')
    }
  }
})

function syncSelectedProject (to) {
  const projectIdFromUrl = to.params.projectId
  const selectedProjectId = store.getters.selectedProject ? String(store.getters.selectedProject.id) : null
  if (projectIdFromUrl && projectIdFromUrl !== selectedProjectId) {
    store.dispatch('updateSelectedProject', projectIdFromUrl)
  }
}

export default router
