<template>
  <div class="search-bar">
    <v-text-field @input="emit"
                  @keydown.enter="emit"
                  width="750"
                  autofocus
                  :placeholder="$t('search')"
                  outlined
                  :dense="displayXS"
                  hide-details
                  class="mt-1"
                  ref="searchField"
                  v-model="searchQuery">
      <template v-slot:append>
        <v-icon @click="emit">
          search
        </v-icon>
      </template>
    </v-text-field>
    <v-radio-group @change="emit"
                   hide-details
                   v-model="fullTextSearch"
                   row>
      <v-radio :label="$t('searchByName')" :value="false"/>
      <v-radio :label="$t('searchByContent')" :value="true"/>
    </v-radio-group>
  </div>
</template>

<script>
  import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
  import messages from '../../componet-locale/search-bar/messages'

  export default {
    name: 'SearchBar',
    mixins: [displayServiceMixin],
    data: () => ({
      searchQuery: undefined,
      fullTextSearch: false
    }),
    i18n: { messages: messages },
    mounted () {
      this.$nextTick(() => this.$refs.searchField.$el.focus())
    },
    methods: {
      emit () {
        this.$emit('search', this.searchQuery, this.fullTextSearch)
      },
      clean () {
        this.searchQuery = undefined
        this.fullTextSearch = false
        this.emit()
      }
    }
  }
</script>

<style>

  .search-bar {
    width: 100%;
  }

</style>
