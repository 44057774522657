<template>
  <div>
    <v-card-title>
      <a @click="openInNewTab(data)"
         class="hide-text-overflow simple-link subtitle-1"
      >
        {{ data.name }}
      </a>
    </v-card-title>
    <data-table :table="table" widget/>
  </div>
</template>

<script>
import DataTable from '../utils/DataTable'
import { EventBus } from '@/event-bus'

export default {
  name: 'TableWidget',
  components: { DataTable },
  props: ['id'],
  data: () => ({
    table: {},
    data: {}
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios
        .get('data/get', { params: { id: this.id } })
        .then(response => {
          this.data = response.data
          this.loadTable()
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    },
    loadTable () {
      this.$axios
        .get('/table/get-table/' + this.id)
        .then(response => {
          this.table = JSON.parse(response.data.table)
          this.table.object = this.data
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    }
  }
}
</script>
