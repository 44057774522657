<template>
  <v-card elevation="0">
    <v-card-text class="pa-0">
      <message v-for="item in messageList"
               :key="item.createdAt"
               :message="item"/>
    </v-card-text>
    <v-card-actions>
      <v-textarea
        v-model="message"
        :placeholder="$t('message')"
        autocomplete="off"
        rows="2"
        no-resize
        dense
        outlined
        @keydown.enter.exact.prevent="sendMessage"
        @keydown.enter.alt.exact.prevent="message += '\n'"
      >
        <template v-slot:prepend-inner>
          <chat-attachments :chat="chat"/>
        </template>
        <template v-slot:append>
          <v-slide-x-reverse-transition hide-on-leave>
            <v-icon v-if="message">send</v-icon>
          </v-slide-x-reverse-transition>
        </template>
      </v-textarea>
    </v-card-actions>
  </v-card>
</template>

<script>
import ChatAttachments from '@/components/chats/ChatAttachments'
import Message from '@/components/chats/messages/Message'
import SockJs from 'sockjs-client'
import Stomp from 'webstomp-client'
import messages from '../../componet-locale/issue-comments/messages'

export default {
  name: 'Comments',
  components: { ChatAttachments, Message },
  props: {
    chatId: Number,
    default: 0
  },
  data: () => ({
    message: '',
    messageList: [],
    chat: {},
    stompClient: undefined,
    subscriptions: new Map()
  }),
  i18n: { messages: messages },
  created() {
    this.init()
  },
  destroyed() {
    this.stompClient.disconnect()
    this.stompClient = null
  },
  methods: {
    connect(id) {
      const socket = new SockJs(`${this.$axios.defaults.baseURL}/ws-endpoint`)
      this.stompClient = Stomp.over(socket, { debug: false })
      this.stompClient.connect({}, () => this.subscribe(id))
    },
    subscribe(id) {
      const subscription = this.stompClient.subscribe(
        `/ws-broker/topic/${id}`,
        it => this.addMessage(JSON.parse(it.body))
      )
      this.subscriptions.set(id, subscription) // add subscription object associated with chatID into map
    },
    sendMessage() {
      this.stompClient.send(`/ws-prefix/notify/${this.chatId}`, this.message)
      this.message = ''
    },
    writeHistory(val) {
      let template = `<div style="display:flex;"><div class="vertical"></div><span class="grey--text">{VALUE}</span></div>`
      let message = template.replace('{VALUE}', val)
      this.stompClient.send(`/ws-prefix/notify/${this.chatId}`, message)
    },
    addMessage(message) {
      let user = this.chat.members.find(it => message.authorID === it.id)
      if (user) {
        message.author = user
        this.messageList.push(message)
      } else {
        this.init()
      }
    },
    init() {
      this.connect(this.chatId)
      this.$axios
        .get('chat-records/get-messages', { params: { chatId: this.chatId } })
        .then(response => {
          this.messageList = response.data
          this.chat = response.data[0].chat
        })
    }
  },
  watch: {
    chatId() {
      this.stompClient.disconnect()
      this.stompClient = null
      this.init()
    }
  }
}
</script>
