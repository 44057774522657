import moment from 'moment'

const TODAY = 'HH:mm'
const THIS_WEEK = 'dd'
const THIS_YEAR = 'D.MM'
const FULL = 'D.MM.YY'

export function messageDateTimeInList(date) {
  moment.locale(localStorage.getItem('locale'))
  let messageDate = moment(date)
  let mask

  let today = moment().startOf('day')
  let startOfTheWeek = moment().startOf('week')
  let startOfTheYear = moment().startOf('year')

  if (messageDate.isBefore(startOfTheYear)) mask = FULL
  else if (messageDate.isBefore(startOfTheWeek)) mask = THIS_YEAR
  else if (messageDate.isBefore(today)) mask = THIS_WEEK
  else mask = TODAY

  return messageDate.format(mask)
}

export function messageDateTime(date) {
  const format = isMessageBeforeNow(date) ? 'DD.MM.YYYY HH:mm' : 'HH:mm'
  return moment(date).format(format)
}

function isMessageBeforeNow(date) {
  const createdAtStartOfDay = moment(date).startOf('day')
  const now = moment().startOf('day')

  return createdAtStartOfDay.isBefore(now)
}
