<template>
  <v-dialog v-model="dialog" width="700" scrollable>
    <v-card>
      <v-card-text>
        <v-text-field
          outlined
          label="Название шаблона"
          v-model="issueTemplate.name"
        />

        <v-text-field
          outlined
          label="Иконка"
          v-model="issueTemplate.icon"
        />

        <issue-template-field
          v-for="field in issueTemplate.fields"
          :value="field"
        />

        <v-card-title>
          <v-spacer/>
          <v-btn
            outlined
            @click="addField"
          >
            <v-icon left>add</v-icon>
            Добавить поле
          </v-btn>
        </v-card-title>

      </v-card-text>

      <v-card-actions>
        <v-btn
          outlined
          @click="dialog=false"
        >
          Отмена
        </v-btn>
        <v-spacer/>
        <v-btn
          outlined
          color="primary"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import IssueTemplateField from '@/components/admin/issue-template-management/IssueTemplateField'

const DEFAULT_FIELD = {
  name: '',
  type: 'STRING',
  required: false,
  options: ''
}

const DEFAULT_TEMPLATE = {
  name: '',
  fields: [],
}

export default {
  name: 'IssueTemplateDialog',
  components: { IssueTemplateField },
  data: () => ({
    dialog: false,
    issueTemplate: {}
  }),
  methods: {
    open (template = { ...DEFAULT_TEMPLATE }) {
      this.issueTemplate = template
      this.dialog = true
    },
    addField () {
      this.issueTemplate.fields.push({ ...DEFAULT_FIELD })
    },
    save () {
      this.$axios
        .post('/issue-template/save', this.issueTemplate)
        .then(() => {
          this.$emit('onSave')
        })
        .finally(() => {
          this.dialog = false
        })
    }
  }
}
</script>
