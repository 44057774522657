<template>
  <v-card
      v-click-outside="hideSearchField"
      dark
      v-if="chatItems.length"
      class="second-color quick_list"
      max-height="400"
  >
    <v-card-title style="height: 54px;"
                  class="pa-2 align-center justify-center">
      <v-text-field
          ref="search_text_field"
          v-show="searchFieldShown"
          autocomplete="off"
          dense
          flat
          placeholder="Поиск по чатам"
          hide-details
          prepend-inner-icon="search"
          v-model="search"
          solo-inverted
          clearable
          @keyup.esc="hideSearchField"
          color="grey darken-1"
      />
      <v-icon
          v-show="!searchFieldShown"
          color="white"
          size="32"
          v-text="'search'"
          @click="onSearchClick"
      />
    </v-card-title>
    <v-list class="content-list transparent">
      <div
          v-for="(item, index) in filteredChats"
          :key=index
      >
        <v-tooltip
            open-delay="500"
            left
            :disabled="searchFieldShown"
            color="grey darken-1"
        >
          <template v-slot:activator="{ on }">
            <v-list-item
                :ripple="false"
                v-on="on"
                class="list-item px-2"
                dark
                @click="onChatClick(item)"
            >
              <user-avatar
                  :user="item.user"
                  dense
                  v-if="item.type === 'DIRECT' && item.user"
              />
              <v-avatar
                  v-else
                  size="40"
                  :color="item.avatar.color"
              >
                <v-icon>
                  {{ item.avatar.icon }}
                </v-icon>
              </v-avatar>
              <v-list-item-content v-show="searchFieldShown">
                <v-list-item-title class="ml-2 body-2 font-weight-thin">
                  {{ item.chatName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>{{ item.chatName }}</span>
        </v-tooltip>
        <v-divider
            v-if="searchFieldShown"
            class="mx-2"
            light
        />
      </div>
    </v-list>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UserAvatar from '../utils/UserAvatar'
  import Message from './messages/Message'
  import { chatMixin } from './mixins/chat-mixin'
  import ClickOutside from 'vue-click-outside'

  export default {
    name: 'ChatQuickList',
    components: { Message, UserAvatar },
    mixins: [chatMixin],
    directives: { ClickOutside },
    data: () => ({
      searchFieldShown: false,
      search: ''
    }),
    methods: {
      hideSearchField() {
        this.searchFieldShown = false
        this.search = ''
      },
      getChatName(item) {
        if (item.type === 'DIRECT') {
          let user = item.members.find(it => it.id !== this.currentLoginUser.id)
          return this.getFullName(user)
        }
        return item.chat.name
      },
      mapChatItems(item) {
        return {
          chatName: this.getChatName(item),
          avatar: this.getAvatar(item.type),
          user: item.members.find(it => it.id !== this.currentLoginUser.id),
          chat: item.chat,
          lastMessageID: item.lastMessageID,
          lastMessageTime: item.lastMessageTime
        }
      },
      onChatClick(item) {
        this.hideSearchField()
        this.$emit('click', item)
      },
      onSearchClick() {
        this.searchFieldShown = true
        this.$nextTick(() => this.$refs.search_text_field.focus())
      }
    },
    computed: {
      ...mapGetters([
        'nonArchivedChats'
      ]),
      chatItems() {
        return this.nonArchivedChats.map(item => this.mapChatItems(item))
      },
      filteredChats() {
        return this.chatItems
          .filter(item => item.chatName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
      }
    }
  }
</script>

<style scoped>
  .quick_list {
    position: fixed;
    max-height: 500px;
    max-width: 300px;
    bottom: 0;
    right: 10px;
    z-index: 200;
  }

  .content-list {
    overflow: scroll;
    max-height: 342px;
    min-height: 342px;
  }

  .content-list::-webkit-scrollbar {
    display: none;
  }

  .list-item {
    cursor: pointer;
  }
</style>
