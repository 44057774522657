<template>
  <v-gauge :maxValue="field.minValue" :min-value="field.maxValue" :options="options" :value="value"/>
</template>

<script>
import VGauge from 'vgauge'

export default {
  props: ['value', 'field'],
  name: 'GaugeWidget',
  components: { VGauge },
  computed: {
    options() {
      return {
        angle: -0.24,
        lineWidth: 0.2,
        radiusScale: 1,
        pointer: {
          length: 0.6,
          strokeWidth: 0.049,
          color: '#000000'
        },
        limitMax: true,
        limitMin: false,
        colorStart: '#6FADCF',
        colorStop: '#8FC0DA',
        strokeColor: '#E0E0E0',
        generateGradient: true,
        highDpiSupport: true,
        staticZones: [
          { strokeStyle: '#F03E3E', min: this.field.minValue, max: this.field.minThresholdValue },
          { strokeStyle: '#30B32D', min: this.field.minThresholdValue, max: this.field.maxThresholdValue },
          { strokeStyle: '#F03E3E', min: this.field.maxThresholdValue, max: this.field.maxValue }
        ]
      }
    }
  }
}
</script>
