<template>
  <div>
    <v-dialog
      width="1000"
      persistent
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          История измнений
          <v-spacer/>
          <v-btn icon @click="dialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Автор
              </th>
              <th class="text-left">
                Тип изменений
              </th>
              <th class="text-left">
                Дата
              </th>
              <th class="text-left">
                Изменены поля
              </th>
              <th class="text-left">
                {{ ' ' }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in changelog" :key="item.id">
              <td>
                <user-avatar small :user="item.author"/>
                {{ getUserFullName(item.author) }}
              </td>
              <td>{{ item.changeType }}</td>
              <td>{{ formatDate(item.date) }}</td>
              <td>{{ getShortDescription(item.changeListByFields) }}</td>
              <td>
                <v-icon v-if="item.geometry">map</v-icon>
                <v-icon v-if="item.changeListByFields.length">description</v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar'

export default {
  name: 'LayerPoiChangelogDialog',
  components: { UserAvatar },
  data: () => ({
    dialog: false,
    changelog: null,
    lp: null
  }),
  methods: {
    async open (lp) {
      this.lp = lp
      if (lp == null) {
        console.error('error lp is null')
        return
      }
      this.dialog = true
      let { data } = await this.$axios.get('layer-poi/changelog/get', {
        params: { lpId: lp.id }
      })
      this.changelog = data
    },
    getShortDescription (changeListByFields) {
      return changeListByFields
        .map(it => `${it.fieldName}: ${it.value}`)
        .join('; ')
    }
  }
}
</script>
