<template>
  <v-card
    @click.native="show(item.name)"
    class="ma-2 search-result-row"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <span
          v-html="highlight(item.name)"
          class="subtitle-2"
        />
        <v-list-item-subtitle>
          <span v-html="highlight(item.template.name)"/>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <layer-poi-badge :template="item.template"/>
      </v-list-item-action>
    </v-list-item>
    <v-card-actions>
      <v-chip label outlined small>
        <v-icon left small>layers</v-icon>
        <span v-html="item.layerName "/>
      </v-chip>
      <v-spacer/>
      <span
        class="font-weight-thin caption"
        v-if="item.id"
      >
        #{{ item.id }}
      </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import LayerPoiBadge from '@/components/layer-poi/LayerPoiBadge'

export default {
  name: 'LayerPoiSearchResult',
  components: { LayerPoiBadge },
  props: {
    item: Object,
    searchField: String
  },
  methods: {
    show () {
      this.$axios
        .get('layer-poi/find-by-id', {
          params: { id: this.item.id }
        })
        .then(response => {
          let poi = response.data
          poi.dComponent = 'layer-poi-feature-card'
          this.$emit('show', poi)
        })
    },
    highlight (name) {
      if (!name) return this.item.template.name
      let re = new RegExp(this.searchField, 'ig')
      return this.searchField ? name.replace(re, `<em>$&</em>`) : name
    }
  }
}
</script>
