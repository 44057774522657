<template>
  <div>
    <!--    <div class="d-map-controls-si caption">-->
    <!--      <span class="">-->
    <!--        {{ value.center.join(',') }}-->
    <!--      </span>-->
    <!--    </div>-->
    <div
      v-if="!state.editMode && showAdditionalControls"
      class="d-map-controls d-map-controls-right d-inline-block pt-10"
    >
      <div class="d-map-controls-block d-inline-block">
        <div
          v-if="setAbility('MODERATOR')"
          @click="$emit('create')"
          class="d-map-controls-btn"
        >
          <v-icon :size="20">
            add_circle
          </v-icon>
        </div>
        <div
          @click="switchMeasurer('LineString')"
          class="d-map-controls-btn d-inline-block"
        >
          <v-icon
            :size="20"
            :color="isLinearMeasurer ? 'blue' : '' "
          >
            straighten
          </v-icon>
        </div>
        <div
          @click="switchMeasurer('Polygon')"
          class="d-map-controls-btn d-inline-block"
        >
          <v-icon
            :color="isPolygonMeasurer ? 'blue' : '' "
            :size="20">
            format_shapes
          </v-icon>
        </div>
        <div
          @click="$emit('export')"
          class="d-map-controls-btn d-inline-block"
        >
          <v-icon :size="20">
            print
          </v-icon>
        </div>

        <div
          @click="changeRenderMode"
          class="d-map-controls-btn"
        >
          <v-icon color="red" :size="20" v-if="isCanvas">
            airplanemode_inactive
          </v-icon>

          <v-icon :size="20" v-else>
            flight
          </v-icon>
        </div>
      </div>
    </div>
    <div class="d-map-controls d-map-controls-bottom d-map-controls-right">
      <div class="d-map-controls-block">
        <div
          @click="findUserLocation"
          class="d-map-controls-btn"
        >
          <v-progress-circular
            v-if="locationLoading"
            indeterminate
            color="grey"
            :size="20"
          />
          <v-icon :size="20" v-else>
            my_location
          </v-icon>
        </div>
      </div>

      <div
        class="d-map-controls-block"
      >
        <div
          @click="value.zoom++"
          class="d-map-controls-btn"
        >
          <v-icon
            :size="20"
          >
            add
          </v-icon>
        </div>
        <v-divider/>
        <div
          @click="value.zoom--"
          class="d-map-controls-btn"
        >
          <v-icon
            :size="20"
          >
            remove
          </v-icon>
        </div>
      </div>
    </div>
    <user-geoloc
      v-if="showUserLocation"
      :state="state"
      @onFindPosition="onFindPosition"
    />
  </div>
</template>

<script>
import UserGeoloc from '@/components/map/UserGeoloc'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'

export default {
  name: 'DMapControls',
  components: { UserGeoloc },
  mixins: [displayServiceMixin],
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: Object,
    state: Object,
    widget: Boolean
  },
  data: () => ({
    showUserLocation: false,
    isCanvas: true
  }),
  methods: {
    changeRenderMode () {
      this.value.renderer = this.value.renderer === 'webgl' ? 'canvas' : 'webgl'
      if (this.value.renderer === 'canvas') {
        EventBus.$emit('showInfoMessage', 'Режим WebGL отключен')
      } else {
        EventBus.$emit('showInfoMessage', 'Режим WebGL включен')
      }
      this.isCanvas = this.value.renderer === 'canvas'
    },
    findUserLocation () {
      if (this.state.userLocation) {
        this.value.center = this.state.userLocation
      } else {
        this.showUserLocation = true
      }
    },
    switchMeasurer (type) {
      if (!this.state.measureType || this.state.measureType !== type) {
        this.state.measureType = type
      } else {
        this.state.measureType = null
      }
    },
    onFindPosition (coordinate) {
      this.value.center = coordinate
    }
  },
  computed: {
    isPolygonMeasurer () {
      return this.state.measureType === 'Polygon'
    },
    isLinearMeasurer () {
      return this.state.measureType === 'LineString'
    },
    locationLoading () {
      return !this.state.userLocation && this.showUserLocation
    },
    showAdditionalControls () {
      return !(this.widget || this.displayXS)
    }
  }
}
</script>

<style>
.d-map-controls {
  position: absolute;
  z-index: 200;
}

.d-map-controls-bottom {
  bottom: 224px;
}

.d-map-controls-right {
  right: 20px;
}

.d-map-controls-block {
  align-content: center;
  width: 36px;
  margin-top: 5px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  background: white;
  border-radius: 8px;
}

.d-map-controls-btn {
  cursor: pointer;
  padding: 8px;
}

.d-map-controls-btn .v-icon:hover {
  color: black;
}

.d-map-controls-si {
  position: absolute;
  z-index: 200;
  bottom: 0;
  padding: 3px 5px;
  background: white;
}

</style>
