<template>
  <scrolling-document class="pdf-document doc-frame"
                      v-bind="{pages, pageCount, currentPage, scale}"
                      v-slot="{page, isPageFocused, isElementFocused, isPageVisible}"
                      :enable-page-jump="true"
                      @page-jump="onPageJump"
                      @pages-fetch="onPagesFetch"
                      @pages-reset="fitWidth">
    <pdf-page v-bind="{scale, page, isPageFocused, isElementFocused, isPageVisible}"
              @page-rendered="onPageRendered"
              @page-errored="onPageErrored"
              @page-focus="onPageFocused"/>
  </scrolling-document>
</template>

<script>
import { PIXEL_RATIO, VIEWPORT_RATIO } from '@/utils/constants'
import ScrollingDocument from '@/components/utils/pdf/ScrollingDocument'
import PdfPage from '@/components/utils/pdf/PdfPage'

export default {
  name: 'PdfDocument',
  components: { PdfPage, ScrollingDocument },
  props: {
    pages: {
      required: true
    },
    pageCount: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 1.0
    },
    optimalScale: {
      type: Number
    },
    fit: {
      type: String
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    defaultViewport () {
      if (!this.pages.length) return { width: 0, height: 0 }
      const [page] = this.pages
      const deviceScale = PIXEL_RATIO * VIEWPORT_RATIO

      return page.getViewport({scale: deviceScale})
    },
    isPortrait () {
      const { width, height } = this.defaultViewport
      return width <= height
    }
  },
  methods: {
    pageWidthScale () {
      const { defaultViewport, $el } = this
      if (!defaultViewport.width) return 0

      return $el.clientWidth / defaultViewport.width
    },
    pageHeightScale () {
      const { defaultViewport, $el } = this
      if (!defaultViewport.height) return 0

      return $el.clientHeight / defaultViewport.height
    },
    fitWidth () {
      const scale = this.pageWidthScale()
      this.updateScale(scale, { isOptimal: !this.optimalScale })
    },
    fitHeight () {
      const scale = this.isPortrait ? this.pageHeightScale() : this.pageWidthScale()
      this.updateScale(scale)
    },
    fitAuto () {
      const scale = Math.min(this.pageWidthScale(), this.pageHeightScale())
      this.updateScale(scale)
    },
    updateScale (scale, { isOptimal = false } = {}) {
      if (!scale) return
      this.$emit('scale-change', { scale, isOptimal })
    },
    onPageJump (scrollTop) {
      this.$el.scrollTop = scrollTop - 123
    },
    onPagesFetch (currentPage) {
      this.$parent.$emit('pages-fetch', currentPage)
    },
    onPageFocused (pageNumber) {
      this.$parent.$emit('page-focus', pageNumber)
    },
    onPageRendered (payload) {
      this.$parent.$emit('page-rendered', payload)
    },
    onPageErrored (payload) {
      this.$parent.$emit('page-errored', payload)
    }
  },
  watch: {
    fit (fit) {
      switch (fit) {
        case 'width':
          this.fitWidth()
          break

        case 'auto':
          this.fitAuto()
          break

        default:
          break
      }
    },
    pageCount: 'fitWidth'
  }
}
</script>
