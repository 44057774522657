<template>
  <div
    id="map-search-tuner"
    class="pa-2"
  >
    <v-select
      :items="layers"
      solo
      dense
      flat
      class="mt-2"
      hide-details
      clearable
      :disabled="value.onlyByAddress"
      item-text="name"
      label="Слой"
      return-object
      v-model="value.selectedLayer"
    />

    <v-select
      :items="value.selectedLayer.typeList"
      v-if="value.selectedLayer && value.selectedLayer.typeList && value.selectedLayer.typeList.length"
      solo
      dense
      flat
      class="mt-2"
      hide-details
      clearable
      :disabled="value.onlyByAddress"
      item-text="name"
      label="Тип"
      return-object
      v-model="value.selectedType"
    />

    <v-select
      :items="value.selectedType.modes"
      solo
      dense
      class="mt-2"
      flat
      hide-details
      v-if="value.selectedType && value.selectedType.modes && value.selectedType.modes.length"
      clearable
      :disabled="value.onlyByAddress"
      item-text="title"
      label="Режим"
      return-object
      v-model="value.selectedMode"
    />

    <v-checkbox
      v-if="value.selectedLayer && value.selectedLayer.type === 'ZWS'"
      :disabled="value.onlyByAddress"
      dense
      hide-details
      class="mx-2 mt-1"
      label="Искать по ID"
      v-model="value.onlyByID"
    />

    <v-checkbox
      v-if="value.selectedLayer && value.selectedLayer.type === 'LAYER_POI'"
      :disabled="value.onlyByAddress"
      dense
      hide-details
      class="mx-2 mt-1"
      label="Точное совпадение"
      v-model="value.exactMatch"
    />

    <v-checkbox
      dense
      hide-details
      class="mx-2 mt-1"
      label="Только по адресу"
      v-model="value.onlyByAddress"
    />

    <v-btn
      block
      class="mt-3"
      outlined
      @click="$emit('search')"
    >
      <v-icon left>search</v-icon>
      Поиск
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'MapSearchFilter',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: Object,
    layers: Array
  }
}
</script>

<style>
#map-search-tuner {
  background: #ededed;
  margin: 6px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
</style>
