<template>
  <v-card outlined class="my-2">

    <v-card-actions>
      <v-spacer/>
      <v-icon small class="handle pointer mr-1">drag_indicator</v-icon>
    </v-card-actions>

    <v-card-text>
      <v-text-field
        label="Название поля"
        v-model="value.name"
      />
      <v-select
        label="Тип данных"
        v-model="value.type"
        :items="dataTypeList"
      >
        <template v-slot:selection="{item}">
          {{ $t(item) }}
        </template>
        <template v-slot:item="{item}">
          {{ $t(item) }}
        </template>
      </v-select>
      <v-textarea
        label="Опции"
        auto-grow
        placeholder="Введите варианты для выпадающего списка (используйте запятую в качестве разделителя)"
        rows="1"
        v-if="value.type === 'LIST' || value.type === 'MULTIPLE_LIST'"
        v-model="value.options"
      />
      <v-text-field
        label="Префикс"
        auto-grow
        v-if="value.type === 'CUSTOM_LINK'"
        v-model="value.options"
      />
      <v-text-field
        label="Формула"
        auto-grow
        v-if="value.type === 'COMPUTED_FIELD'"
        v-model="value.options"
      />
      <v-text-field
        label="ID таблицы и название поля (через запятую)"
        v-if="value.type === 'DATA_TABLE'"
        v-model="value.options"
      />
      <v-checkbox
        dense
        hide-details
        label="Использовать как название"
        v-if="value.type === 'STRING'"
        v-model="value.partOfName"
      />
      <v-checkbox
        dense
        hide-details
        label="Отображать текст на карте"
        v-model="value.showOnMap"
      />
      <v-checkbox
        dense
        hide-details
        label="Исключить из таблицы"
        v-model="value.excludeFromTableView"
      />
      <v-card-actions>
        <v-spacer/>
        <v-btn
          outlined
          @click="remove"
          color="red"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import messages from '@/componet-locale/poi-template-field/messages'

export default {
  name: 'PoiTemplateField',
  i18n: { messages: messages },
  props: { value: Object },
  data: () => ({
    dataTypeList: [
      'STRING',
      'BOOLEAN',
      'DATE',
      'LIST',
      'MULTIPLE_LIST',
      'NUMBER',
      'LINK',
      'CUSTOM_LINK',
      'COMPUTED_FIELD',
      'DATA_TABLE'
    ]
  }),
  methods: {
    async getTableList () {
      let { data } = await this.$axios.get('data')
      return data
    },
    remove () {
      this.$emit('remove', this.value)
    }
  }
}
</script>
