<template>
  <v-container>
    <v-layout align-center justify-center wrap>
      <v-flex lg10 md12 sm12 xl8 xs12>
        <v-card class="elevation-0">
          <v-card-title>
            {{ $t('settings') }}
          </v-card-title>
          <v-card-subtitle v-if="user.isImportedFromAD">
            {{ $t('importedFromAD') }}
          </v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="user.firstName"
              :label="$t('name')"
              prepend-icon="person"
              :disabled="user.isImportedFromAD"
            />
            <v-text-field
              v-model="user.lastName"
              :label="$t('surname')"
              prepend-icon="person"
              :disabled="user.isImportedFromAD"
            />
            <v-text-field
              v-model="user.email"
              :label="$t('email')"
              prepend-icon="alternate_email"
              :disabled="user.isImportedFromAD"
            />
            <v-text-field
              v-model="settings.gisLogin"
              label="GIS пользователь"
              autocomplete="chrome-off"
              prepend-icon="location_on"
            />
            <v-text-field
              v-model="settings.gisPassword"
              autocomplete="new-password"
              label="GIS пароль"
              prepend-icon="location_on"
              :append-icon="showGisPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showGisPassword ? 'text' : 'password'"
              @click:append="showGisPassword = !showGisPassword"
            />
            <v-text-field
              v-model="user.phoneNumber"
              :label="$t('phone')"
              prepend-icon="local_phone"
              :disabled="user.isImportedFromAD"
            />

            <v-switch
                :label="$t('smsNotification')"
              v-model="user.smsNotificationsAllowed"
            />

            <v-switch
              :label="$t('emailNotification')"
              v-model="user.emailNotificationsAllowed"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn outlined @click="save">
              {{ $t('button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { EventBus } from '../../event-bus'
  import messages from '../../componet-locale/user-profile/messages'

  export default {
    name: 'UserProfile',
    data: () => ({
      user: {},
      settings: {},
      showGisPassword: false
    }),
    i18n: { messages: messages },
    mounted () {
      this.init()
    },
    methods: {
      async init () {
        const userResponse = await this.$axios.get('/users/get-user')
        this.user = userResponse.data
        const settingsResponse = await this.$axios.post('/users/get-settings')
        if (settingsResponse.data) this.settings = settingsResponse.data
        this.user = {...this.user} // force update
      },
      save () {
        this.user.settings = this.settings
        this.$axios
          .post('users/save', this.user)
          .then(() => {
            this.$store.dispatch('setGisCredentials')
            EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          })
          .catch(() => {
            this.init()
            EventBus.$emit('showErrorMessage', this.$t('error'))
          })
      }
    }
  }
</script>
