export const getInitialState = () => {
  return {
    activeChatID: null,
    archivedChatsShown: false,
    chats: [],
    filterByChatType: 'ALL',
    currentLoginUser: JSON.parse(localStorage.getItem('currentLoginUser')),
    currentLoginUserProjects: JSON.parse(localStorage.getItem('currentLoginUserProjects')),
    selectedProject: JSON.parse(localStorage.getItem('selectedProject')),
    establishedConnection: false,
    onlineUsers: [],
    activeModalChat: undefined,
    gisCredentials: undefined,
    collectiveData: { center: [0, 0], zoom: 6 },
    subscriptions: new Map()
  }
}
