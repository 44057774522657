<template>
  <vl-layer-tile>
    <vl-source-xyz
      :url="layer.url"
      crossOrigin="Anonymous"
      :projection="layer.projection ? layer.projection : 'EPSG:3857'"
    />
  </vl-layer-tile>
</template>

<script>
import { get as getProjection } from 'ol/proj'
import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'

proj4.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs')
register(proj4)

let proj3395 = getProjection('EPSG:3395')
proj3395.setExtent([-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244])

export default {
  name: 'XyzLayer',
  props: { layer: Object }
}
</script>
