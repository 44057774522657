<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title
        class="headline lighten-2 pb-0"
        primary-title>
        {{ $t('widget') }}
      </v-card-title>
      <v-card-text>
        <v-select
          :items="widgetKeyList"
          @change="find"
          :label="$t('widgetType')"
          v-model="widget.type"
        >
          <template v-slot:item="{item}">
            {{ $t(item) }}
          </template>
          <template v-slot:selection="{item}">
            {{ $t(item) }}
          </template>
        </v-select>

        <v-autocomplete
          :disabled="!widget.type || widget.type === 'SENSOR'"
          :items="items"
          @click:clear="search = ''"
          :search-input.sync="search"
          clearable
          error-messages=""
          :label="$t('source')"
          :no-data-text="$t('noMatch')"
          return-object
          item-text="name"
          v-model="widget.abstractData"
        />

      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="disableCondition" @click="save" color="primary" outlined>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { widgetTypes } from '@/services/widget-service'
import messages from '../../componet-locale/widget-details-dialog/messages'

const emptyWidget = {
  position: {},
  name: '',
  type: 'MAP',
}

export default {
  name: 'WidgetDetailsDialog',
  data: () => ({
    dialog: false,
    widget: {},
    items: [],
    search: ''
  }),
  i18n: { messages: messages },
  methods: {
    open(widget = null) {
      this.widget = widget || emptyWidget
      this.dialog = true
      this.find()
    },
    save() {
      let widget = widgetTypes.get(this.widget.type)
      this.widget.w = widget.minW
      this.widget.h = widget.minH
      this.widget.project = this.selectedProject
      this.$axios
        .post('/widget/save', this.widget)
        .then(() => {
          this.dialog = false
          this.$emit('onSave')
        })
    },
    find() {
      let searchVal
      if (this.search) {
        searchVal = this.search
      } else {
        searchVal = ''
      }
      this.$axios
        .get('/data/search-by-type', {
          params: {
            str: searchVal,
            projectId: this.selectedProject.id,
            dataType: this.widget.type
          }
        })
        .then(response => this.items = response.data)
    }
  },
  computed: {
    widgetKeyList() {
      return Array.from(widgetTypes.keys())
    },
    disableCondition() {
      if (this.widget.type === 'SENSOR') return false
      return !this.widget.abstractData
    }
  },
  watch: {
    search() {
      this.find()
    }
  }
}
</script>
