<template>
  <div>
    <v-select
      :readonly="!isAuthenticated"
      class="simple-input px-3 mb-2"
      append-icon=""
      hide-details
      dense
      v-if="feature.mode"
      v-model="feature.mode"
      return-object
      @change="updateState"
      item-text="title"
      :items="feature.modeList">
      <template v-slot:selection="{ item }">
        <img class="mr-2" width="18" :src="item.image"/>
        {{ item.title }}
      </template>

      <template v-slot:item="{ item }">
        <img class="mr-2" width="18" :src="item.image"/>
        {{ item.title }}
      </template>
    </v-select>

    <action-toolbar
      class="ma-3"
      :actions="actions"
      @createIssue="createIssue"
      @share="share"
      @remove="$refs.confirmationDialog.open(feature)"
    />
    <confirmation-dialog
      @confirm="remove"
      :title="`Вы действительно хотите удалить объект ${name}`"
      ref="confirmationDialog"
    />
    <share-data-dialog ref="shareDataDialog"/>
    <new-issue-dialog ref="newIssueDialog"/>
  </div>
</template>

<script>
import ActionToolbar from '@/components/utils/ActionToolbar'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import { EventBus } from '@/event-bus'
import { CRATE_ISSUE, EDIT, REMOVE, SHARE } from '@/components/map/helpers/map-actions'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import NewIssueDialog from '@/components/issue/NewIssueDialog'

export default {
  name: 'ZwsActionToolbar',
  components: { NewIssueDialog, ShareDataDialog, ConfirmationDialog, ActionToolbar },
  props: {
    feature: Object,
    name: String
  },
  data: () => ({
    actions: [
      CRATE_ISSUE,
      SHARE,
      EDIT,
      REMOVE
    ]
  }),
  methods: {
    share () {
      let url = window.location + `?layerId=${this.feature.layer.id}&sys=${this.sys}`
      this.$refs.shareDataDialog.share(url)
    },
    createIssue () {
      let poi = {
        name: this.name,
        sys: this.sys,
        layer: this.feature.layer
      }
      this.$refs.newIssueDialog.open(poi)
    },
    async remove () {
      await ZwsCommandBuilder.deleteFeature(this.feature, this.sys)
      EventBus.$emit('refreshMap')
      this.$emit('close')
    },
    async updateState () {
      await ZwsCommandBuilder.updateState(this.feature, this.sys)
      EventBus.$emit('refreshMap')
    }
  },
  computed: {
    sys () {
      let key = this.feature.props.find(prop => prop.isKey)
      if (key && key.value) {
        return key.value
      }
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    }
  }
}
</script>
