<template>
  <v-dialog v-model="dialog" fullscreen>
    <file-viewer :file-token="fileToken" @close="close" v-if="fileToken"/>
  </v-dialog>
</template>

<script>
import FileViewer from "./FileViewer";

export default {
  name: 'SharedFilePage',
  data: () => ({
    dialog: false,
    fileToken: ''
  }),
  components: { FileViewer },
  methods: {
    toDrive () {
      this.$router.push('/drive')
    },
    close () {
      this.dialog = false
      this.toDrive()
    }
  },
  watch: {
    '$route.params.token': {
      handler: function (value) {
        if (value) {
          this.fileToken = value
          this.dialog = true
        } else {
          this.toDrive()
        }
      },
      immediate: true
    }
  }
}
</script>
