<script>
import visible from '@/directives/visible'
import 'pdfjs-dist/web/pdf_viewer.css'

export default {
  name: 'PdfCanvas',
  directives: { visible },
  props: {
    page: {
      type: Object,
      required: true
    },
    viewport: {
      type: Object,
      required: true
    }
  },
  methods: {
    async renderPage () {
      try {
        this.cancelRendering()

        const { $el: canvas, viewport } = this
        const canvasContext = canvas.getContext('2d')

        Object.assign(canvas, viewport)
        this.renderTask = this.page.render({ canvasContext, viewport })

        await this.renderTask.promise
        this.successRendering()
      } catch (error) {
        this.failRendering(error)
      }
    },
    successRendering () {
      this.$emit('page-rendered', {
        page: this.page,
        text: `Rendered page ${this.page.pageNumber}`
      })
    },
    failRendering (response) {
      this.$emit('page-errored', {
        response,
        page: this.page,
        text: `Failed to render page ${this.page.pageNumber}`
      })
    },
    cancelRendering () {
      if (!this.renderTask) return
      this.renderTask.cancel()
      this.renderTask = null
    },
  },
  watch: {
    viewport () {
      this.renderPage()
    }
  },
  render (h) {
    return h('canvas', {
      class: ['d-block', 'box-shadow']
    })
  },
  beforeDestroy () {
    this.cancelRendering()
  },
  mounted () {
    this.renderPage()
  }
}
</script>
