<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title
        class="headline lighten-2 pb-0"
        primary-title>
        {{ $t('project') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="project.name"
          @keydown.enter="save"
          counter="50"
          :label="$t('name')"
          outlined/>
        <v-textarea
          v-model="project.description"
          counter="255"
          :label="$t('description')"
          outlined/>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!project.name"
               :loading="loading"
               @click="save"
               color="primary"
               outlined>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { EventBus } from '@/event-bus'
import { mapActions } from 'vuex'
import messages from '@/componet-locale/project-view-dialog/messages'

export default {
  name: 'ProjectViewDialog',
  components: { VueEditor },
  i18n: {
    messages: messages
  },
  data: () => ({
    dialog: false,
    loading: false,
    project: {
      name: ''
    }
  }),
  methods: {
    ...mapActions([
      'selectProject',
    ]),
    save () {
      this.loading = true
      let url = this.project.id ? '/project/update' : '/project/create'
      this.$axios
        .post(url, this.project)
        .then((response) => {
          this.loading = false
          this.updateUserPermissions()
          this.dialog = false
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.selectProject({
            project: response.data,
            path: 'home'
          })
          this.$emit('on-save')
        })
        .catch(() => {
          this.loading = false
          EventBus.$emit('showErrorMessage', this.$t('errorWritingData'))
        })
    },
    updateUserPermissions () {
      this.$store.dispatch('updateUserData')
    },
    open () {
      this.dialog = true
      this.project = { name: '' }
    },
    edit () {
      this.dialog = true
      this.project = { ...this.selectedProject }
    }
  }
}
</script>
