<template>
  <div>
    <component
      v-for="feature in features"
      :is="getComponent(feature)"
      :feature="feature"
      @edit="$emit('edit')"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import ZwsFeatureCard from '@/components/map/zws/ZwsFeatureCard'
import LayerPoiFeatureCard from '@/components/map/LayerPoiFeatureCard'
import GeoServerFeatureCard from '@/components/map/geoserver/GeoServerFeatureCard'

export default {
  name: 'SelectedFeatureCard',
  components: { LayerPoiFeatureCard, ZwsFeatureCard, GeoServerFeatureCard },
  props: {
    features: Array
  },
  methods: {
    getComponent (feature) {
      if (feature.dComponent) {
        return feature.dComponent
      }
    }
  }
}
</script>
