<template>
  <div class="px-2">
    <v-text-field
      v-model="value.searchField"
      :loading="loading"
      @keyup.enter="$emit('search')"
      @click:append="$emit('tune')"
      @click:clear="$emit('clear')"
      :solo="solo"
      :flat="!solo"
      @focusin="solo=true"
      :solo-inverted="!solo"
      @focusout="solo=false"
      append-icon="tune"
      placeholder="Поиск"
      dense
      clearable
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: "MapSearchField",
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    value: Object,
    loading: Boolean
  },
  data: () => ({
    solo: false
  })
}
</script>
