<template>
  <v-dialog
      v-model="dialog"
      hide-overlay
      max-width="700"
  >
    <v-card>
      <v-card-title class="flex-nowrap justify-space-between">
        <h3 class="text-truncate">
          {{ title }}
        </h3>
        <v-icon
            @click="dialog=false"
            v-text="'close'"
        />
      </v-card-title>

      <v-card-text>
        {{ body }}
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="blue"
            outlined
            @click="dialog=false"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
            color="red"
            outlined
            @click="confirm"
        >
          {{ $t('button.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ChatConfirmationDialog',
  data: () => ({
    title: '',
    body: '',
    user: {},
    chatName: '',
    dialog: false
  }),
  methods: {
    confirm() {
      this.$emit('confirm', this.user)
      this.dialog = false
    },
    open(title, body) {
      this.title = title
      this.body = body
      this.dialog = true
    }
  }
}
</script>
