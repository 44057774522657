<template>
  <v-card
    outlined
    :style="getStyle(color)"
    class="ma-3"
  >
    <v-card-text>
      <v-select
        outlined
        dense
        label="Слой"
        return-object
        item-text="name"
        :items="layers"
        v-model="data.layer"
      />
      <v-select
        outlined
        dense
        no-data-text="Нет доступных шаблонов"
        label="Шаблон"
        v-if="data.layer"
        return-object
        item-text="name"
        :items="templateList"
        v-model="data.template"
      />
      <v-select
        outlined
        v-for="item in properties"
        dense
        clearable
        return-object
        v-if="data.template && data.features.length && data.fieldsMapping"
        :label="item"
        item-text="name"
        :items="data.template.fields"
        v-model="data.fieldsMapping[item]"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "GeoJsonFieldMapping",
    props: {
      data: Object,
      layers: Array,
      graphType: String,
      color: String,
    },
    data: () => ({
      properties: [],
    }),
    methods: {
      getStyle(color) {
        return `border: 4px ${color} solid;`
      }
    },
    mounted() {
      if (this.data && this.data.features[0].properties) {
        this.properties = Object.keys(this.data.features[0].properties)
        this.data.fieldsMapping = {}
      }
    },
    computed: {
      templateList() {
        if (!this.data.layer.poiTemplates.length) return

        return this.data.layer.poiTemplates
          .filter(it => it.graphType === this.data.graphType)
      }
    },
    watch: {
      'data.layer'() {
        this.data.template = null
        this.data.nameField = null
        this.data.descriptionField = null
      },
      'data.template'() {
        this.data.nameField = null
        this.data.descriptionField = null
      }
    }
  }
</script>
