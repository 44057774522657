<template>
  <div>
    <vl-source-cluster v-if="lpStyleList" :distance="200">
      <vl-layer-vector
        :declutter="true"
        render-mode="image"
      >
        <vl-source-vector :url="getUrl()">
          <vl-style-func :factory="getStyle"/>
        </vl-source-vector>
      </vl-layer-vector>
    </vl-source-cluster>
  </div>
</template>

<script>

import { createFillStyle, createStyle } from 'vuelayers/lib/ol-ext'
import { Fill, Stroke, Text } from 'ol/style'
import { EventBus } from '@/event-bus'

export default {
  name: 'ObjectLayer',
  props: { layer: Object },
  data: () => ({
    lpStyleList: null
  }),
  created () {
    this.loadStyles()
  },
  methods: {
    getStyle () {
      let getText = this.getText
      let map = this.getStylesById()
      return function (feature) {
        let props = feature.values_
        let style = map.get(props['style-id'])
        let text = props['map-text']
        if (text && text.length) {
          style = style.clone()
          style.setText(getText(text))
        }
        return style
      }
    },
    loadStyles () {
      this.$axios
        .get('layer-poi-style')
        .then(response => {
          this.lpStyleList = response.data
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Произошла ошибка при попытке получить данные по стилям'))
    },
    getText (text) {
      return new Text({
        font: '16px sans-serif',
        text: text,
        fill: new Fill({
          color: 'black'
        }),
        stroke: new Stroke({ color: 'white', width: 3 }),
        padding: [2, 2, 2, 2]
      })
    },
    getOlStyle (style) {
      const hexToRgb = (hex) => {
        let r = parseInt(hex.slice(1, 3), 16)
        let g = parseInt(hex.slice(3, 5), 16)
        let b = parseInt(hex.slice(5, 7), 16)
        let alpha = parseInt(hex.slice(7, 9), 16) / 255
        return [r, g, b, alpha]
      }
      return createStyle({
        strokeColor: hexToRgb(style.stroke),
        strokeWidth: style.strokeWidth,
        imageRadius: 4,
        imageSrc: style.icon,
        imageScale: style.imageScale,
        fill: createFillStyle({
          fillColor: hexToRgb(style.fill)
        })
      })
    },
    getStylesById () {
      let stylesById = new Map()
      this.lpStyleList.forEach(it => {
        stylesById.set(it.id, this.getOlStyle(it))
      })
      return stylesById
    },
    getUrl () {
      return `${window.location.origin}/api/layer-poi/min-geo-json?layerId=${this.layer.id}`
    }
  }
}
</script>
