import axios from 'axios'

export default function downloadFile (url, fileName) {
  return axios
    .get(url, { responseType: 'arraybuffer', timeout: 2400000 })
    .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const originalExtension = getOriginalExtension(response.headers)
        link.download = createFinalFileName(fileName, originalExtension)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    )
}

function getOriginalExtension (headers) {
  const contentDisposition = headers['content-disposition']
  if (contentDisposition) {
    return contentDisposition
      .replace(/"/g, '')
      .split('.')
      .pop()
  } else return null
}

function createFinalFileName (fileName, originalExtension) {
  if (fileName.endsWith(originalExtension) || !originalExtension) return fileName
  else return fileName + '.' + originalExtension
}
