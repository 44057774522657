<template>
  <v-list
      class="chat-list"
      dense
  >
    <div
        v-for="item in list"
        v-show="item.visible"
        :key="item.id"
    >
      <v-list-item @click="onItemClick(item.id)">
        <v-list-item-avatar class="grey darken-1">
          {{ item.avatarLabel }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider light/>
    </div>
  </v-list>
</template>

<script>
import { EventBus } from '@/event-bus'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FilterChatList',
  props: ['value'],
  data: () => ({
    list: []
  }),
  methods: {
    ...mapActions(['setActiveChatId']),
    avatar(chat) {
      if (chat.type === 'DIRECT')
        return chat.name
            .split(' ')
            .map(i => i[0])
            .join('')
      else
        return chat.name.substr(0, 1)
    },
    onItemClick(id) {
      this.$emit('update:value', null)
      this.setActiveChatId(id)
      EventBus.$emit('show-chats', id)
    }
  },
  created() {
    this.list = this.chats
        .map(chat => {
          return {
            id: chat.id,
            name: chat.name,
            avatarLabel: this.avatar(chat),
            visible: false
          }
        })
  },
  computed: {
    ...mapGetters(['chats'])
  },
  watch: {
    value(newVal) {
      this.list.forEach(chat =>
          chat.visible = newVal ? chat.name.toLowerCase().indexOf(newVal.toLowerCase()) > -1 : false
      )
    }
  }
}
</script>

<style scoped>
.chat-list {
  height: calc(100vh - 55px);
  overflow: auto;
}
</style>
