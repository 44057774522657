<template>
  <v-dialog
    persistent
    v-model="dialog"
    width="700"
  >
    <v-card>
      <v-card-title>
        Стиль объекта
        <v-spacer/>
        <v-btn @click="dialog=false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          outlined
          label="Название"
          v-model="item.name"
        />

        <v-menu
          :close-on-content-click="false"
        >
          <template v-slot:activator="{on}">
            <v-text-field
              outlined
              v-on="on"
              readonly
              label="Контур"
              v-model="item.stroke"
            >
              <template v-slot:append>
                <div
                  :style="`background: ${item.stroke}`"
                  class="lp-color-badge"
                />
              </template>
            </v-text-field>
          </template>
          <v-card>
            <v-color-picker
              v-model="item.stroke"
              flat
              dot-size="25"
              swatches-max-height="200"
              mode="hexa"
            />
          </v-card>
        </v-menu>

        <v-menu
          :close-on-content-click="false"
        >
          <template v-slot:activator="{on}">
            <v-text-field
              outlined
              v-on="on"
              readonly
              label="Заливка"
              v-model="item.fill"
            >
              <template v-slot:append>
                <div
                  :style="`background: ${item.fill}`"
                  class="lp-color-badge"
                />
              </template>
            </v-text-field>
          </template>
          <v-card>
            <v-color-picker
              v-model="item.fill"
              flat
              dot-size="25"
              swatches-max-height="200"
              mode="hexa"
            />
          </v-card>
        </v-menu>

        <v-text-field
          outlined
          type="number"
          v-model="item.strokeWidth"
          label="Толщина"
          max="8"
          min="0"
        />

        <v-text-field
          outlined
          label="Иконка"
          v-model="item.icon"
        />
        <v-text-field
          outlined
          label="Масштаб иконки (от 0.1 до 1)"
          v-model="item.imageScale"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :if="item.id"
          color="red"
          outlined
          @click="remove"
        >
          <v-icon v-if="this.item.id">delete</v-icon>
          {{ this.item.id ? "Удлалить" : "Отменить" }}
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" outlined @click="save">
          <v-icon>save</v-icon>
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
  name: 'PoiStyleDialog',
  data: () => ({
    dialog: false,
    item: {}
  }),
  methods: {
    open (item = { fill: '#FF000030', stroke: '#FF0000FF' }) {
      this.item = { ...item }
      this.dialog = true
    },
    remove () {
      if (this.item.id) {
        this.$axios
          .delete('layer-poi-style', { params: { id: this.item.id } })
          .then(() => EventBus.$emit('showInfoMessage', 'Стиль удален'))
          .catch(() => EventBus.$emit('showErrorMessage', 'При попытке удаления стиля произошла ошибка'))
          .finally(() => {
            this.dialog = false
            this.$emit('update')
          }
          )
      } else {
        this.dialog = false
      }
    },
    save () {
      this.$axios
        .post('layer-poi-style', this.item)
        .then(() => EventBus.$emit('showSuccessMessage', 'Сохранено'))
        .catch(() => EventBus.$emit('showErrorMessage', 'При попытке сохранения стиля произошла ошибка'))
        .finally(() => {
          this.dialog = false
          this.$emit('update')
        }
        )
    }
  }
}
</script>

<style scoped>
.lp-color-badge {
  width: 24px;
  height: 24px;
  border-radius: 5px;
}
</style>