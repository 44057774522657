var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.layer)?_c('div',[_c('v-card-actions',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-spacer'),_c('action-toolbar',{attrs:{"actions":_vm.actions,"bottom":true},on:{"download":_vm.exportCsv,"removeAll":function($event){return _vm.$refs.confirmationDialog.open(_vm.layer)}}})],1),_c('v-card-actions',[_c('span',{staticStyle:{"width":"180px"}},[_c('v-select',{staticClass:"group-input-left",attrs:{"dense":"","label":"Фильтр","hide-details":"","outlined":"","item-text":"name","items":_vm.filters,"return-object":"","prepend-icon":"mdi-filter"},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}})],1),_c('span',{staticStyle:{"width":"180px"}},[(_vm.filterBy.type === 'LIST' || _vm.filterBy.type === 'BOOLEAN')?_c('v-select',{staticClass:"group-input-right",attrs:{"outlined":"","label":"Значение","dense":"","clearable":"","hide-details":"","items":_vm.getOptions()},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.filterBy.type === 'BOOLEAN')?_c('span',[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]):_c('span',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.filterBy.type === 'BOOLEAN')?_c('span',[_vm._v(" "+_vm._s(_vm.$t(item))+" ")]):_c('span',[_vm._v(_vm._s(item))])]}}],null,false,1105956572),model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}}):_c('v-text-field',{staticClass:"group-input-right",attrs:{"label":"Значение","dense":"","clearable":"","hide-details":"","outlined":""},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}})],1),_c('v-spacer'),(_vm.layer.poiTemplates.length > 1)?_c('v-select',{attrs:{"outlined":"","label":"Тип объектов","return-object":"","hide-details":"","dense":"","items":_vm.layer.poiTemplates,"item-text":"name"},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers.filter(function (f) { return !f.excludeFromTableView; }),"server-items-length":_vm.totalElements,"options":_vm.options,"items":_vm.items,"locale":"ru"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',{on:{"click":function($event){return _vm.openDialog(item)}}},_vm._l((headers),function(header,index){return _c('td',{key:index},[(header.type === 'LINK')?[_c('a',{attrs:{"target":"_blank","href":item[header.value]}},[_vm._v(" "+_vm._s(item[header.value])+" ")])]:(header.type === 'NUMBER' ||  header.type === 'COMPUTED_FIELD')?[_vm._v(" "+_vm._s(item[header.value] ? item[header.value].toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') : '')+" ")]:(header.type === 'CUSTOM_LINK')?[_c('a',{attrs:{"target":"_blank","href":item[header.options] + item[header.value]}},[_vm._v(" "+_vm._s(item[header.value])+" ")])]:(header.type === 'BOOLEAN')?[_vm._v(" "+_vm._s(item[header.value] ? _vm.$t('true') : _vm.$t('false'))+" ")]:[_vm._v(" "+_vm._s(item[header.value])+" ")]],2)}),0)]}}],null,false,1459260321)})],1):_vm._e(),_c('confirmation-dialog',{ref:"confirmationDialog",attrs:{"title":"<span class='red--text font-weight-bold'>Внимание, это действие необратимо!</span><br/>Вместе с этой операцией история изменений по объектам также будет удалена.<br/> Вы действительно хотите удалить все данные типа: "},on:{"confirm":_vm.removeAll}}),_c('layer-poi-dialog',{ref:"layerPoiDlg",attrs:{"map-widget":"","actions-toolbar":""},on:{"update":_vm.loadPoiList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }