<template>
  <v-dialog v-if="dialog" v-model="dialog" width="900">
    <v-card>
      <v-card-text class="pa-0">
        <v-row>
          <v-col lg="4">
            <v-card-text>
              <abstract-data-edit-form :item="item"/>
            </v-card-text>
          </v-col>
          <v-col md="8" style="height: calc(100vh - 200px); overflow: auto;">
            <v-card-text>
              <div class="prism-editor-wrapper my-editor">
                <prism-editor auto-style-line-numbers language="js" line-numbers v-model="item.script"/>
              </div>
              <calculation-edit-form :item="item"/>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined text>{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn @click="save" color="primary" outlined>{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AbstractDataEditForm from '../utils/AbstractDataEditForm'
  import CalculationEditForm from './CalculationEditForm'
  import { EventBus } from '@/event-bus'
  import PrismEditor from 'vue-prism-editor'
  import 'vue-prism-editor/dist/VuePrismEditor.css'
  import 'prismjs/themes/prism.css'
  import 'prismjs'

  export default {
    name: 'CalculationEditDialog',
    components: { CalculationEditForm, AbstractDataEditForm, PrismEditor },
    data: () => ({
      dialog: false,
      item: {
        script: 'function main() {\n' +
          '  //TODO write code here\n' +
          '}',
        props: []
      }
    }),
    methods: {
      open () {
        this.dialog = true
        this.item = {
          script: 'function main() {\n' +
            '  //TODO write code here\n' +
            '}',
          props: []
        }
      },
      save () {
        let item = { ...this.item }
        item.project = this.selectedProject
        item.props = JSON.stringify(item.props)
        this.$axios
          .post('calc/save', item)
          .then(() => {
            this.dialog = false
            this.$emit('on-save')
          })
          .catch(() => {
            EventBus.$emit('showErrorMessage', this.$t('error'))
          })
      }
    }
  }
</script>
