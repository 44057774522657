export const chatMixin = {
  data: () => ({
    avatarList: [
      {
        key: 'PUBLIC',
        icon: 'supervisor_account',
        color: 'indigo darken-2'
      },
      {
        key: 'PRIVATE',
        icon: 'lock',
        color: 'grey darken-2'
      },
      {
        key: 'DIRECT',
        icon: 'account_circle',
        color: 'blue'
      },
      {
        key: 'ISSUE',
        icon: 'bug_report',
        color: 'purple darken-1'
      },
      {
        key: 'DATA',
        icon: 'description',
        color: 'blue'
      }
    ]
  }),
  methods: {
    getNameInitials({ firstName, lastName }) {
      return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`
    },
    getAvatar(value) {
      return this.avatarList.find(it => it.key === value)
    }
  }
}
