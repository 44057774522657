<template>
  <v-hover v-slot:default="{ hover }">
    <v-card class="my-1" outlined
            :elevation="(hover && isActive) ? 4 : 0">
      <v-card-title>
        <span class="title">{{ value.project.name }}</span>
      </v-card-title>
      <v-card-text class="pt-0" v-if="isActive">
        <div>
          <v-chip class="ma-1"
                  color="primary" label
                  outlined
                  v-for="category in value.categoryList.filter(it => !it.isDefault)">
            {{ category.name }}
          </v-chip>
        </div>
        <v-switch disabled
                  :label="$t('moderator')"
                  v-model="value.isModerator"/>
      </v-card-text>
      <v-card-actions v-else>
        <v-spacer/>
        <v-btn color="red" outlined
               @click="deleteUserProject">
          {{ $t('button.remove') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>

  import messages from '../../../componet-locale/user-project-card/messages'

  export default {
    name: 'UserProjectCard',
    props: ['value', 'isActive'],
    i18n: { messages: messages },
    methods: {
      deleteUserProject () {
        this.$emit('on-remove', this.value.id)
      }
    }
  }
</script>
