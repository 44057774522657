<template>
  <v-container>
    <div class="pa-10">
      <v-btn :loading="loading"
             class="mb-4"
             :disabled="!lastBuildNumber"
             v-if="updateType === 'LOCAL'"
             @click="updateAll">
        {{ $t('installLastVersion') }}
      </v-btn>
      <p v-else> {{ $t('updateDisabled') }} </p>
      <p v-bind:class="buildNumber === lastBuildNumber ? 'green--text' : 'red--text'">
        {{ $t('currentVersion') }}: {{ buildNumber }}
      </p>
      <p v-if="lastBuildNumber">
        {{ $t('availableVersion') }}: {{ lastBuildNumber }}
      </p>
      <p v-else>
        {{ $t('updateServerUnavailable') }}
      </p>
      <v-btn :loading="loading"
             class="mt-6"
             @click="reindex">
        {{ $t('rebuildIndex') }}
      </v-btn>
      <br/>
      <v-btn :loading="loading"
             class="mt-6"
             @click="removeFiles">
        {{ $t('removeUnusedFiles') }}
      </v-btn>
      <br>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/update-page/messages'

export default {
  name: 'UpdatePage',
  data: () => ({
    loading: true,
    buildNumber: '...',
    lastBuildNumber: undefined,
    updateType: undefined
  }),
  i18n: { messages: messages },
  methods: {
    updateAll() {
      EventBus.$emit('showSuccessMessage', this.$t('updatePlanned'))
      this.loading = true
      this.$axios.post('/update/update-all') // it reloads model and gui
    },
    getUpdateType () {
      this.$axios
        .get('/update/get-update-type')
        .then(response => this.updateType = response.data)
    },
    reindex() {
      EventBus.$emit('showInfoMessage', this.$t('indexRebuildPlanned'))
      this.loading = true
      this.$axios
          .post('/full-text-search/reindex', null, { timeout: 600000 })
          .then(() => EventBus.$emit('showSuccessMessage', this.$t('indexRebuildPlanned')))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('indexRebuildFailed')))
          .finally(() => this.loading = false)
    },
    setDefaultCategory() {
      EventBus.$emit('showSuccessMessage', this.$t('defaultCategoryPlanned'))
      this.loading = true
      this.$axios
          .post('/update/set-default-category')
          .then(() => EventBus.$emit('showSuccessMessage', this.$t('defaultCategoryDone')))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('defaultCategoryFailed')))
          .finally(() => this.loading = false)
    },
    encryptMessages() {
      this.loading = true
      this.$axios
          .post('/update/encrypt-messages')
          .then(() => EventBus.$emit('showSuccessMessage', this.$t('encryptionDone')))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('encryptionFailed')))
          .finally(() => this.loading = false)
    },
    addTokens() {
      EventBus.$emit('showSuccessMessage', this.$t('addTokenPlanned'))
      this.loading = true
      this.$axios
          .post('/update/add-tokens')
          .then(() => EventBus.$emit('showSuccessMessage', this.$t('tokenAdded')))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('tokenNotAdded')))
          .finally(() => this.loading = false)
    },
    removeFiles() {
      EventBus.$emit('showInfoMessage', this.$t('deletionUnusedFilePlanned'))
      this.loading = true
      this.$axios
          .post('/update/delete-unused-files', null, { timeout: 600000 })
          .then(() => EventBus.$emit('showSuccessMessage', this.$t('fileRemoved')))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('fileNotRemoved')))
          .finally(() => this.loading = false)
    },
    removeMailTemplates() {
      this.loading = true
      this.$axios
          .post('/update/remove-mail-templates', null, { timeout: 600000 })
          .then(() => EventBus.$emit('showSuccessMessage', this.$t('fileRemoved')))
          .catch(() => EventBus.$emit('showErrorMessage', this.$t('fileNotRemoved')))
          .finally(() => this.loading = false)
    },
    getBuildNumber() {
      this.$axios
          .get('/update/get-build-number')
          .then(result => {
            this.buildNumber = result.data
            // TODO move to special separate backend status check
            this.loading = false})
          .catch(() => EventBus.$emit('showInfoMessage', this.$t('updateNotAvailable')))
    },
    getLastBuildNumber() {
      this.$axios
          .get('/update/get-last-build-number')
          .then(result => this.lastBuildNumber = result.data)
    }
  },
  created() {
    this.getBuildNumber()
    this.getLastBuildNumber()
    this.getUpdateType()
  }
}
</script>
