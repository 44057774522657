<template>
  <v-dialog
      v-model="dialog"
      :fullscreen="displayXS"
      max-width="600"
  >
    <v-card>
      <v-card-title>
        <h5>
          <span class="grey--text text--darken-1">{{ $t('title') }}</span>
          {{ title }}
        </h5>
        <v-spacer/>
        <v-btn
            icon
            rounded
            @click="dialog=false"
        >
          <v-icon v-text="'close'"/>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-2">
        <v-text-field
            v-model.trim="$v.name.$model"
            :placeholder="$t('placeholder')"
            dense
            outlined
        />
      </v-card-text>

      <v-card-actions class="px-6 pb-4">
        <v-btn
            elevation="0"
            outlined
            @click="dialog=false"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
            color="primary"
            elevation="0"
            outlined
            :disabled="savingDisabled"
            @click="save"
        >
          {{ $t('button.rename') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import messages from '@/componet-locale/chat-renaming-dialog/messages'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { validationMixin } from 'vuelidate'
import { required, sameAs, not } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatRenamingDialog',
  mixins: [displayServiceMixin, validationMixin],
  i18n: { messages },
  data: () => ({
    savingDisabled: false,
    dialog: false,
    title: null,
    name: null
  }),
  methods: {
    open() {
      this.dialog = true
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.savingDisabled = true
      this.$axios
          .put('/chats/rename', null, {
            params: {
              chatID: this.activeChat.id,
              name: this.name
            }
          })
          .then(() => {
            this.dialog = false
            this.name = null
          })
          .finally(() => this.savingDisabled = false)
    }
  },
  computed: {
    ...mapGetters(['activeChat'])
  },
  watch: {
    dialog(newVal) {
      if (newVal) this.title = this.activeChat.name
      else this.name = null
    }
  },
  validations: {
    name: {
      required,
      notSameAs:
          not(
              sameAs(
                  function() {
                    return this.activeChat.name
                  }
              )
          )
    }
  }
}
</script>
