<template>
  <div>
    <chat-modal-view
        :chat="chat"
        v-if="isModalViewVisible"
        @close="modalWindow=false"
    />

    <v-fade-transition>
      <chat-quick-list
          v-if="isQuickListVisible"
          @click="onChatClick"
      />
    </v-fade-transition>
  </div>
</template>

<script>
  import ChatModalView from './ChatModalView'
  import ChatQuickList from './ChatQuickList'
  import { globalMixin } from '../../mixins/global-mixin'
  import { chatMixin } from './mixins/chat-mixin'
  import { displayServiceMixin } from '../../mixins/dispay-service-mixin'

  export default {
    name: 'ChatUtils',
    components: { ChatQuickList, ChatModalView },
    mixins: [globalMixin, chatMixin, displayServiceMixin],
    data: () => ({
      chat: null,
      modalWindow: false
    }),
    methods: {
      onChatClick(item) {
        this.chat = item
        this.modalWindow = true
        this.$store.dispatch('setActiveModalChat', this.chat.chat.id)
      }
    },
    computed: {
      isModalViewVisible() {
        let show = this.isAuthenticated
        if (show && RegExp(`^/chats`).test(this.$route.path)) return false
        if (show && this.displayXS) return false
        return !!(show && this.modalWindow)
      },
      isQuickListVisible() {
        let show = this.isAuthenticated
        if (show && RegExp(`^/chats`).test(this.$route.path)) return false
        if (show && this.displayXS) return false
        return show
      }
    }
  }
</script>