<template>
  <view-data-page
    :data="data"
    v-if="data"
    :selectedView="selectedView"
    :tab-list="tabList"
    :split-screen-mode="splitScreenMode"
    @selectView="selectView"
  >

    <template
      v-if="setAbility('MODERATOR')"
      v-slot:actions
    >
      <v-list-item
        v-if="setAbility('MODERATOR')"
        @click="save"
        dense
      >
        <v-list-item-avatar>
          <v-icon>save</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('button.save') }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="setAbility('MODERATOR')"
        @click="saveAs(false)"
        dense
      >
        <v-list-item-avatar>
          <v-icon>save_alt</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('button.saveAs') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="setAbility('ADMIN') && !data.parentMap"
        @click="saveAs(true)"
        dense
      >
        <v-list-item-avatar>
          <v-icon>crop</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          Создать представление (наследование)
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="setAbility('ADMIN')"
        @click="$refs.map.runImport()"
        dense
      >
        <v-list-item-avatar>
          <v-icon>import_export</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ $t('importGeoJSON') }}
        </v-list-item-content>
      </v-list-item>
    </template>

    <template slot="doc">
      <d-map
        ref="map"
        :poi="poi"
        v-if="data && gisCredentials"
        :map="data"
      />
    </template>

    <template slot="navigation">
      <div v-show="selectedView === 'search'">
        <portal-target name="map-search"/>
      </div>
      <div v-show="selectedView === 'layers'">
        <v-text-field
          v-model="searchField"
          :placeholder="$t('layers')"
          :flat="!solo"
          dense
          :solo-inverted="!solo"
          :solo="solo"
          @focusin="solo=true"
          @focusout="solo=false"
          class="mx-2"
          hide-details>
          <template v-slot:append>
            <v-btn small @click="$refs.searchLayerDialog.open()" icon>
              <v-icon>add</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <map-config
          class="mt-2"
          :layers.sync="layers"
          :search-field="searchField"
          :data="data"
          @refresh-map="$refs.map.refresh()"
          @saveAs="saveAs"
          @save="save"/>

        <v-card
          v-if="data.parentMap"
          outlined
          class="caption ma-2 pa-2"
        >
          Эта карта является проекцией от карты <b>{{ data.parentMap.name }}</b>.
          <br/>
          Список слоев, настройки (темы, надписи) и порядок отображения настраиваются в родительской карте.
        </v-card>
      </div>

      <create-map-dialog ref="createMapDialog"/>
      <search-layer-dialog @select="addLayer" ref="searchLayerDialog"/>
    </template>
  </view-data-page>
</template>

<script>
import ViewDataPage from '../utils/ViewDataPage'
import MapConfig from './helpers/MapConfig'
import SearchLayerDialog from '@/components/map/helpers/SearchLayerDialog'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/map-view/messages'
import CreateMapDialog from '@/components/map/CreateMapDialog'
import { mapGetters } from 'vuex'
import DMap from '@/components/map/DMap'

export default {
  name: 'MapView',
  props: {
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dataId: Number
  },
  components: { DMap, CreateMapDialog, SearchLayerDialog, MapConfig, ViewDataPage },
  data: () => ({
    poi: null,
    solo: false,
    searchField: '',
    selectedView: 'search',
    tabList: [
      {
        icon: 'search',
        view: 'search'
      },
      {
        icon: 'layers',
        view: 'layers'
      },
      {
        icon: 'message',
        view: 'comments'
      }
    ],
    data: {},
    layers: []
  }),
  i18n: { messages: messages },
  methods: {
    async getMapById (id) {
      let response = await this.$axios.get('data/get', { params: { id: id } })
      let map = response.data
      map.position = map.position ? JSON.parse(map.position) : null
      return map
    },
    async init () {
      this.data = null
      let id = this.dataId || this.$route.params.id
      let map = await this.getMapById(id)
      let layerConfigList
      if (map.parentMap) {
        layerConfigList = map.parentMap.layerConfigList
      } else {
        layerConfigList = map.layerConfigList
      }
      this.layers = layerConfigList
        .sort((a, b) => {
          return a.indexNumber > b.indexNumber ? 1 : -1
        })
        .map(it => {
          let layer = it.layer
          layer.visible = it.visible
          return layer
        })
      let { sys, layerId } = this.$route.query

      if (sys && layerId) {
        let layer = this.layers.find(it => it.id === Number(layerId))
        if (layer) this.poi = { layer: layer, sys: sys }
      }
      this.data = map
    },
    selectView (view) {
      this.selectedView = view
    },
    addLayer (layer) {
      this.layers.push(layer)
    },
    saveAs (inheritance = false) {
      let map = { ...this.data }
      if (inheritance) {
        map.parentMap = { id: this.data.id }
        map.layerConfigList = []
      } else {
        map.parentMap = null
      }
      map.id = 0
      map.name = ''
      this.prepareToSave(map)
      this.$refs.createMapDialog.create(map)
    },
    prepareToSave (map, withPosition = true) {
      map.layerConfigList = this.layers.map((item, index) => ({
        layer: { id: item.id },
        indexNumber: index,
        visible: item.visible,
        labels: item.labels ? item.labels.filter(it => it.isActive).map(it => it.id).join() : '',
        themes: item.themes ? item.themes.filter(it => it.isActive).map(it => it.id).join() : ''
      }))

      if (withPosition) {
        map.position = this.$refs.map.getPosition()
      } else {
        let newPosition = this.$refs.map.getPosition()
        map.position.center = newPosition.center
        map.position.zoom = newPosition.zoom
        map.position = JSON.stringify(map.position)
      }
    },
    async save () {
      let map = await this.getMapById(this.data.id)
      this.prepareToSave(map, false)
      this.$axios
        .post('/map/save', map)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('mapSaved'))
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.loading = false)
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters([
      'gisCredentials'
    ])
  },
  watch: {
    '$route.params.id' () {
      this.init()
    }
  }
}
</script>
