<template>
  <v-dialog v-model="dialog" width="600">
    <v-card class="pb-2">
      <v-card-title>
        <h3>{{ $t('privateFiles')}}</h3>
        <v-spacer/>
        <v-icon @click="dialog=false">close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-text-field
          hide-details
          autocomplete="off"
          dense
          outlined
          prepend-inner-icon="search"
          :placeholder="$t('fileSearch')"
          v-model="search"
        />
      </v-card-text>
      <v-list dense>
        <template v-for="(file, index) in computedList">
          <v-list-item class="pl-6" :key="index" @click="selectFile(file)" :ripple="false">
            <v-list-item-avatar tile>
              <v-img :src="`/image/${file.type}.png`"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ file.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getDate(file.creationDate) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset v-if="(computedList.length>1 && index<computedList.length-1)"/>
        </template>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment'
  import {EventBus} from "../../event-bus";
  import messages from '../../componet-locale/file-list/messages'


  export default {
    name: "FileList",
    data: () => ({
      dialog: false,
      list: [],
      search: ''
    }),
    i18n: { messages: messages },
    methods: {
      getDate(timestamp) {
        return moment(timestamp).format('DD MMM YYYY HH:mm')
      },
      open() {
        this.$axios
          .get('files/get-file-entities', { params: { name: '' } })
          .then(({data}) => {
            this.list = data;
            this.dialog = true;
          });
      },
      selectFile(file) {
        this.$axios
          .post('files/share', null, {params: {id: file.id}})
          .then(() => {
            if (!file.isShared) {
              EventBus.$emit('showInfoMessage', this.$t('accessGranted'))
              file.isShared = true
            }
            this.$emit('on-choose', [file]);
            this.dialog = false;
          })
      }
    },
    computed: {
      computedList() {
        return this.list.filter(i => i.name.includes(this.search))
      }
    }
  }
</script>
