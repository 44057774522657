<script>
export default {
  name: 'ScrollingPage',
  props: {
    page: {
      type: Object,
      required: true
    },
    scale: {
      type: Number,
      required: true
    },
    focusedPage: {
      type: Number,
      default: undefined
    },
    scrollTop: {
      type: Number,
      default: 0
    },
    clientHeight: {
      type: Number,
      default: 0
    },
    enablePageJump: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    elementTop: 0,
    elementHeight: 0
  }),
  computed: {
    isPageFocused () {
      return this.page.pageNumber === this.focusedPage
    },
    isPageVisible () {
      const { elementTop, elementHeight, scrollTop, clientHeight } = this

      if (!elementHeight) {
        return
      }

      const preloadGapHeight = clientHeight / 2
      const tpbWithGap = Math.max(0, scrollTop - preloadGapHeight)
      const bottomWithGap = scrollTop + clientHeight + preloadGapHeight

      return Math.min(bottomWithGap, elementTop + elementHeight) >= Math.max(tpbWithGap, elementTop)
    },
    //TODO: obsolete
    isElementFocused () {
      const { elementTop, elementHeight, scrollTop, clientHeight } = this
      if (!elementHeight) return

      const bottom = elementTop + elementHeight
      const halfHeight = (elementHeight / 2)
      const halfScreen = (clientHeight / 2)
      const delta = elementHeight >= halfScreen ? halfScreen : halfHeight
      const threshold = scrollTop + delta

      return elementTop < threshold && bottom >= threshold
    }
  },
  methods: {
    jumpToPage () {
      if (!this.enablePageJump || this.isElementFocused || !this.isPageFocused) return

      this.$emit('page-jump', this.elementTop)
    },
    updateElementBounds () {
      const { offsetTop, offsetHeight } = this.$el
      this.elementTop = offsetTop
      this.elementHeight = offsetHeight
    }
  },
  watch: {
    scale: 'updateElementBounds',
    scrollTop: 'updateElementBounds',
    clientHeight: 'updateElementBounds',
    isPageFocused: 'jumpToPage'
  },
  mounted () {
    this.updateElementBounds()
  },
  render () {
    const { isPageFocused, isElementFocused, isPageVisible } = this
    return this.$scopedSlots.default({
      isPageFocused,
      isElementFocused,
      isPageVisible
    })
  }
}
</script>
