import SockJs from 'sockjs-client'
import Stomp from 'webstomp-client'
import axios from 'axios'
import store from '../store'

class Websocket {

  client

  connect() {
    const socket = new SockJs(`${axios.defaults.baseURL}/ws-endpoint`)
    this.client = Stomp.over(socket, { debug: false })
    this.client.heartbeat.incoming = 25000
    this.client.heartbeat.outgoing = 25000

    this.client.connect({},
      () => this.subscribeOnSystemChannel(),
      () => {
        console.log('WebSocket connection has been closed. Trying to reconnect again.')
        store.dispatch('unsubscribeAll')
        setTimeout(() => this.connect(), 5000)
      }
    )
  }

  disconnect() {
    this.client.disconnect()
    store.dispatch('setWebsocketConnection', false)
  }

  send(topic, message) {
    this.client.send(topic, message)
  }

  subscribeOnSystemChannel() {
    [
      '/user/ws-broker/system-channel',
      '/ws-broker/system-channel'
    ]
      .forEach(destination => this.client.subscribe(destination, it => this.handleSystemMessage(it)))
  }

  handleSystemMessage(frame) {
    const message = JSON.parse(frame.body)
    switch (message.type) {
      case 'INVITED':
        store.dispatch('onInviteChat', message.body)
        break
      case 'DETACHED':
        store.dispatch('detachChat', message.body)
        break
      case 'DELETED':
        store.dispatch('onDeleteChat', message.body)
        break
      case 'USERS_CONNECTED':
        store.dispatch('setOnlineUsers', message.body.ids)
        break
      case 'USER_DISCONNECTED':
        store.dispatch('removeFromOnlineUsers', message.body.id)
        break
      case 'SUBSCRIBE_AND_REFRESH':
        store.dispatch('refreshChatListAndSubscribe', message.body)
        break
      case 'MESSAGE_READ':
        store.dispatch('updateLastMessageAttributes', message.body)
        break
      case 'PING_PONG':
        this.client.send('/ws-prefix/ping-pong')
        break
    }
  }

}

function initiateWebSocket() {
  return new Websocket()
}

export default initiateWebSocket()
