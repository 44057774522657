<template>
  <v-combobox :item-text="model.itemText"
              :items="items"
              :placeholder="label"
              @change="$emit('change')"
              chips
              class="ml-2 input-filter"
              clearable
              dense
              hide-details
              multiple
              return-object
              small-chips
              v-model="model.value">
    <template v-slot:selection="{ item, index }">
      <v-chip :color="model.color" dark label small v-if="index === 0">
        {{ evalItemText(item) }}
      </v-chip>
      <span class="grey--text caption" v-if="index === 1">+{{ model.value.length - 1 }}</span>
    </template>
  </v-combobox>
</template>

<script>
  export default {
    name: 'ComboBoxFilter',
    props: {
      model: {
        type: Object,
        default: {
          value: [],
          urlDataSource: '',
          itemText: ''
        }
      },
      label: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      items: []
    }),
    mounted () {
      this.loadData()
    },
    watch: {
      model () {
        this.loadData()
      }
    },
    methods: {
      loadData () {
        if (this.model.urlDataSource) {
          let projectId = this.selectedProject.id
          this.$axios
            .get(this.model.urlDataSource, {
              params: { id: projectId }
            })
            .then(response => this.items = response.data)
        }
      },
      evalItemText(item) {
        if (typeof this.model.itemText === "function") {
          return this.model.itemText(item)
        } else {
          return item[this.model.itemText]
        }
      }
    }
  }
</script>
