<template>
  <v-select :items="items"
            :placeholder="label"
            @change="$emit('change')"
            class="ml-2 input-filter"
            dense
            v-model="model.value">
    <template v-slot:selection="{ item }">
      <span class="pl-7 grey--text">{{ $t(item.text) }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-icon class="position-absolute">{{ item.icon }}</v-icon>
      <span class="pl-7">{{ $t(item.text) }}</span>
    </template>
  </v-select>
</template>

<script>
import messages from '../../componet-locale/sorting/messages'

export default {
  name: 'Sorting',
  props: {
    model: {
      type: Object,
      default: {}
    },
    label: {
      type: String,
      default: ''
    }
  },
  i18n: { messages: messages },
  data: () => ({
    items: [
      {
        text: 'byDate',
        icon: 'keyboard_arrow_down',
        value: {
          sortBy: 'creationDate',
          desc: 'DESC',
        }
      },
      {
        text: 'byDate',
        icon: 'keyboard_arrow_up',
        value: {
          sortBy: 'creationDate',
          desc: 'ASC',
        }
      },
      {
        text: 'byName',
        icon: 'keyboard_arrow_down',
        value: {
          sortBy: 'name',
          desc: 'ASC',
        }
      },
      {
        text: 'byName',
        icon: 'keyboard_arrow_up',
        value: {
          sortBy: 'name',
          desc: 'DESC',
        }
      }
    ]
  })
}
</script>
