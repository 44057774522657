<template>
  <v-card outlined
          class="my-2">
    <v-card-title>
      Field
      <v-spacer/>
      <v-btn icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-text-field
        v-model="value.address"
        label="Address"
        dense
      />
      <v-text-field
        v-model="value.name"
        label="Name"
        dense
      />
      <v-text-field
        v-model="value.description"
        label="Description"
        dense
      />
      <v-text-field
        v-model="value.postfix"
        label="Postfix"
        dense
      />
      <v-text-field
        v-model="value.minValue"
        label="Min value"
        dense
      />
      <v-text-field
        v-model="value.minThresholdValue"
        label="Min Threshold value"
        dense
      />
      <v-text-field
        v-model="value.maxValue"
        label="Max value"
        dense
      />
      <v-text-field
        v-model="value.maxThresholdValue"
        label="Max Threshold value"
        dense
      />
      <v-select
        v-model="value.dataType"
        :items="dataTypeList"
        label="Data Type"
        dense
      />

      <v-color-picker
        v-model="value.color"
        flat
        light
        mode="hex"
        hide-canvas
        label="Color"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ModbusField',
  props: ['value'],
  data: () => ({
    dataTypeList: ['WORD', 'FLOAT', 'BOOLEAN']
  })
}
</script>
