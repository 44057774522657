<template>
  <div :id="`image-data-${id}`" style="height: 100% !important; position: relative"
       v-bind:class="dense ? '' : 'widget'">
    <v-toolbar absolute collapse dense flat max-width="400px" style="border-radius: 5px">
      <a @click="openInNewTab(data)"
         v-bind:class="dense ? 'subtitle-1' : 'headline'"
         class="hide-text-overflow simple-link">
        {{ data.name }}
      </a>
    </v-toolbar>
    <d-map
      widget
      v-if="data && data.layerConfigList"
      :map="data"
      />
  </div>
</template>

<script>
import DMap from "@/components/map/DMap";
import {mapGetters} from "vuex";

export default {
  name: 'MapWidget',
  components: { DMap },
  props: {
    id: {
      type: Number,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: {}
  }),
  mounted () {
    this.loadData()
  },
  computed: {
    ...mapGetters([
      'gisCredentials'
    ])
  },
  methods: {
    async getMapById(id) {
      let response = await this.$axios.get('data/get', {params: {id: id}})
      let map = response.data
      map.position = map.position ? JSON.parse(map.position) : null
      return map
    },
    async loadData () {
      this.data = await this.getMapById(this.id)
    }
  }
}
</script>
