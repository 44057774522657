<template>
  <div>
    <div class="pa-1 elevation-1" style="width: 350px; height: 100vh; position: fixed; z-index: 20; background: white">
      <v-card-title>
        {{ $t('selectedLayers') }}
        <v-spacer/>
        <v-btn @click="$refs.searchLayerDialog.open()" icon>
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-title>

      <map-config :layers.sync="layers" sandbox/>

      <v-card-actions>
        <v-spacer/>
        <v-btn @click="createMap" text>{{ $t('createMap') }}</v-btn>
      </v-card-actions>

    </div>
    <div style="position: absolute; width: calc(100% - 280px); padding-left: 350px; height: 100vh">
      <d-map :source-layers="layers" ref="map"/>
      <create-map-dialog :layers="layers" ref="createMapDialog"/>
    </div>
    <search-layer-dialog @select="addLayer" ref="searchLayerDialog"/>
  </div>
</template>

<script>
import MapConfig from './helpers/MapConfig'
import CreateMapDialog from './CreateMapDialog'
import SearchLayerDialog from '@/components/map/helpers/SearchLayerDialog'
import messages from '../../componet-locale/map-sand-box/messages'
import DMap from "@/components/map/DMap";

export default {
  name: 'MapSandbox',
  props: {
    layerIdList: {
      type: String,
      default: '',
    }
  },
  i18n: {messages: messages},
  components: {DMap, SearchLayerDialog, CreateMapDialog, MapConfig},
  data: () => ({
    layers: [],
  }),
  methods: {
    addLayer(layer) {
      this.layers.push(layer)
    },
    createMap() {
      let layerConfigList = this.layers.map((item, index) => ({
        layer: item,
        indexNumber: index,
        visible: item.visible
      }))
      let map = {
        position: this.$refs.map.getPosition(),
        layers: this.layers,
        layerConfigList: layerConfigList,
        project: this.selectedProject
      }
      this.$refs.createMapDialog.create(map)
    },
    init() {
      this.$axios
        .get(`/layer/get-list/${this.layerIdList}`)
        .then(response => this.layers = response.data)
    }
  },
  watch: {
    layers() {
      this.$refs.map.refresh()
    }
  },
  created() {
    this.init()
  }
}
</script>
