<template>
  <v-snackbar :color="color"
              :timeout="5000"
              bottom
              right v-model="snackbar">
    {{ message }}
    <v-btn @click="snackbar = false" text>
      {{ $t('button.close') }}
    </v-btn>
  </v-snackbar>
</template>

<script>
  import { EventBus } from '@/event-bus'

  export default {
    name: 'Snackbar',
    data: () => ({
      snackbar: false,
      message: '',
      color: 'primary',
      progressBar: false,
      progress: 0,
      time: 0
    }),
    mounted () {
      EventBus.$on('showInfoMessage', (message) => this.showInfoMessage(message))
      EventBus.$on('showErrorMessage', (message) => this.showErrorMessage(message))
      EventBus.$on('showSuccessMessage', (message) => this.showSuccessMessage(message))
    },
    methods: {
      showSuccessMessage (message) {
        this.showMessage(message, 'success')
      },
      showInfoMessage (message) {
        this.showMessage(message, 'info')
      },
      showErrorMessage (message) {
        this.showMessage(message, 'error')
      },
      showMessage (message, color) {
        this.color = color
        this.message = message
        this.snackbar = true
      }
    }
  }
</script>
