<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <v-card height="800">
      <v-card-title>
        {{ userProject.project.name }}
        <v-spacer/>
        <v-switch
          :label="$t('moderator')"
          v-model="userProject.isModerator"/>
      </v-card-title>
      <v-card-text v-if="tree">
        <v-treeview
          v-model="selection"
          selectable
          :items="tree"/>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn color="red" outlined
               @click="deleteUserProject">
          {{ $t('button.remove') }}
        </v-btn>
        <v-btn
          color="primary" outlined
          @click="saveUserProject">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '../../../componet-locale/user-project-create-dialog/messages'

export default {
  name: 'UserProjectEditDialog',
  i18n: { messages: messages },
  data: () => ({
    userProject: {
      categoryList: {}
    },
    tree: null,
    selection: [],
    dialog: false,
    allCategories: [],
  }),
  methods: {
    editUserProject (userProject) {
      this.userProject = { ...userProject }
      this.selection = this.excludeDefault(userProject.categoryList).map(it => it.id)
      this.getAllCategories()
      this.dialog = true
    },
    saveUserProject () {
      this.$axios
        .post('project/add-user-project',
          {
            user: this.userProject.user.id,
            project: this.userProject.project.id,
            categoryList: this.selection,
            moderator: this.userProject.isModerator,
            id: this.userProject.id
          }
        )
        .then(() => {
          this.dialog = false
          this.$emit('on-save')
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    deleteUserProject () {
      this.dialog = false
      this.$emit('on-remove', this.userProject.id)
    },
    deleteCategory (categeory) {
      this.userProject.categoryList.splice(this.userProject.categoryList.indexOf(categeory), 1)
    },
    createTree (data) {
      let map = new Map()
      this.excludeDefault(data)
        .forEach(item => {
          if (!map.has(item.type)) {
            map.set(item.type, [item])
          } else {
            map.get(item.type).push(item)
          }
        })
      let array = []
      map.forEach((items, key) => {
        if (items.length === 1 || !key) {
          array = array.concat(items)
          map.delete(key)
        } else {
          array.push({
            name: key,
            selected: true,
            id: key,
            children: items
          })
        }
      })
      this.tree = array
    },
    getAllCategories () {
      this.$axios
        .get('/category/get-all-for-project/', { params: { id: this.userProject.project.id } })
        .then(response => {
          this.allCategories = response.data
          this.createTree(response.data)
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    excludeDefault (categoryList) {
      return Array.isArray(categoryList) ? categoryList.filter(it => !it.isDefault) : []
    }
  }
}
</script>
