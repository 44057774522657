<template>
  <v-container>
    <v-card class="elevation-0">
      <v-card-title>
        {{ $t('widgets') }}
        <v-spacer/>
        <flexible-button @click="editableMode = true"
                         class="mr-2"
                         icon="edit"
                         :label="$t('button.edit')"
                         v-show="!editableMode && widgets.length"/>
        <flexible-button @click="save"
                         class="ml-2"
                         icon="save"
                         :label="$t('button.save')"
                         v-show="editableMode"/>
        <flexible-button @click="$refs.widgetDetailsDialog.open()"
                         icon="add"
                         :label="$t('addWidget')"
                         v-show="!editableMode"/>
      </v-card-title>

      <widget-bar :editableMode="editableMode" :widgets="widgets" @onRemove="onRemove"/>

      <v-card-text v-if="!widgets.length" v-show="!editableMode" class="pb-0">
        <v-card outlined>
          <v-card-text class="pa-5">
            {{ $t('hintWidget') }}
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <widget-details-dialog @onSave="loadWidgets" ref="widgetDetailsDialog"/>
  </v-container>
</template>

<script>
import FlexibleButton from '../utils/FlexibleButton'
import WidgetBar from './WidgetBar'
import WidgetDetailsDialog from './WidgetDetailsDialog'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/dashboard/messages'
import projectMessages from '../../shared-locale/project/messages'

export default {
  name: 'Dashboard',
  components: {
    WidgetDetailsDialog,
    WidgetBar,
    FlexibleButton
  },
  i18n: {
    messages: messages,
    sharedMessages: projectMessages
  },
  data: () => ({
    editableMode: false,
    widgets: []
  }),
  methods: {
    save () {
      this.editableMode = false
      this.$axios
        .post('widget/save-all', this.widgets)
        .then(() => {
          this.editableMode = false
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          EventBus.$emit('frameResize')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    onRemove () {
      if (!this.widgets.length) this.editableMode = false
      this.loadWidgets()
    },
    loadWidgets () {
      this.$axios
        .get('widget/get-all', {
          params: { projectId: this.selectedProject.id }
        })
        .then(response => {
          this.widgets = response.data
        })
    }
  },
  created () {
    this.loadWidgets()
  },
  watch: {
    'selectedProject.id' () {
      this.loadWidgets()
    }
  }
}
</script>
