<template>
  <v-list-item
    :input-value="inputValue"
    :ripple="false"
    class="pl-2"
    @click=""
  >

    <v-list-item-avatar>
      <user-avatar
        v-if="item.type === 'DIRECT'"
        :user="user(item)"
        class="ma-0"
        dense
        online-dot
      />
      <v-avatar
        v-else
        :color="avatar.color"
        dark
        size="40"
      >
        <v-icon>
          {{ avatar.icon }}
        </v-icon>
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content class="py-1">
      <v-list-item-title>
        <slot name="title">
          <span
            class="body-2"
            v-text="item.name"
          />
        </slot>
      </v-list-item-title>
      <v-list-item-subtitle v-html="body"/>
    </v-list-item-content>

    <v-list-item-action class="my-1">

      <div class="caption full-width text-center">
        {{ messageDateTimeInList(item.topRecord.createdAt) }}
      </div>

      <div style="height: 25px">
        <v-chip
          v-show="item.numberOfUnreadMessages"
          color="blue"
          small
        >
          {{ item.numberOfUnreadMessages }}
        </v-chip>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { messageDateTimeInList } from '@/components/chats/chat-utils'
import messages from '../../componet-locale/system-message-template/messages'
import UserAvatar from '../utils/UserAvatar'
import { chatMixin } from './mixins/chat-mixin'

export default {
  name: 'ChatItem',
  components: { UserAvatar },
  mixins: [chatMixin],
  i18n: { messages: messages },
  props: ['item', 'inputValue'],
  methods: {
    messageDateTimeInList,
    getContentForEventMessage(record) {
      const { messageKey, decryptedBody, author } = record
      let metadata
      if (['INVITED_BY', 'REMOVED_BY'].includes(messageKey)) {
        metadata = JSON.parse(decryptedBody.match(/\[(.*?)\]/g)).map(i => i.fullName).join(', ')
      } else if ('RENAMED' === messageKey) {
        metadata = JSON.parse(decryptedBody)
      }
      let content = `${author.firstName}: `

      switch (messageKey) {
        case 'JOINED':
          content += this.$t('joinedToChat')
          break
        case 'LEFT':
          content += this.$t('leftChat')
          break
        case 'INVITED_BY':
          content += this.$t('invitedToChat', [metadata])
          break
        case 'REMOVED_BY':
          content += this.$t('removedFromChat', [metadata])
          break
        case 'RENAMED':
          content += this.$t('renamedChat', { oldName: metadata[0], newName: metadata[1] })
      }
      return content
    },
    user(chat) {
      const [firstName, lastName] = chat.name.split(' ')
      return { firstName: firstName, lastName: lastName, id: chat.companionID }
    }
  },
  computed: {
    avatar() {
      return this.getAvatar(this.item.type) || {}
    },
    body() {
      let decryptedBody = (this.item.topRecord.messageKey === 'DELETED') ? 'Сообщение удалено' : this.item.topRecord.decryptedBody
      const messageKeys = ['JOINED', 'LEFT', 'INVITED_BY', 'REMOVED_BY', 'RENAMED']
      const filePrefix = /{"file/
      const lastMessage = `${this.item.topRecord.author.firstName}: ${decryptedBody}`
      if (filePrefix.test(lastMessage)) {
        return `${this.item.topRecord.author.firstName}: File`
      } else if (messageKeys.includes(this.item.topRecord.messageKey)) {
        return this.getContentForEventMessage(this.item.topRecord)
      } else {
        const div = document.createElement('div')
        div.innerHTML = lastMessage
        return div.textContent || div.innerText || ''
      }
    }
  }
}
</script>
