<template>
  <v-btn :fab="displayXS"
         :outlined="!displayXS"
         :small="displayXS"
         :text="!displayXS"
         @click="click()"
         class="btn-list-panel"
         color="primary">
    <span v-if="!displayXS">{{ label }}</span>
    <v-icon medium small v-if="displayXS">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
  import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

  export default {
    name: 'FlexibleButton',
    props: ['label', 'icon'],
    mixins: [displayServiceMixin],
    methods: {
      click () {
        this.$emit('click')
      }
    }
  }
</script>
