<template>
  <v-dialog v-model="dialog" width="800">

    <v-card>
      <v-card-title>
        <span>{{ user.firstName }} {{ user.lastName }}</span>
      </v-card-title>

      <v-card-text class="pt-0">
        <p>{{ $t('registrationDate') }}: <b>{{ formatDate(user.registrationDate) }}</b></p>
        <p v-if="user.isImportedFromAD">{{ $t('importedFromAD') }}</p>

        <v-select
          :items="roleList"
          @change="save"
          class="my-2"
          dense
          item-text="groupName"
          outlined
          return-object
          v-model="user.role"/>

        <v-text-field
          outlined
          append-icon="person"
          v-model="user.login"
          :disabled="user.isImportedFromAD"
          dense
          :label="$t('login')"
        />

        <v-text-field
          outlined
          :label="$t('firstName')"
          name="login"
          append-icon="person"
          dense
          type="text"
          :disabled="user.isImportedFromAD"
          v-model="user.firstName"/>

        <v-text-field
          outlined
          :label="$t('surname')"
          name="login"
          append-icon="person"
          required
          dense
          :disabled="user.isImportedFromAD"
          type="text"
          v-model="user.lastName"/>

        <v-text-field
          outlined
          :label="$t('phone')"
          name="login"
          dense
          append-icon="phone"
          required
          type="text"
          v-model="user.phoneNumber"/>

        <v-text-field
          outlined
          :label="$t('email')"
          name="login"
          dense
          :disabled="user.isImportedFromAD"
          append-icon="alternate_email"
          required
          type="text"
          v-model="user.email"/>

        <v-text-field
          outlined
          :label="$t('position')"
          name="position"
          :disabled="user.isImportedFromAD"
          dense
          append-icon="home_work"
          type="text"
          v-model="user.position"/>

        <v-text-field
          outlined
          :label="$t('company')"
          name="company"
          :disabled="user.isImportedFromAD"
          dense
          append-icon="home_work"
          type="text"
          v-model="user.company"/>

        <v-switch
          :label="$t('emailNotification')"
          v-model="user.emailNotificationsAllowed"/>

      </v-card-text>

      <v-card-title>
        <v-btn @click="deactivateUser"
               color="red"
               outlined
               v-if="user.isActive">
          {{ $t('deactivate') }}
        </v-btn>

        <v-btn @click="activateUser"
               color="green"
               dark
               v-if="!user.isActive">
          {{ $t('activate') }}
        </v-btn>

        <v-btn @click="generateToken"
               outlined
               class="ml-2">
          Сброс пароля
        </v-btn>
        <v-spacer/>
        <v-btn
          :loading="userActivityLoading"
          @click="exportActivityLog"
          outlined
        >
          {{ $t('exportUserActivity') }}
        </v-btn>
        <v-btn @click="save"
               outlined
               class="ml-2">
          {{ $t('save') }}
        </v-btn>
      </v-card-title>

      <v-divider/>

      <div>
        <v-card-title>
          {{ $t('projects') }}
          <v-spacer/>
          <v-btn outlined @click="$refs.userProjectCreateDialog.createUserProject(user)">
            <v-icon left>add</v-icon>
            {{ $t('button.add') }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <user-project-card :value="item" :is-active="user.role==='USER'"
                             v-for="item in userProjects"
                             @on-remove="removeUserProject"
                             @click.native="openUserProject(item)"/>

          <v-card outlined v-if="!userProjects.length">
            <v-card-text>
              <p>{{ $t('noProjects') }}</p>
            </v-card-text>
          </v-card>
        </v-card-text>
      </div>
    </v-card>

    <generate-token-to-restore-password-dialog :user="user" ref="generateTokenToRestorePasswordDialog"/>
    <user-project-create-dialog @on-save="getUserProjects" ref="userProjectCreateDialog"/>
    <user-project-edit-dialog @on-save="getUserProjects" @on-remove="removeUserProject" ref="userProjectEditDialog"/>

  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import UserProjectCard from '@/components/admin/user-management/UserProjectCard'
import UserProjectCreateDialog from '@/components/admin/user-management/UserProjectCreateDialog'
import UserProjectEditDialog from '@/components/admin/user-management/UserProjectEditDialog'
import messages from '../../../componet-locale/user-details-dialog/messages'
import GenerateTokenToRestorePasswordDialog
  from '@/components/admin/user-management/GenerateTokenToRestorePasswordDialog'

export default {
  name: 'UserDetailsDialog',
  components: { GenerateTokenToRestorePasswordDialog, UserProjectCreateDialog, UserProjectEditDialog, UserProjectCard },
  i18n: { messages: messages },
  data: () => ({
    dialog: false,
    userActivityLoading: false,
    user: {},
    userProjects: [],
    roleList: [],
  }),
  created() {
    this.$axios
      .get('/users/get-all-user-roles')
      .then(response => {
        this.roleList = response.data
      })
  },
  methods: {
    open(user) {
      this.user = { ...user }
      this.dialog = true
      this.getUserProjects()
    },
    getUserProjects() {
      this.$axios
        .get('project/get-user-projects', { params: { id: this.user.id } })
        .then(response => this.userProjects = response.data.sort((first, second) => {
          if (first.project.name > second.project.name) return 1
          if (second.project.name > first.project.name) return -1
          return 0
        }))
    },
    generateToken() {
      this.$refs.generateTokenToRestorePasswordDialog.open()
    },
    save() {
      this.$axios
        .post('users/save', this.user)
        .then(() => {
          EventBus.$emit('showSuccessMessage', this.$t('dataSaved'))
          this.$emit('on-change')
          this.getUserProjects()
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
    },
    deactivateUser() {
      this.changeStatus(false)
    },
    activateUser() {
      this.changeStatus(true)
    },
    changeStatus(active) {
      let status = this.user.active
      this.user.isActive = active
      let url = active ? 'users/activate' : 'users/deactivate'
      this.$axios
        .post(url, null, { params: { id: this.user.id } })
        .then(() => {
          let message = active ? 'userActivated' : 'userDeactivated'
          EventBus.$emit('showInfoMessage', this.$t(message))
          this.$emit('on-change')
        })
        .catch(() => {
          EventBus.$emit('showErrorMessage', this.$t('error'))
          this.user.active = status
        })
    },
    exportActivityLog() {
      this.userActivityLoading = true
      this.$axios
        .post(`admin/activity-log/export/xlsx`, null, {
          responseType: 'arraybuffer',
          params: {
            userId: this.user.id
          },
          timeout: 300000
        })
        .then(({ data }) => {
          let url = window.URL.createObjectURL(new Blob([data]))
          let link = document.createElement('a')
          link.href = url
          link.download = `${this.getUserFullName(this.user)} activity-log.xlsx`
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('exportFailed')))
        .finally(() => this.userActivityLoading = false)
    },
    removeUserProject(id) {
      this.$axios
        .post('project/remove-user-project', null, {
          params: { id: id }
        })
        .then(() => {
          this.getUserProjects()
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    openUserProject(item) {
      if (this.user.role === 'USER') this.$refs.userProjectEditDialog.editUserProject(item)
    }
  }
}
</script>
