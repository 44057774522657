<template>
  <v-dialog v-model="dialog" width="500" eager>
    <v-card>
      <v-card-text>
        <file-pond
          allow-multiple
          :server="myServer"
          :files="initFiles"
          @addfilestart="isFileUploaded = false"
          @processfiles="isFileUploaded = true"
          @removefile="updateUploadingState"
          :label-idle="$t('hint')"
          ref="pond"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog=false"
          outlined
        >
          {{ $t('button.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          @click="save"
          color="primary"
          outlined
          :disabled="!isFileUploaded"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import { EventBus } from '@/event-bus'
import messages from '../../componet-locale/new-file-dialog/messages'

const FilePond = vueFilePond()

export default {
  name: 'NewFileDialog',
  components: { FilePond },
  props: {
    shareOnce: {
      type: Boolean,
      default: false
    },
    externalStorage: {
      type: Object,
      default: () => null
    },
    dataModifier: {
      type: Function,
      default: () => {
      }
    }
  },
  i18n: { messages: messages },
  data: function () {
    return {
      myServer: {
        process: (fieldName, file, metadata, load, error) => this.handleFileUpload(file, load, error),
        revert: (uniqueFileId) => this.handleFileRevert(uniqueFileId)
      },
      data: {},
      dialog: false,
      uploadedFiles: [],
      isFileUploaded: false,
      initFiles: []
    }
  },
  methods: {
    save() {
      this.$axios
        .post(this.storage.saveRecordsURL, this.uploadedFiles)
        .then(({ data }) => {
          this.dialog = false
          this.$emit('on-save', data)
        })
    },
    determineType(file) {
      let types = ['doc', 'jpg', 'pdf', 'png', 'ppt', 'txt', 'xls', 'zip']

      let fileExt = file.name.split('.').pop().toLowerCase()
      if (types.includes(fileExt)) return fileExt

      switch (fileExt) {
        case 'csv':
        case 'xlsx':
          return 'xls'
        case 'jpeg':
          return 'jpg'
        case 'rtf':
        case 'docx':
          return 'doc'
        case 'pptx':
          return 'ppt'
      }

      return 'zip'
    },
    handleFileUpload(file, load, error) {
      let formData = new FormData()
      formData.append('file', file)

      this.$axios
        .post(this.storage.saveFileURL, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000,
        })
        .then(response => {
          this.data.type = this.determineType(file)
          this.data.path = response.data
          this.data.size = file.size
          this.data.isShared = this.shareOnce
          this.data.name = file.name
          this.uploadedFiles.push(this.data)
          this.dataModifier(this.data)
          load(response.data)
        })
        .catch(reason => {
          error()
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.data = {}
        })
    },
    handleFileRevert(uniqueFileId) {
      this.uploadedFiles = this.uploadedFiles.filter(f => f.path !== uniqueFileId)
      this.updateUploadingState()
    },
    updateUploadingState() {
      this.isFileUploaded = this.uploadedFiles.length !== 0
    },
    open(initFiles = null) {
      this.dialog = true
      if (initFiles) this.initFiles = initFiles
    },
    resetData() {
      this.$refs.pond.removeFiles()
      this.isFileUploaded = false
      this.uploadedFiles = []
    }
  },
  computed: {
    storage() {
      let defaultStorage = {
        saveFileURL: '/files/save-user-file',
        saveRecordsURL: 'files/save-records'
      }
      return this.externalStorage || defaultStorage
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.resetData()
      }
    }
  }
}
</script>

<style>

.filepond--drop-label {
  min-height: 100px !important;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: #3949AB;
}


.filepond--panel-root {
  background-color: transparent;
}

.filepond--list-scroller {
  top: 20px !important;
}

</style>
