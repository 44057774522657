<template>
  <div>
    <div class="px-6" v-if="selectedProject.id">
      <span class="subtitle-1">{{ $t('searchByProject') }}<b>{{ selectedProject.name }}</b></span>
    </div>
    <div v-if="dataList.length">
      <v-divider/>
      <v-list>
        <v-subheader>{{ $t('data') }}</v-subheader>
        <v-list-item
          v-for="item in dataList"
          :three-line="!!item.description"
          :two-line="!item.description"
          @click="openData(item)"
        >
          <v-list-item-avatar>
            <v-avatar>
              <v-icon large>
                {{ getDataTypeDetails(item.dataType).icon }}
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="highlight(item.name)"/>
            <v-list-item-subtitle
              v-if="item.description"
              class="grey--text text--darken-4"
              v-html="highlight(item.description)"
            />
            <v-list-item-subtitle>
              {{ item.project.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="tasks.length">
      <v-divider/>
      <v-list>
        <v-subheader>{{ $t('tasks') }}</v-subheader>
        <v-list-item v-for="item in tasks"
                     two-line
                     @click="openTask(item)">
          <v-list-item-avatar>
            <v-avatar>
              <v-icon color="purple"
                      large>bug_report
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="highlight(item.name)"/>
            <v-list-item-subtitle>
              {{ item.project.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-avatar :color="getStatus(item.status).color"
                      size="16"/>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="chats.length">
      <v-divider/>
      <v-list>
        <v-subheader>{{ $t('chats') }}</v-subheader>
        <v-list-item
          v-for="item in chats"
          two-line
          @click="openChat(item)"
        >
          <user-avatar
            v-if="item.type === 'DIRECT'"
            :user="user(item.name)"
          />
          <v-avatar
            v-else
            :color="getAvatar(item.type).color"
            class="mr-3"
            size="40"
          >
            <v-icon dark>
              {{ getAvatar(item.type).icon }}
            </v-icon>
          </v-avatar>
          <v-list-item-title v-html="highlight(item.name)"/>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="articles.length">
      <v-divider/>
      <v-list>
        <v-subheader>{{ $t('articles') }}</v-subheader>
        <v-list-item v-for="item in articles"
                     two-line
                     @click="openWiki(item)">
          <v-list-item-avatar>
            <v-avatar>
              <v-icon large>assignment</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="highlight(item.currentTitle)"/>
            <v-list-item-subtitle>
              {{ getUserFullName(item.author) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="userProjects.length">
      <v-divider/>
      <v-list>
        <v-subheader>{{ $t('projects') }}</v-subheader>
        <v-list-item v-for="item in userProjects"
                     two-line
                     @click="select(item.project)">
          <project-avatar :project="item.project"
                          class="elevation-0 mr-4"/>
          <v-list-item-title v-html="highlight(item.project.name)"/>
          <v-list-item-action>
            <v-icon v-if="item.favorite"
                    color="yellow darken-3">star
            </v-icon>
            <v-icon v-else
                    color="grey">star_border
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { issueMixin } from '@/mixins/issue-mixin'
import { mapActions } from 'vuex'
import UserAvatar from '../../components/utils/UserAvatar'
import messages from '../../componet-locale/limited-search-result/messages'
import { chatMixin } from '../chats/mixins/chat-mixin'
import ProjectAvatar from '../utils/ProjectAvatar'

export default {
  name: 'LimitedSearchResult',
  components: { ProjectAvatar, UserAvatar },
  props: {
    searchQuery: {
      type: String,
      default: ''
    }
  },
  mixins: [issueMixin, chatMixin],
  data: () => ({
    dataList: [],
    tasks: [],
    chats: [],
    articles: [],
    userProjects: [],
    cancel: undefined
  }),
  i18n: { messages: messages },
  methods: {
    ...mapActions([
      'selectProject'
    ]),
    select (project) {
      this.$emit('close')
      this.selectProject({
        project: project,
        path: 'home'
      })
    },
    open (path) {
      this.$emit('close')
      if (this.$route.path.toLowerCase() !== path.toLowerCase()) this.$router.push(path)
    },
    openData (item) {
      let path = `/project/${item.project.id}/${item.dataType}/${item.id}`
      this.open(path)
    },
    openTask (item) {
      let path = `/project/${item.project.id}/issue/${item.id}`
      this.open(path)
    },
    openChat (item) {
      EventBus.$emit('show-chats', item.id)
      this.$emit('close')
    },
    openWiki (item) {
      let path = `/wiki/${item.id}`
      this.open(path)
    },
    find (newSearchQuery) {
      const CancelToken = this.$axios.CancelToken
      let self = this
      if (this.cancel) {
        this.cancel()
      }
      this.$axios
        .get('global-search/find', {
          params: {
            q: newSearchQuery,
            projectId: this.selectedProject.id
          },
          cancelToken: new CancelToken(function executor (c) {
            self.cancel = c
          })
        })
        .then(response => {
          this.dataList = response.data.abstractDataList
          this.tasks = response.data.issues
          this.chats = response.data.chats
          this.articles = response.data.articles
          this.userProjects = response.data.userProjects
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
    },
    highlight (name) {
      let re = new RegExp(this.searchQuery, 'ig')
      return this.searchQuery ? name.replace(re, `<em>$&</em>`) : name
    },
    clean () {
      this.dataList = []
      this.tasks = []
      this.chats = []
      this.userProjects = []

    },
    init () {
      this.find(this.searchQuery)
    },
    user (chatName) {
      const [firstName, lastName] = chatName.split(' ')
      return { firstName: firstName, lastName: lastName }
    }
  },
  watch: {
    searchQuery (newSearchQuery) {
      this.find(newSearchQuery)
    }
  },
  created () {
    this.find(this.searchQuery)
  }
}

</script>
