<template>
  <v-text-field :label="label"
                v-model="value"
                :append-icon="appendIcon"
                :type="type"
                @click:append="show = !show"/>
</template>

<script>
export default {
  name: 'HiddenTextField',
  props: ['model', 'label', 'hidden'],
  data: () => ({
    show: false
  }),
  computed: {
    appendIcon () {
      return this.hidden ?
        this.show ? 'mdi-eye' : 'mdi-eye-off'
        : ''
    },
    type () {
      return this.hidden ?
        this.show ? 'text' : 'password'
        : 'text'
    },
    value: {
      get () {
        return this.model
      },
      set (val) {
        this.$emit('update:model', val)
      }
    }
  }
}
</script>


