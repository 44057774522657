<template>
  <v-fade-transition>
    <v-card
        id="cont"
        class="chat-modal-view__card"
        height="450px"
        width="400px"
        elevation="3"
    >

      <v-card-title class="py-1 px-2 second-color">
        <v-col
            id="cont-header"
            class="pa-0 text-truncate"
        >
          <span class="body-1">{{ chatTitle }}</span>
          <v-spacer/>
        </v-col>
        <v-icon
            color="white"
            v-text="'close'"
            @click="$emit('close')"
        />
      </v-card-title>

      <v-card-text class="pa-0 chat-modal-view__card-text">
        <div class="message-wrapper">
          <message
              v-for="item in messageList"
              :data-row="item.id"
              :key="item.createdAt"
              :message="item"
          />
        </div>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="grey lighten-4">
        <v-text-field
            background-color="white"
            hide-details
            outlined
            v-model="message"
            dense
            @keyup.enter="sendMessage"
            :placeholder="placeHolder"
        />
      </v-card-actions>
    </v-card>
  </v-fade-transition>
</template>

<script>
  import { EventBus } from '../../event-bus'
  import Message from './messages/Message'
  import { chatMixin } from './mixins/chat-mixin'
  import messages from '../../componet-locale/chat-frame/messages'

  export default {
    components: { Message },
    i18n: { messages: messages },
    mixins: [chatMixin],
    props: ['chat'],
    name: 'ChatModalView',
    data: () => ({
      messageList: [],
      observer: null,
      onBottom: false,
      message: ''
    }),
    methods: {
      addMessage({ message, chatID }) {
        if (this.chat && this.chat.chat.id != chatID) return
        const scrolledToBottom = this.isScrolledToBottom()
        message.author = { firstName: message.firstName, lastName: message.surname }
        this.messageList.push(message)
        this.$nextTick(() => {
          const element = document.querySelector(`[data-row='${message.id}']`)
          this.observer.observe(element)
          if (scrolledToBottom || this.currentLoginUser.id === message.authorID) {
            this.scrollToBottom()
            this.chat.numberOfUnreadMessages = 0
          } else if (!this.isElementInViewport(element)) {
            this.$store.dispatch('increaseNumberOfUnreadMessages', this.chat.chat.id)
          }
        })
      },
      clear() {
        if (this.observer) this.observer.disconnect()
        this.messageList.length = 0
      },
      isElementInViewport(el) {
        const rect = el.getBoundingClientRect()
        return (
          rect.top >= 0 &&
          rect.bottom <= document.getElementsByClassName('message-wrapper')[0].clientHeight
        )
      },
      isScrolledToBottom() {
        const { scrollTop, clientHeight, scrollHeight } = document.getElementsByClassName('message-wrapper')[0]
        this.onBottom = Math.ceil(scrollTop) + clientHeight >= scrollHeight
        return this.onBottom
      },
      initObserver() {
        const options = {
          root: document.querySelector('.message-wrapper'),
          rootMargin: '0px',
          threshold: 0.99
        }
        const callback = this.handleIntersecting

        this.observer = new IntersectionObserver(callback, options)
        const allDataRows = document.querySelectorAll('[data-row]')
        const elements = Array.from(allDataRows).filter(el => Number(el.dataset.row) > this.chat.lastMessageID)
        elements.forEach(i => this.observer.observe(i))
      },
      handleIntersecting(entries) {
        const lastEntry = entries[entries.length - 1]
        if (lastEntry.isIntersecting) {
          const messageID = lastEntry.target.dataset['row']
          this.proceedOnIntersection(messageID)
        }
      },
      proceedOnIntersection(messageID) {
        const message = this.messageList.find(i => i.id == messageID)
        if (message.createdAt <= this.chat.lastMessageTime) {
          this.observer.unobserve(document.querySelector(`[data-row='${messageID}']`))
          return
        }
        const indexOfMessage = this.messageList.findIndex(i => i.id == messageID)
        const indexOfLastReadMessage = this.messageList.findIndex(i => i.id == this.chat.lastMessageID)
        this.messageList // unobserve elements
          .slice(indexOfLastReadMessage, indexOfMessage + 1)
          .map(i => i.id)
          .forEach(i => {
            const element = document.querySelector(`[data-row='${i}']`)
            this.observer.unobserve(element)
          })
        this.$ws.send(`/ws-prefix/chat/${this.chat.chat.id}/message-read/${messageID}`)
      },
      scrollToBottom() {
        const messageWrapper = document.getElementsByClassName('message-wrapper')[0]
        messageWrapper.scrollTop = messageWrapper.scrollHeight
      },
      sendMessage() {
        if (!this.message) return
        const chatID = this.chat.chat.id
        this.$ws.send(`/ws-prefix/notify/${chatID}`, this.message)
        this.message = ''
      },
      fetchMessages(chatID) {
        this.$axios
          .get('chat-records/get-messages', { params: { chatId: chatID } })
          .then(({ data }) => {
            this.messageList = data
            this.$nextTick(() => {
                this.initObserver()
                this.scrollToBottom()
                document.getElementsByClassName('message-wrapper')[0]
                  .addEventListener('scroll', this.isScrolledToBottom)
              }
            )
          })
      },
      setDraggable() {
        let elmnt = document.getElementById('cont')
        elmnt.style.bottom = '0'
        elmnt.style.right = '100px'

        let relPosX = 0, relPosY = 0
        let pos1 = 0, pos2 = 0

        if (document.getElementById('cont-header')) {
          // if present, the header is where you move the DIV from:
          document.getElementById('cont-header').onmousedown = dragMouseDown
        } else {
          // otherwise, move the DIV from anywhere inside the DIV:
          elmnt.onmousedown = dragMouseDown
        }

        function dragMouseDown(e) {
          e.preventDefault()
          // get the mouse cursor position at startup:
          pos1 = e.pageX
          pos2 = e.pageY
          relPosX = pos1 - elmnt.offsetLeft
          relPosY = pos2 - elmnt.offsetTop

          document.onmouseup = closeDragElement
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag
        }

        function elementDrag(e) {
          const modalWidth = elmnt.offsetWidth
          const modalHeight = elmnt.offsetHeight
          const app = document.getElementById('app')
          const x = Math.min(
            Math.max(0, e.pageX - relPosX),
            window.innerWidth - modalWidth
          )
          const y = Math.min(
            Math.max(0, e.pageY - relPosY),
            window.innerHeight - modalHeight
          )

          elmnt.style.top = y + 'px'
          elmnt.style.left = x + 'px'
          elmnt.style.removeProperty('bottom')
          elmnt.style.removeProperty('right')
        }

        function closeDragElement() {
          // stop moving when mouse button is released:
          document.onmouseup = null
          document.onmousemove = null
        }
      }
    },
    mounted() {
      this.setDraggable()
    },
    created() {
      EventBus.$on('incoming-message', this.addMessage)
    },
    beforeDestroy() {
      this.clear()
      this.$store.dispatch('setActiveModalChat', null)
      EventBus.$off('incoming-message', this.addMessage)
    },
    computed: {
      chatTitle() {
        return this.chat ? this.chat.chatName : ''
      },
      placeHolder() {
        let text = this.$t('message') + ' '
        if (this.chat) text += this.chat.chatName
        return text
      }
    },
    watch: {
      chat: {
        deep: true,
        immediate: true,
        handler: function(val) {
          if (val) this.fetchMessages(val.chat.id)
        }
      }
    }
  }
</script>

<style scoped>
  #cont-header {
    cursor: move;
  }

  .chat-modal-view__card {
    position: fixed;
    z-index: 100;
  }

  .chat-modal-view__card-text {
    min-height: 354px;
  }

  .message-wrapper {
    position: absolute;
    overflow-y: auto;
    width: 100%;
    max-height: 354px;
    bottom: 56px;
  }
</style>
