<template>
  <v-card elevation="0" class="without-background">
    <v-card-text class="pa-0">
      <message class="px-0" v-for="item in messageList" :key="item.createdAt" :message="item"/>
    </v-card-text>
    <v-card-actions class="px-0">
      <v-text-field autocomplete="off"
                    v-model="message"
                    dense
                    outlined
                    :placeholder="$t('placeholder')"
                    @keydown.enter="sendMessage">
        <template v-slot:prepend-inner>
          <chat-attachments :chat="data.chat"/>
        </template>
        <template v-slot:append>
          <v-slide-x-reverse-transition hide-on-leave>
            <v-icon v-if="message">send</v-icon>
          </v-slide-x-reverse-transition>
        </template>
      </v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
import Message from '@/components/chats/messages/Message'
import SockJs from 'sockjs-client'
import Stomp from 'webstomp-client'
import messages from '@/componet-locale/comments/messages'
import ChatAttachments from '@/components/chats/ChatAttachments'

export default {
  name: 'Comments',
  components: { ChatAttachments, Message },
  props: {
    data: Object
  },
  i18n: { messages: messages },
  data: () => ({
    message: '',
    messageList: [],
    chat: {},
    stompClient: undefined,
    subscriptions: new Map(),
  }),
  created () {
    this.init()
  },
  destroyed () {
    if (this.stompClient) {
      this.stompClient.disconnect()
      this.stompClient = null
    }
  },
  methods: {
    connect (id) {
      const socket = new SockJs(`${this.$axios.defaults.baseURL}/ws-endpoint`)
      this.stompClient = Stomp.over(socket, { debug: false })
      this.stompClient.connect({}, () => this.subscribe(id))
    },
    subscribe (id) {
      const subscription = this.stompClient.subscribe(
        `/ws-broker/topic/${id}`,
        it => this.addMessage(JSON.parse(it.body))
      )
      this.subscriptions.set(id, subscription) // add subscription object associated with chatID into map
    },
    createChat () {
      this.$axios
        .post(
          'chats/new-chat-for-data', {
            dataId: this.data.id,
            message: this.message
          })
        .then(response => {
          this.data.chat = response.data
          this.message = ''
          this.init()
        })
    },
    sendMessage () {
      if (!(this.data.chat && this.data.chat.id)) {
        this.createChat()
        return
      }

      this.stompClient.send(`/ws-prefix/notify/${this.chatId}`, this.message)
      this.message = ''
    },
    writeHistory (val) {
      let template = `
      <div style="display:flex;">
        <div class="vertical"></div>
        {VALUE}
      </div>`
      let message = template.replace('{VALUE}', val)
      this.stompClient.send(`/ws-prefix/notify/${this.chatId}`, message)
    },
    addMessage (message) {
      let user = this.chat.members.find(it => message.authorID === it.id)
      if (user) {
        message.author = user
        this.messageList.push(message)
      } else {
        this.init()
      }
    },
    init () {
      if (!this.data || !this.chatId) return
      this.connect(this.chatId)
      this.$axios
        .get('chat-records/get-messages', { params: { chatId: this.chatId } })
        .then(response => {
          this.messageList = response.data
          this.chat = response.data[0].chat
        })
    }
  },
  watch: {
    data () {
      if (this.stompClient) this.stompClient.disconnect()
      this.messageList = []
      this.chat = undefined
      this.stompClient = null
      this.init()
    }
  },
  computed: {
    chatId () {
      if (!this.data.chat || !this.data.chat.id) return undefined
      return this.data.chat.id
    }
  }
}
</script>
