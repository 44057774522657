<template>
  <div>
    <component
      :is="chartComponent"
      :chart-data="datacollection"
      :options="options"
      style="height: 500px"
    />
  </div>
</template>

<script>
import LineChart from '@/components/utils/chart/line-chart'
import BarChart from '@/components/utils/chart/bar-chart'
import PieChart from '@/components/utils/chart/pie-chart'
import BubbleChart from '@/components/utils/chart/bubble-chart'
import RadarChart from '@/components/utils/chart/radar-chart'
import ScatterChart from '@/components/utils/chart/scatter-chart'
import DoughnutChart from '@/components/utils/chart/doughnut-chart'

export default {
  name: 'Chart',
  components: { DoughnutChart, ScatterChart, RadarChart, BubbleChart, PieChart, BarChart, LineChart },
  props: {
    table: {
      type: Object,
      default () {
        return {
          headers: []
        }
      }
    },
    chart: {
      type: Object,
      default () {
        return {
          headers: []
        }
      }
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  computed: {
    datacollection () {
      if (this.table.headers !== undefined && this.chart.headers !== undefined) {
        if (this.chart.headers.length === 0) {
          this.chart.headers = this.table.headers
        }
        let labels = this.chart.headers
          .filter(header => header.isValue)
          .map(header => header.name)
        let datasets = []
        if (this.chart.showDetails) {
          this.table.items.forEach(item => {
            let data = []
            this.chart.headers
              .filter(header => header.isValue)
              .forEach(header => {
                data.push(item[header.field])
              })
            var label = 'empty'
            if (this.chart.mainColumn !== undefined) {
              label = item[this.chart.mainColumn.field]
            }
            let color = this.getRandomColor()
            datasets.push({
              label: label,
              borderColor: color,
              backgroundColor: color,
              data: data,
              fill: false
            })
          })
        } else {
          let data = []
          this.chart.headers
            .filter(header => header.isValue)
            .forEach(header => {
              data.push(this.getTotal(header))
            })
          let color = this.getRandomColor()
          datasets.push({
            label: this.chart.name,
            borderColor: color,
            backgroundColor: color,
            data: data
          })
        }
        return {
          labels: labels,
          datasets: datasets
        }
      } else {
        return {}
      }
    },
    chartComponent () {
      switch (this.chart.type) {
        case 'LINE':
          return LineChart
        case 'BAR':
          return BarChart
        case 'PIE':
          return PieChart
        case 'RADAR':
          return RadarChart
        case 'DOUGHNUT':
          return DoughnutChart
      }
    }
  },
  methods: {
    getRandomColor () {
      let letters = '0123456789ABCDEF'
      let color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    getTotal (header) {
      let sum = 0
      this.table.items.forEach(item => {
        sum += item[header.field]
      })
      if (typeof sum === 'number') {
        return sum
      }
    }
  }
}
</script>
