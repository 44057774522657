<template>
  <v-bottom-sheet inset v-model="dialog" width="900">
    <v-card class="full-height-card">
      <v-card-text class="pa-0">
        <image-editor :options="options" ref="tuiImageEditor" v-if="options.includeUI.loadImage.path"/>
      </v-card-text>
      <v-card-actions style="background: #151515">
        <v-btn dark @click="close" outlined>{{ $t('button.close') }}</v-btn>
        <v-spacer/>
        <v-btn dark @click="download" outlined>{{ $t('button.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
  import 'tui-image-editor/dist/svg/icon-a.svg'
  import 'tui-image-editor/dist/svg/icon-b.svg'
  import 'tui-image-editor/dist/svg/icon-c.svg'
  import 'tui-image-editor/dist/svg/icon-d.svg'

  import 'tui-image-editor/dist/tui-image-editor.css'

  import { ImageEditor } from '@toast-ui/vue-image-editor'

  const icona = require('tui-image-editor/dist/svg/icon-a.svg')
  const iconb = require('tui-image-editor/dist/svg/icon-b.svg')
  const iconc = require('tui-image-editor/dist/svg/icon-c.svg')
  const icond = require('tui-image-editor/dist/svg/icon-d.svg')
  const whiteTheme = {
    'menu.normalIcon.path': icond,
    'menu.activeIcon.path': iconb,
    'menu.disabledIcon.path': icona,
    'menu.hoverIcon.path': iconc,
    'submenu.normalIcon.path': icond,
    'submenu.activeIcon.path': iconb,
    'common.bi.image': 'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
    'common.bisize.width': '251px',
    'common.bisize.height': '21px',
    'common.backgroundImage': './img/bg.png',
    'common.backgroundColor': '#fff',
    'common.border': '1px solid #c1c1c1',
  }

  export default {
    name: 'ExportDialog',
    components: {
      ImageEditor
    },
    data: () => ({
      dialog: false,
      useDefaultUI: false,
      options: { // for tui-image-editor component's "options" prop
        cssMaxWidth: 700,
        cssMaxHeight: 1000,
        includeUI: {
          loadImage: {},
          theme: whiteTheme
        }
      },
      src: ''
    }),
    methods: {
      open () {
        this.dialog = true
        this.load()
      },
      close () {
        this.options.includeUI.loadImage = {}
        this.dialog = false
      },
      load () {
        let canvas = document.getElementsByClassName('ol-unselectable')[0]
        this.options.includeUI.loadImage = {
          path: canvas.toDataURL('image/png'),
          name: 'someImageName',
        }
      },
      download () {
        let dataURL = this.$refs.tuiImageEditor.invoke('toDataURL', 'image/png')
        let link = document.createElement('a')
        document.body.appendChild(link) // Firefox requires the link to be in the body :(
        link.href = dataURL
        link.download = `Export_(${new Date().toDateString()}).png`
        link.click()
        document.body.removeChild(link)
      }
    }
  }
</script>

<style>
  #tie-btn-icon,
  #tie-btn-mask,
  #tie-btn-flip,
  #tie-btn-filter {
    display: none !important;
  }

  .color-picker-control {
    overflow-x: auto;
  }

  .tui-colorpicker-palette-container > div {
    display: none;
  }

  .tui-image-editor-main-container {
    border: none !important;
  }

  .tui-image-editor-header {
    display: none;
  }

  .tui-image-editor-main {
    position: static !important;
  }

  .tui-colorpicker-palette-button {
    height: 23px;
    width: 23px;
  }

  .tui-colorpicker-palette-container {
    overflow: auto;
  }

  .tui-colorpicker-palette-container {
    height: 50px !important;
  }
</style>
