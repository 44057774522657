<template>
  <v-data-iterator
    :items="table.items"
    row wrap>
    <template slot="item" slot-scope="props">
      <v-flex lg3 md4 sm6 xs12>
        <v-card class="ma-2" outlined>
          <v-card-title><h4>{{ props.item['cell_0'] }}</h4></v-card-title>
          <v-divider/>
          <v-slide-y-transition>
            <v-list dense>
              <v-list-item v-for="header in table.headers.filter(h => h.field !== 'url')">
                <v-list-item-content>{{ header.name }}:</v-list-item-content>
                <v-list-item-content class="align-end">{{props.item[header.field]}}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-slide-y-transition>
        </v-card>
      </v-flex>
    </template>
  </v-data-iterator>
</template>

<script>
  export default {
    name: 'DataIterator',
    props: ['table']
  }
</script>
