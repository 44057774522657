<template>
  <v-card
    color="grey lighten-4"
    class="mx-2 mb-4"
    outlined
  >
    <v-list-item @click="selectCategory(item.category.id)">
      <v-list-item-avatar>
        <v-icon>folder</v-icon>
      </v-list-item-avatar>
      <v-list-item-title>
        {{ item.category.name }}
      </v-list-item-title>
      <v-list-item-action v-if="item.size">
        {{ item.size }}
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'CategoryCard',
  props: { item: Object },
  methods: {
    selectCategory (id) {
      this.$router.push({
        name: 'category-list',
        params: {
          projectId: this.selectedProject.id,
          id: id
        }
      })
    }
  }
}
</script>
