<template>
  <div>
    <v-list dense>
      <v-list-item
        dense
        v-for="template in this.layer.poiTemplates"
      >
        <v-list-item-action class="mr-0">
          <layer-poi-badge :template="template"/>
        </v-list-item-action>
        <v-list-item-content>
          <span
            v-html="template.name"
            class="caption"
          />
        </v-list-item-content>
        <v-list-item-action
          v-if="setAbility('ADMIN')"
        >
          <v-btn
            @click="$refs.unbindTemplateConfirmationDlg.open(template)"
            icon
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div
      v-if="setAbility('ADMIN')"
      class="px-3"
    >
      <v-btn
        @click="$refs.findLayerPoiTemplateDlg.open()"
        outlined
        block
        color="primary"
      >
        <v-icon>add</v-icon>
        Добавить
      </v-btn>
    </div>
    <confirmation-dialog
      title="<span class='red--text'>Это действие не может быть отменено!<br/>Все данные этого типа в слое будут удалены.</span><br/><br/>Вы действительно хотите удалить шаблон"
      ref="unbindTemplateConfirmationDlg"
      @confirm="unbindTemplate"
    />
    <find-layer-poi-template-dialog
      :layer="layer"
      @bind="bindTemplate"
      ref="findLayerPoiTemplateDlg"
    />
  </div>
</template>

<script>
import FindLayerPoiTemplateDialog from "@/components/map/FindLayerPoiTemplateDialog";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog";
import LayerPoiBadge from "@/components/layer-poi/LayerPoiBadge";
import { EventBus } from "@/event-bus";

export default {
  name: "LayerPoiTemplateConfigurations",
  components: { LayerPoiBadge, ConfirmationDialog, FindLayerPoiTemplateDialog },
  props: {
    layer: Object
  },
  methods: {
    unbindTemplate(template) {
      this.$axios
        .post('layer/unbind-poi-template', null, {
          params: {
            layerId: this.layer.id,
            templateId: template.id
          }
        })
        .then(response => {
          this.$emit('update', response.data)
          EventBus.$emit('showSuccessMessage', 'Шаблон удален')
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Произошла ошибка'))
    },
    bindTemplate(template) {
      this.$axios
        .post('layer/bind-poi-template', null, {
          params: {
            layerId: this.layer.id,
            templateId: template.id
          }
        })
        .then(response => {
          this.$emit('update', response.data)
          EventBus.$emit('showSuccessMessage', 'Шаблон добавлен')
        })
        .catch(() => EventBus.$emit('showErrorMessage', 'Произошла ошибка'))
    }
  }
}
</script>
