<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>
        Добавить слой
      </v-card-title>
      <v-card-text class="pt-0">
        <v-autocomplete
          :items="items"
          :search-input.sync="search"
          cache-items
          clearable
          outlined
          hide-details
          class="mt-2"
          error-messages=""
          item-text="name"
          label="Слой"
          no-data-text="Нет совпадений"
          return-object
          v-model="layer"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :disabled="!layer" @click="select" color="primary" outlined>
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SearchLayerDialog',
  data: () => ({
    dialog: false,
    items: [],
    layer: null,
    search: ''
  }),
  methods: {
    open() {
      this.item = null
      this.search = ''
      this.items = []
      this.layer = null
      this.dialog = true
      this.find()
    },
    select() {
      this.$emit('select', this.layer)
      this.dialog = false
    },
    find() {
      this.$axios
        .get('/data/search-by-type', {
          params: {
            str: this.search,
            projectId: this.selectedProject.id,
            dataType: 'LAYER'
          }
        })
        .then(response => this.items = response.data)
    }
  },
  watch: {
    search() {
      this.find()
    }
  }
}
</script>
