<template>
  <div class="d-flex row">
    <div
        v-for="(item, index) in items"
        :key="index"
        :class="`cell px-2 pt-1 flex-grow-1 flex-column text-center ${activeClass(item)}`"
        @click="setActiveItem(item.value)"
    >
      <v-icon
          class="d-flex"
          v-text="item.icon"
      />
      <span class="text-no-wrap caption d-inline-flex">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatTypeFilter',
  data: () => ({
    items: [
      { icon: 'home', label: 'Все', value: 'ALL' },
      { icon: 'supervisor_account', label: 'Публ.', value: 'PUBLIC' },
      { icon: 'lock', label: 'Прив.', value: 'PRIVATE' },
      { icon: 'account_circle', label: 'Личные', value: 'DIRECT' },
      { icon: 'bug_report', label: 'Задачи', value: 'ISSUE' },
      { icon: 'description', label: 'Коммент', value: 'DATA' }
    ]
  }),
  methods: {
    setActiveItem(e) {
      this.$store.dispatch('setChatFilter', e)
    },
    activeClass(e) {
      return e.value === this.$store.getters.filterByChatType ? 'active' : ''
    }
  },
  created() {
    this.$store.dispatch('setChatFilter', 'ALL')
  }
}
</script>
<style scoped>
.row {
  max-width: 100%;
  margin: auto;
}

.active {
  border-bottom: 3px solid;
}

.active .v-icon, .active span {
  opacity: 1 !important;
}

.cell .v-icon, .cell span {
  opacity: 0.5;
}

.cell:hover .v-icon, .cell:hover span {
  color: white;
  opacity: 0.8;
}

.cell:hover {
  cursor: pointer;
}

</style>
