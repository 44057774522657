<template>
  <div style="height: 100vh">
    <v-btn icon absolute right class="pr-0 mr-0" @click="data = null" v-if="data">
      <v-icon>close</v-icon>
    </v-btn>

    <v-container fill-height fluid v-if="!data">
      <v-layout align-center justify-center>
        <v-flex xl6 lg8 md9 s10 xs12>

          <v-card outlined class="mb-2" v-if="listOfRelatedData.length > 0">
            <v-card-title>
              <h3>{{ $t('listOfRelatedData') }}</h3>
            </v-card-title>
            <v-list>
              <v-list-item v-for="data in listOfRelatedData" @click="select(data)" dense>
                <v-list-item-avatar>
                  <v-icon>{{ getUrlByDataType(data.dataType).icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.description }} <span>{{ formatDate(data.creationDate) }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>

          <v-card @click="find()" outlined class="py-5">
            <v-card-actions>
              <v-spacer/>
              <v-icon :size="150">add</v-icon>
              <v-spacer/>
            </v-card-actions>
            <v-card-title>
              <v-spacer/>
              {{ $t('openDocument') }}
              <v-spacer/>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <component
      :is="getComponentByType(data.dataType)"
      :data-id="data.id"
      v-if="data"
      split-screen-mode
    />
    <search-data-dialog @select="select" ref="searchDataDialog"/>
  </div>
</template>

<script>
import MapView from '@/components/map/MapView'
import DocView from '@/components/doc/DocView'
import TableView from '@/components/table/TableView'
import SearchDataDialog from '@/components/utils/SearchDataDialog'
import ChartView from '@/components/chart/ChartView'
import ReportView from '@/components/report/ReportView'
import LayerView from '@/components/map/LayerView'
import CalculationView from '@/components/calc/CalculationView'
import messages from '../../componet-locale/data-viewer/messages'

export default {
  name: 'DataViewer',
  props: { dataId: Number, listOfRelatedData: Array },
  components: { CalculationView, LayerView, ReportView, ChartView, SearchDataDialog, TableView, DocView, MapView },
  data: () => ({
    data: null
  }),
  created () {
    if (this.dataId) {
      this.$axios
        .get('data/get', { params: { id: this.dataId } })
        .then(response => this.select(response.data))
    }
  },
  i18n: { messages: messages },
  methods: {
    select (data) {
      this.data = data
    },
    getComponentByType (dataType) {
      switch (dataType) {
        case 'DOC':
          return 'doc-view'
        case 'TABLE':
          return 'table-view'
        case 'LAYER':
          return 'layer-view'
        case 'MAP':
          return 'map-view'
        case 'CHART':
          return 'chart-view'
        case 'CALC':
          return 'calculation-view'
        case 'REPORT':
          return 'report-view'
      }
    },
    find (data) {
      this.$refs.searchDataDialog.open(data)
    }
  }
}
</script>
