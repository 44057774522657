<template>
  <div>
    <vl-layer-vector
      v-if="addressList.length"
      :z-index="6"
    >
      <vl-source-vector :features="addressList"/>
      <vl-style-box>
        <vl-style-icon
          src="/image/baseline_location.png"
          :scale="0.2"
          :anchor="[0.5, 0.4]"
        />
        <vl-style-stroke
          :width="5"
          color="red"
        />
      </vl-style-box>
    </vl-layer-vector>

    <portal to="map-search">
      <map-search-field
        v-model="filter"
        :loading="loading"
        @search="search"
        @clear="clearList"
        @tune="tune = !tune"
      />
      <map-search-filter
        v-if="tune"
        v-model="filter"
        :layers="layers"
        @search="search"
      />
      <layer-poi-search-result
        v-for="item in layerPoiList"
        :item="item"
        :search-field="filter.searchField"
        @show="show"
      />
      <zws-search-result
        v-for="item in entries"
        :item="item"
      />
      <address-search-result
        :addressList="addressList"
        @show="showBbox"
      />
    </portal>
  </div>
</template>

<script>
import ZwsCommandBuilder from '../../../services/zws-command-builder'
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/map-search/messages'
import AddressSearchResult from '@/components/map/map-search/search-result/AddressSearchResult'
import ZwsSearchResult from '@/components/map/map-search/search-result/ZwsSearchResult'
import MapSearchField from '@/components/map/map-search/MapSearchField'
import MapSearchFilter from '@/components/map/map-search/MapSearchFilter'
import LayerPoiSearchResult from '@/components/map/map-search/search-result/LayerPoiSearchResult'
import { transformExtent } from 'ol/proj'

export default {
  name: 'MapSearch',
  components: { LayerPoiSearchResult, MapSearchFilter, MapSearchField, ZwsSearchResult, AddressSearchResult },
  i18n: { messages: messages },
  props: ['layers', 'extent', 'view'],
  data: () => ({
    layerPoiList: [],
    entries: [],
    addressList: [],
    tune: false,
    loading: false,
    filter: {
      searchField: '',
      selectedLayer: null,
      selectedMode: null,
      selectedType: null,
      onlyByID: false,
      onlyByAddress: false,
      exactMatch: false
    }
  }),
  methods: {
    async search () {
      this.loading = true
      this.entries = []
      this.addressList = []
      let coordinates = this.getCoordinates()
      if (coordinates) {
        this.show(coordinates, true)
        this.loading = false
        return
      }

      if (!this.selectedLayer || this.onlyByAddress) {
        this.searchByAddress()
        if (this.onlyByAddress) {
          this.loading = false
          return
        }
      }
      this.findByLP()
      if (!this.zwsLayers.length) {
        this.loading = false
        return
      }
      await this.searchByZwsLayers()
    },
    async searchByZwsLayers () {
      let { selectedType, selectedMode, selectedLayer, searchField, onlyByID } = this.filter
      let items = []
      let typeId = selectedType ? selectedType.typeId : null
      let modeId = selectedMode ? selectedMode.index : null
      try {
        if (selectedLayer) {
          items = await ZwsCommandBuilder.search(selectedLayer, searchField, onlyByID, typeId, modeId)
        } else {
          for (let layer of this.zwsLayers) {
            let result = await ZwsCommandBuilder.search(layer, searchField, onlyByID)
            items = items.concat(result)
          }
        }
      } catch (e) {
        this.loading = false
      }
      this.loading = false
      if (items.length === 1) {
        this.show(items[0])
      } else if (items.length === 0) {
        EventBus.$emit('showInfoMessage', this.$t('noDataByRequest'))
      }
      items.forEach(item => {
        if (item.row.modeid && item.row.typeid) {
          let type = item.layer.typeList.find(type => type.typeId === item.row.typeid.value)
          let mode = type.modes.find(mode => mode.index === item.row.modeid.value)
          item.icon = mode.image
        }
      })
      this.entries = items
    },
    getCoordinates () {
      let field = this.filter.searchField.replaceAll(' ', '')
      let array = field
        .split(',')
        .map(item => {
          return parseFloat(item)
        })

      if (array.length === 2 && typeof array[0] === 'number' && typeof array[1] === 'number') {
        return [array[1], array[0]]
      }
    },
    show (item, byCoordinates = false) {
      this.$emit('show', item, byCoordinates)
    },
    showBbox (bbox) {
      this.$emit('showBbox', bbox)
    },
    clear () {
      this.clearList()
      this.filter = {
        searchField: '',
        selectedLayer: null,
        selectedMode: null,
        selectedType: null,
        onlyByID: false,
        onlyByAddress: false
      }
    },
    clearList () {
      this.entries = []
      this.addressList = []
    },
    searchByAddress (byExtent = true) {
      let displayExtent = transformExtent(this.view.calculateExtent(), 'EPSG:3857', 'EPSG:4326')

      let extent

      let maxExtent = [
        this.extent[0] < -528 ? -528 : this.extent[0].toFixed(2),
        this.extent[1] < -90 ? -90 : this.extent[1].toFixed(2),
        this.extent[2] > 551 ? 551 : this.extent[2].toFixed(2),
        this.extent[3] > 90 ? 90 : this.extent[3].toFixed(2)
      ]

      if (byExtent) {
        extent = [
          displayExtent[0] > maxExtent[0] ? displayExtent[0].toFixed(2) : maxExtent[0],
          displayExtent[1] > maxExtent[1] ? displayExtent[1].toFixed(2) : maxExtent[1],
          displayExtent[2] < maxExtent[2] ? displayExtent[2].toFixed(2) : maxExtent[2],
          displayExtent[3] < maxExtent[3] ? displayExtent[3].toFixed(2) : maxExtent[3]
        ]
      } else {
        extent = maxExtent
      }
      let url = `https://nominatim.openstreetmap.org/search?q=${this.filter.searchField}&countrycodes=ru&format=geojson&addressdetails=1&viewbox=${extent}&bounded=1`

      fetch(url)
        .then(res => res.json())
        .then(response => {
          this.addressList = response.features
          if (!this.addressList.length && byExtent) {
            this.searchByAddress(false)
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.loading = false))
    },
    findByLP () {
      let layer
      if (this.selectedLayer && this.layer.type === 'LAYER_POI') {
        layer = this.selectedLayer
      }
      let layerIdList = layer ? [layer.id] : this.lpLayers.map(it => it.id)
      let searchField = this.filter.exactMatch ? this.filter.searchField : `%${this.filter.searchField}%`
      this.$axios
        .get('layer-poi/find-by-name', {
          params: {
            layerIds: layerIdList.toString(),
            name: searchField
          }
        })
        .then(response => {
          this.layerPoiList = response.data.content
        })
        .catch(error => {
          console.error('Произошла ошибка при попытке поиска по объектам LP')
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.findByLP()
  },
  watch: {
    'filter.searchField' () {
      this.clearList()
    },
    'filter.selectedLayer' () {
      this.filter.selectedType = null
      this.filter.selectedMode = null
    },
    'filter.selectedType' () {
      this.selectedMode = null
    },
    layers () {
      this.clear()
    }
  },
  computed: {
    zwsLayers () {
      return this.layers.filter(layer => layer.type === 'ZWS')
    },
    lpLayers () {
      return this.layers.filter(layer => layer.type === 'LAYER_POI' && layer.visible)
    }
  }
}
</script>
