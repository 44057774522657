<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
  >
    <v-list-item @click="" dense :ripple="false">
      <v-list-item-avatar class="mr-0 col-first" style="align-self: stretch">
        <user-avatar :user="message.author"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="px-0 blue--text text--darken-2 font-weight-bold">
          {{ getUserFullName(message.author) }}
          <span class="caption grey--text">{{ getMessageTime() }}</span>
        </v-list-item-title>
        <component
          :is="dComponent"
          :message="message"
        />
      </v-list-item-content>
      <v-list-item-action v-if="message.messageKey === 'DEFAULT'">
        <v-slide-y-transition>
                <span style="position:absolute; top:-5px; right: 10px" v-if="hover">
                  <v-btn-toggle dense>
<!--                  <v-btn small>-->
<!--                    <v-icon color="orange">thumb_up</v-icon>-->
<!--                  </v-btn>-->
                  <v-btn
                    @click="remove"
                    small
                  >
                    <v-icon color="red">delete</v-icon>
                  </v-btn>
                </v-btn-toggle>
                </span>
        </v-slide-y-transition>
        <span/>
      </v-list-item-action>

      <confirmation-dialog
        title="Вы действительно хотите удалить это сообщение"
        @confirm="confirmRemove"
        ref="deleteConfirmationDialog"
      />
    </v-list-item>
  </v-hover>
</template>

<script>
import moment from 'moment'
import FileMessageContent from '@/components/chats/FileMessageContent'
import DefaultMessageContent from '@/components/chats/DefaultMessageContent'
import { messageDateTime } from '../chat-utils'
import UserAvatar from '@/components/utils/UserAvatar'
import EventMessageTemplateWrapper from '@/components/chats/messages/event-templates/EventMessageTemplateWrapper'
import ConfirmationDialog from "@/components/utils/ConfirmationDialog";

export default {
  name: 'Message',
  components: { ConfirmationDialog, UserAvatar },
  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    click() {
    },
    resolveMessage() {
      const eventMessageTypes = ['JOINED', 'LEFT', 'INVITED_BY', 'REMOVED_BY', 'RENAMED']
      const mask = /^{"file/
      if (mask.test(this.message.decryptedBody))
        return FileMessageContent
      else if (eventMessageTypes.includes(this.message.messageKey))
        return EventMessageTemplateWrapper
      else
        return DefaultMessageContent
    },
    getMessageTime() {
      return messageDateTime(this.message.createdAt)
    },
    remove() {
      this.$refs.deleteConfirmationDialog.open(this.message)
    },
    confirmRemove() {
      this.$emit('remove', this.message)
    }
  },
  computed: {
    dComponent() {
      return this.resolveMessage()
    },
    time() {
      return moment(this.message.createdAt).format('HH:mm')
    }
  }
}
</script>

<style>
.vertical {
  margin-right: 10px;
  width: 4px;
  border-radius: 4px;
  background: lightblue;
}

.message-body {
  white-space: pre-wrap;
}

.message-body {
  user-select: text;
}

.message-body div {
  white-space: normal;
}
</style>
