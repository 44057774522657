const CRATE_ISSUE = {
  title: 'reportError',
  icon: 'report_problem',
  color: 'white',
  background: '#2d89ff',
  action: 'createIssue'
}

const SHARE = {
  title: 'button.share',
  icon: 'link',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  action: 'share'
}

const EDIT = {
  title: 'button.edit',
  icon: 'edit',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  action: 'edit'
}

const DIRECT_TO_MAP = {
  title: 'button.directToMap',
  icon: 'map',
  color: 'white',
  background: '#2d89ff',
  outlined: true,
  primary: true,
  action: 'directToMap'
}

const REMOVE = {
  title: 'delete',
  icon: 'delete_outline',
  color: 'red',
  background: '#ffe0e0',
  outlined: true,
  action: 'remove'
}

const POI_INFO = {
  title: 'button.info',
  icon: 'info',
  color: 'white',
  background: '#2d89ff',
  outlined: true,
  action: 'info'
}

const ATTACHED_DATA = {
  title: 'button.attachData',
  icon: 'attach_file',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  action: 'attachData'
}

const HISTORY = {
  title: 'button.history',
  icon: 'history',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  action: 'history'
}

const CALC = {
  title: 'button.calculate',
  icon: 'functions',
  color: 'blue',
  outlined: true,
  background: '#f3f6fc',
  action: 'calculate'
}

const SPACER = {
  spacer: true
}

export { CRATE_ISSUE, SHARE, EDIT, DIRECT_TO_MAP, REMOVE, SPACER, POI_INFO, ATTACHED_DATA, HISTORY, CALC }
