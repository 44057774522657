<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="600"
      scrollable
      persistent
    >
      <v-card v-if="dialog">
        <v-card-title class="title pb-0">
          {{ name }}
        </v-card-title>
        <v-card-text class="pt-0">
          <div class="caption py-3">
            Создан: {{ formatDate(poi.creationDate) }}
            <br/>
            Последнее обновление: {{ formatDate(poi.modificationDate) }}
          </div>
          <layer-poi-map-widget
            class="mb-3"
            :poi="poi"
          />
          <action-toolbar
            v-if="actionsToolbar"
            @share="share"
            @createIssue="createIssue"
            @remove="openConfirmationDialog"
            @directToMap="directToMap"
            @history="history"
            @attachData="attachData"
            :actions="actions"
            :counters="counters"
          />
          <layer-poi-fields
            class="mt-7"
            :readonlyMode="readonlyMode"
            :poi="poi"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="close"
            outlined
          >
            Отмена
          </v-btn>
          <v-spacer/>
          <v-btn
            v-if="!readonlyMode"
            @click="print"
            outlined
          >
            <v-icon left>print</v-icon>
            Печать
          </v-btn>
          <v-btn
            v-if="!readonlyMode"
            @click="save"
            outlined
            color="primary"
          >
            <v-icon left>save</v-icon>
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <new-issue-dialog
      v-if="isAuthenticated"
      @on-save="$emit('on-update')"
      :map-widget="false"
      ref="newIssueDialog"
    />
    <confirmation-dialog
      ref="removeConfirmationDialog"
      @confirm="remove"
    />
    <share-data-dialog ref="shareDataDialog"/>
    <layer-poi-change-log-dialog ref="changelogDlg"/>
    <attached-data ref="attachedDataDlg"/>
  </div>
</template>

<script>
import LayerPoiFields from '@/components/layer-poi/LayerPoiFields'
import LayerPoiMapWidget from '@/components/layer-poi/LayerPoiMapWidget'
import { EventBus } from '@/event-bus'
import {
  CRATE_ISSUE,
  DIRECT_TO_MAP,
  REMOVE,
  SHARE,
  SPACER,
  ATTACHED_DATA,
  HISTORY
} from '@/components/map/helpers/map-actions'
import {
  getLinkToLayerPoi,
  getMapListByLayerPoi,
  getNameOfLayerPoi,
  saveLayerPoi
} from '@/components/layer-poi/layer-poi-service'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import NewIssueDialog from '@/components/issue/NewIssueDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import ActionToolbar from '@/components/utils/ActionToolbar'
import AttachedData from '@/components/layer-poi/attachments/AttachedData.vue'
import LayerPoiChangeLogDialog from '@/components/layer-poi/LayerPoiChangelogDialog'
import { poiMixin } from '@/mixins/poi-mixin'

export default {
  name: 'LayerPoiDialog',
  components: {
    LayerPoiChangeLogDialog,
    AttachedData,
    ActionToolbar,
    ConfirmationDialog,
    NewIssueDialog,
    ShareDataDialog,
    LayerPoiMapWidget,
    LayerPoiFields
  },
  props: {
    mapWidget: {
      type: Boolean,
      default: false
    },
    actionsToolbar: {
      type: Boolean,
      default: false
    },
    readonlyMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [poiMixin],
  data: () => ({
    dialog: false,
    poi: null,
    actions: [CRATE_ISSUE, ATTACHED_DATA, SHARE, HISTORY, REMOVE]
  }),
  created () {
    if (this.mapWidget) {
      this.actions.push(SPACER, DIRECT_TO_MAP)
    }
  },
  methods: {
    open (poi) {
      this.poi = { ...poi }
      this.populateCounters(poi.id)
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    directToMap (event) {
      getMapListByLayerPoi(
        this.poi,
        response => {
          this.openInNewTab(
            response.data[0],
            event,
            { layerPoiId: this.poi.id }
          )
        }, () => EventBus.$emit('showErrorMessage', 'Не найдены карты с выбранным объектом')
      )
    },
    getProperties () {
      let map = new Map()

      function getValue (prop) {
        switch (prop.field.type) {
          case 'STRING':
            return prop.string
          case 'DATE':
            return prop.date
          case 'NUMBER':
            return prop.number
          case 'BOOLEAN':
            return prop.boolean
          default:
            return prop.value
        }
      }

      this.poi.properties.forEach(prop => map.set(prop.field.name, getValue(prop)))
      return map
    },
    print () {
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      let rows = []
      this.getProperties().forEach((value, key) => {
        rows.push(`<div><b>${key}: </b>${value}</div>`)
      })
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${rows.join('')}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    share () {
      this.$refs.shareDataDialog.share(
        getLinkToLayerPoi(this.poi, this.selectedProject)
      )
    },
    createIssue () {
      this.$refs.newIssueDialog.createLayerPoiIssue(this.poi)
    },
    attachData () {
      this.$refs.attachedDataDlg.open(this.poi)
    },
    remove () {
      this.$axios
        .delete('layer-poi', {
          params: { id: this.poi.id }
        })
        .then(() => {
          EventBus.$emit('showInfoSuccessMessage', 'Объект удален')
          this.$emit('update')
          this.close()
        })
        .catch(() => {
          EventBus.$emit('showErrorSuccessMessage', 'Произошла ошибка')
        })
    },
    history () {
      this.$refs.changelogDlg.open(this.poi)
    },
    openConfirmationDialog () {
      this.$refs.removeConfirmationDialog.open(
        { name: this.name }
      )
    },
    save () {
      saveLayerPoi(
        this.poi,
        () => {
          EventBus.$emit('showSuccessMessage', 'Изменения сохранены')
          this.$emit('update')
        },
        () => EventBus.$emit('showErrorMessage', 'Произошла ошибка')
      )
    }
  },
  computed: {
    name () {
      return getNameOfLayerPoi(this.poi)
    }
  }
}
</script>
