<template>
  <div class="d-flex">
    <div class="vertical"/>
    <span class="grey--text">
      <component
          :is="component"
          :message="message"
      />
    </span>
  </div>
</template>

<script>
import RenamingEventMessage from '@/components/chats/messages/event-templates/RenamingEventMessage'
import JoiningEventMessage from '@/components/chats/messages/event-templates/JoiningEventMessage'
import LeavingEventMessage from '@/components/chats/messages/event-templates/LeavingEventMessage'
import InvitationEventMessage from '@/components/chats/messages/event-templates/InvitationEventMessage'
import ExclusionEventMessage from '@/components/chats/messages/event-templates/ExclusionEventMessage'

export default {
  props: ['message'],
  name: 'EventMessageTemplateWrapper',
  computed: {
    component() {
      switch (this.message.messageKey) {
        case 'JOINED':
          return JoiningEventMessage
        case 'LEFT':
          return LeavingEventMessage
        case 'RENAMED':
          return RenamingEventMessage
        case 'INVITED_BY':
          return InvitationEventMessage
        case 'REMOVED_BY':
          return ExclusionEventMessage
      }
    }
  }
}
</script>
