<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline lighten-2"
        primary-title
      >
        {{ $t('confirmation') }}
      </v-card-title>
      <v-card-text>
        <span v-html="title"/> <b>{{ data.name }}</b>?
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="dialog=false"
          outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <slot/>
        <v-btn
          @click="confirm"
          color="red"
          outlined
        >
          {{ $t('button.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: ['title'],
  data: () => ({
    dialog: false,
    data: {}
  }),
  methods: {
    open(data) {
      this.data = data
      this.dialog = true
    },
    confirm() {
      this.$emit('confirm', this.data)
      this.dialog = false
    }
  }
}
</script>
