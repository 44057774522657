<template>
  <div>
    <v-text-field
      v-model="number"
      :label="value.field.name"
      :readonly="readonly"
      @keydown="isNumber"
      dense
      outlined
      clearable
    />
  </div>
</template>

<script>
export default {
  name: "NumberField",
  props: {
    value: Object,
    readonly: Boolean
  },
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode === 190 && this.number.indexOf('.') === -1) return true
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  },
  computed: {
    number: {
      get: function () {
        if (!this.value.number) return ''
        return this.value.number.toString().replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ');
      },
      set: function (val) {
        if (!val) {
          this.value.number = null
          return
        }
        let number = Number(val.replaceAll(/\s/g, ''))
        if (number) {
          this.value.number = number
        } else {
          this.value.number = null
        }
      }
    }
  }
}
</script>
