<template>
  <v-dialog
    :fullscreen="displayXS"
    persistent
    v-if="dialog"
    v-model="dialog"
    width="900"
  >
    <v-card>
      <v-card-text v-if="editMode">
        <abstract-data-edit-form :item="item" ref="dataEditForm"/>
        <div v-if="item.dataType === 'MAP'">
          <v-text-field
            outlined
            dense
            label="Icon"
            v-model="item.icon"
          />
          <map-position
            :position="item.position"
          />
        </div>
      </v-card-text>

      <div v-if="!editMode">
        <v-container fluid>
          <v-row>
            <v-col lg="4" md="4" xl="4">
              <abstract-data-edit-form :item="item" ref="dataEditForm"/>
            </v-col>
            <v-col style="height: calc(100vh - 200px); overflow: auto;">
              <v-btn @click="removeFile" absolute color="red" fab outlined right small
                     v-if="file && item.firstVersion.type !== 'OTHER'">
                <v-icon>close</v-icon>
              </v-btn>

              <div class="pa-2" v-html="item.firstVersion.value" v-if="item.firstVersion.type === 'MS_DOC'"/>

              <pdf-viewer :url="url"
                          style="height: 100%; width: 100%"
                          v-if="item.firstVersion.type === 'PDF' && url"/>

              <file-pond :allow-multiple="false"
                         :server="myServer"
                         :label-idle="$t('hint')"
                         max-files="1"
                         ref="pond"
                         v-show="item.firstVersion.type === 'OTHER'"/>

            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-card-actions>
        <v-btn @click="hideDialog" outlined>
          {{ $t('button.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          v-if="!editMode"
          :disabled="disabledSave"
          :loading="loading"
          color="primary"
          outlined
          @click="save"
        >
          {{ $t('button.save') }}
        </v-btn>
        <v-btn :loading="loading" @click="update" color="primary" outlined v-if="editMode">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AbstractDataEditForm from './AbstractDataEditForm'
import mammoth from 'mammoth'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { EventBus } from '@/event-bus'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import PdfViewer from '@/components/utils/pdf/PdfViewer'
import messages from '../../componet-locale/abstract-data-details-dialog/messages'
import MapPosition from '@/components/map/MapPosition'

const FilePond = vueFilePond()

export default {
  name: 'AbstractDataDetailsDialog',
  components: { MapPosition, PdfViewer, AbstractDataEditForm, FilePond },
  mixins: [displayServiceMixin],
  data: function () {
    return {
      myServer: {
        process: (fieldName, file, metadata, load) => {
          this.handleFileUpload(file, load)
        }
      },
      loading: false,
      file: null,
      item: {},
      editMode: false,
      dialog: false,
      isFileConverted: true,
      isFileUploaded: true,
      url: ''
    }
  },
  i18n: { messages: messages },
  methods: {
    createData () {
      this.url = ''
      this.file = undefined
      this.loading = false
      this.item = {
        name: '',
        firstVersion: {
          type: 'OTHER',
          value: ''
        }
      }
      this.editMode = false
      this.dialog = true
    },
    async editData (item) {
      // this.$axios.get('data/get', { params: { id: item.id } })

      this.item = {}
      this.dialog = false
      this.loading = false
      this.editMode = true
      this.item = item
      this.dialog = true
    },
    hideDialog () {
      this.dialog = false
    },
    isValidData () {
      let isFormValid = this.$refs.dataEditForm.validateForm()
      if (!this.editMode && !this.file) {
        this.file = null
        return false
      }
      return isFormValid
    },
    save () {
      if (this.isValidData()) {
        this.loading = true
        this.$emit('save', this.item)
      }
    },
    removeFile () {
      this.$refs.pond.removeFile(0)
      this.item.firstVersion.type = 'OTHER'
    },
    update () {
      if (this.isValidData()) {
        this.loading = true
        let item = { ...this.item }

        let url = 'data/save'

        if (item.dataType === 'MAP') {
          url = 'map/save'
          item.layerConfigList.forEach(it => it.layer = { id: it.layer.id })
          item.position = JSON.stringify(item.position)
        }
        this.$axios
          .post(url, item)
          .then(() => {
            this.hideDialog()
            EventBus.$emit('loadData')
          })
          .catch(error => console.error(error))
          .finally(() => this.loading = false)
      }
    },
    handleFileUpload (file, load) {
      this.loading = true
      this.file = file
      this.isFileConverted = true
      let type = 'OTHER'
      let reader = new FileReader()
      let self = this
      let fileExt = this.file.name.split('.').pop().toLowerCase()
      this.item.name = this.file.name.replace(/\.[^/.]+$/, '')
      if (fileExt === 'pdf') {
        type = 'PDF'
      }
      if (fileExt === 'docx') {
        this.isFileConverted = false
        type = 'MS_DOC'
        reader.onload = () => {
          let arrayBuffer = reader.result
          mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
            .then(function (result) {
              self.item.firstVersion.value = result.value
              self.isFileConverted = true
            })
            .catch(() => {
              EventBus.$emit('showErrorMessage', 'readingError')
              self.isFileConverted = true
            })
            .done()
        }
      }
      reader.readAsArrayBuffer(this.file)
      this.item.firstVersion.type = type
      this.saveFile(load)
    },
    saveFile (load) {
      let formData = new FormData()
      formData.append('file', this.file)
      this.$axios
        .post('/files/save', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 240000
        })
        .then(response => {
          this.item.filePath = response.data
          this.item.firstVersion.fileName = this.file.name
          this.isFileUploaded = true
          this.url = this.$axios.defaults.baseURL + '/files/get-temp-file/' + response.data
          load()
          this.loading = false
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    }
  },
  computed: {
    disabledSave () {
      return !(this.isFileConverted && this.isFileUploaded)
    }
  }
}
</script>
