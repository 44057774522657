<template>
  <div :id="`image-data-${id}`" v-bind:class="dense ? '' : 'widget'">
    <v-toolbar collapse dense flat max-width="100%" style="border-radius: 5px">
      <a @click="openInNewTab(data)"
         v-bind:class="dense ? 'subtitle-1' : 'headline'"
         class="hide-text-overflow simple-link">
        {{ data.name }}
      </a>
    </v-toolbar>
    <div v-bind:class="dense ? '' : 'pa-3'">
      <chart :chart="chart" :table="table"/>
    </div>
  </div>
</template>

<script>
import Chart from '../chart/Chart'
import { EventBus } from '@/event-bus'

export default {
  name: 'ChartWidget',
  components: { Chart },
  props: {
    id: {
      type: Number,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    table: {},
    data: {},
    chart: {}
  }),
  methods: {
    init () {
      this.$axios
        .get('data/get', { params: { id: this.id } })
        .then(response => {
          this.data = response.data
          this.chart = JSON.parse(this.data.value)
          this.loadTable(this.data.table.id)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    },
    loadTable (id) {
      this.$axios
        .get('/table/get-table/' + id)
        .then(response => {
          this.table = JSON.parse(response.data.table)
        })
        .catch(reason => {
          EventBus.$emit('showErrorMessage', 'error')
          console.error(reason.data.message)
        })
    }
  },
  created () {
    this.init()
    if (this.showInfo) {
      this.active = 1
    }
  }
}
</script>
