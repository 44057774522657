<template>
  <div>
    <v-card-title>Объекты</v-card-title>

    <div class="pa-4">
      <v-card
        v-for="{layer, countByPoiTemplates} in layerDetailsList"
        @click="select(layer)"
        class="mt-3"
        outlined
        hover
      >
        <v-card-title>
          {{ layer.name }}
          <v-spacer/>
          <layer-poi-chip
            v-for="template in layer.poiTemplates"
            :template="template"
            :count="countByPoiTemplates[template.id]"
          />
        </v-card-title>
        <v-card-text
          v-html="layer.description"
          v-if="layer.description"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
  import LayerPoiBadge from "@/components/layer-poi/LayerPoiBadge";
  import LayerPoiChip from "@/components/layer-poi/LayerPoiChip";

  export default {
    name: "PoiManagement",
    components: { LayerPoiChip, LayerPoiBadge },
    data: () => ({
      layerDetailsList: []
    }),
    methods: {
      select(layer) {
        this.$router.push({
          name: 'poi-table',
          params: {
            layerId: layer.id
          }
        })
      }
    },
    created() {
      this.$axios
        .get('layer/get-layers-with-poi', {
          params: {
            projectId: this.selectedProject.id
          }
        })
        .then(response => this.layerDetailsList = response.data)
    }
  }
</script>
