<template>
  <profile-items/>
</template>

<script>
  import ProfileItems from '@/components/profile/ProfileItems'

  export default {
    name: 'ProfileMenu',
    components: { ProfileItems },
  }
</script>
